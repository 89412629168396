import * as React from "react";
import parseEmoji from "app/lib/parseEmoji";
import { shortMessage } from "app/lib/commits";

type Props = {
  message: string | null | undefined;
  url?: string;
};

export default class BuildMessage extends React.PureComponent<Props> {
  render() {
    if (!this.props.message) {
      return (
        <span
          aria-label="This will be replaced by the commit message once the build starts"
          data-tooltip-length="medium"
          className="dark-gray"
          style={{
            fontWeight: "normal",
          }}
        >
          …
        </span>
      );
    }

    const firstLine = shortMessage(this.props.message);
    const messageWithEmoji = parseEmoji(firstLine);

    if (this.props.url) {
      return (
        <a
          href={this.props.url}
          className="text-charcoal-600 hover:text-charcoal-600 font-medium"
          title={this.props.message}
          dangerouslySetInnerHTML={{
            __html: messageWithEmoji,
          }}
        />
      );
    }

    return (
      <span
        title={this.props.message}
        dangerouslySetInnerHTML={{
          __html: messageWithEmoji,
        }}
      />
    );
  }
}
