import { BlockStep } from "./BlockStep";
import { CommandStep } from "./CommandStep";
import { GroupStep } from "./GroupStep";
import { InputStep } from "./InputStep";
import { TriggerStep } from "./TriggerStep";
import { WaitStep } from "./WaitStep";

export type Step =
  | CommandStep
  | GroupStep
  | InputStep
  | WaitStep
  | BlockStep
  | TriggerStep;

export enum State {
  Ignored = "ignored",
  Ready = "ready",
  Running = "running",
  Failing = "failing",
  Finished = "finished",
  WaitingForDependencies = "waiting_for_dependencies",
}

export enum Outcome {
  Neutral = "neutral",
  Passed = "passed",
  SoftFailed = "soft_failed",
  HardFailed = "hard_failed",
  Errored = "errored",
}

export enum Type {
  Command = "command",
  Group = "group",
  Input = "input",
  Wait = "wait",
  Block = "block",
  Trigger = "trigger",
}

export enum DependencyType {
  Gate = "gate",
  Chain = "chain",
  Direct = "direct",
  Upload = "upload",
}

export interface BaseStep {
  uuid: string;
  type: Type;
  key?: string;
  label?: string;
  state?: State | null;
  outcome?: Outcome | null;
  groupUuid?: string;
  dependencies: {
    uuid: string | null;
    type: DependencyType;
    key?: string;
  }[];
  missingDependencies?: string[];
  if?: string;
}
