import { AnimatePresence } from "framer-motion";
import { cloneElement } from "react";
import { useOutlet } from "react-router-dom";
import { useBuildPreferencesStore } from "../lib/useBuildPreferencesStore";

/**
 * A react-router outlet for animating in a drawer on route changes.
 *
 * The key is fixed to ensure the drawer doesn't slide up-and-down when switching out the inner
 * content within a drawer (ie. selected different steps).
 */
export const DrawerOutlet = () => {
  const element = useOutlet();
  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);

  return (
    <AnimatePresence initial={false}>
      {element && cloneElement(element, { key: `drawer-${dockPosition}` })}
    </AnimatePresence>
  );
};
