import { useBuild } from "app/components/Playground/BuildContext";
import { useEffect, useMemo, useState } from "react";
import { GroupStep, Outcome, State } from "app/lib/pipeline";
import { EmptyResults } from "../EmptyResults";
import { createStepListItem } from "./StepListItem";
import { useFilterStore } from "./useFilterStore";

/**
 * Render a list of build steps.
 */
export function StepList() {
  const filters = useFilterStore((state) => state.filters);

  const [currentSteps, setCurrentSteps] = useState<string[]>([]);
  const { build } = useBuild();

  if (!build) {
    throw new Error("Missing build context");
  }

  // Filter the steps based on applied filters
  const steps = useMemo(() => {
    if (filters !== "issues") {
      return build.steps.filter((step) => step.state !== State.Ignored);
    }

    return build.steps.filter((step) => {
      return (
        (step.state !== State.Ignored && currentSteps.includes(step.uuid)) ||
        step.state === State.Failing ||
        (step.state === State.Finished &&
          (step.outcome === Outcome.HardFailed ||
            step.outcome === Outcome.SoftFailed))
      );
    });
  }, [build.steps, filters]);

  // Group steps
  const groupedSteps = useMemo(() => {
    const stepsMap = new Map(steps.map((step) => [step.uuid, { ...step }]));
    stepsMap.forEach((step) => {
      if (step.groupUuid) {
        const group = stepsMap.get(step.groupUuid) as GroupStep;
        if (group && step.type !== "group") {
          if (!group.steps) {
            group.steps = [];
          }
          group.steps.push(step);
        }
        stepsMap.delete(step.uuid);
      }
    });
    return Array.from(stepsMap.values());
  }, [steps]);

  // Keep track of the current failing steps to ensure they don't disappear when
  // retrying a failed step.
  useEffect(() => {
    if (filters === "issues") {
      setCurrentSteps(steps.map((step) => step.uuid));
    }
  }, [filters]);

  return (
    <div>
      {groupedSteps.map((step) => createStepListItem({ step }))}

      {filters === "issues" && steps.length === 0 && <EmptyResults />}
    </div>
  );
}
