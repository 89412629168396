import ReactPaginate from "react-paginate";
import styled from "styled-components";
import Colors from "app/constants/analytics/Colors";

type Props = {
  pageCount: number;
  onPageChange: any;
  viewAllUrl?: string;
};

const StyledPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;

  ul {
    list-style: none;
  }

  ul a {
    border-radius: 5px;
    width: 34px;
    padding: 8px 13px;
    text-align: center;
    line-height: 1;
    color: ${Colors.MID_GRAY};
    border: 1px solid transparent;

    &:hover {
      border: 1px solid ${Colors.GRAY_BORDER};
    }
  }

  li {
    display: inline-block;
    list-style: none;
    margin: 0 2px;
  }

  a {
    line-height: 1;
    padding-top: 16px;
    cursor: pointer;
  }

  [aria-label]:before,
  [aria-label]:after,
  .previous,
  .next {
    display: none;
  }

  .selected a {
    border: 1px solid ${Colors.GRAY_BORDER};
    color: ${Colors.BASE_GRAY};
    font-weight: 600;
  }

  .view-all {
    margin-left: 10px;
  }
`;

const Pagination = (props: Props) => {
  if (props.pageCount <= 1) {
    return null;
  }

  return (
    <StyledPagination data-testid="pagination">
      <ReactPaginate
        pageCount={props.pageCount}
        pageRangeDisplayed={props.pageCount}
        onPageChange={props.onPageChange}
        pageLinkClassName="text-decoration-none"
      />
      {props.viewAllUrl && (
        <a
          className="view-all block text-decoration-none semi-bold black"
          href={props.viewAllUrl}
        >
          View all
        </a>
      )}
    </StyledPagination>
  );
};

export default Pagination;
