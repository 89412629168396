import styled from "styled-components";
import Metadata, {
  MetadataType,
} from "app/components/analytics/shared/Metadata";
import MetricCards, {
  Metrics,
} from "app/components/analytics/shared/MetricCards";

type Props = {
  metadata: MetadataType;
  metrics: Metrics;
  hideMissingEnvNotice?: boolean;
};

const StyledRunHeader = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-wrap: wrap;
  justify-items: start;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`;

const Header = ({ metadata, metrics, hideMissingEnvNotice }: Props) => (
  <StyledRunHeader>
    <Metadata
      type="run"
      {...metadata}
      hideMissingEnvNotice={hideMissingEnvNotice}
    />
    <MetricCards metrics={metrics} />
  </StyledRunHeader>
);

export default Header;
