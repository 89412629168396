/* eslint-disable react/display-name */

import { memo } from "react";
import escape from "escape-html";
import IconLoader from "./IconLoader";
import styled from "styled-components";

const LEGACY_ICONS = [
  "bitbucket",
  "chevron-right",
  "circle",
  "donut",
  "close",
  "code",
  "database",
  "external",
  "eye",
  "eye-strikethrough",
  "git",
  "github",
  "gitlab",
  "graphql",
  "menu",
  "organization",
  "packages",
  "password",
  "permission-small-cross",
  "permission-small-tick",
  "placeholder",
  "plus",
  "plus-circle",
  "queues",
  "retry",
  "twitter",
  "unknown-user",
  "webhook",
];

const ALIASES = {
  "job-retries-report": "jobRetriesReport",
  "lock-circle": "permissions",
  "down-triangle": "downTriangle",
  "up-triangle": "upTriangle",
  git: "repository",
  emails: "emailSettings",
  "api-tokens": "accessTokens",
  "build-skipping": "buildSkipping",
  "connected-apps": "connectedApps",
  "notification-services": "notificationServices",
  pipeline: "steps",
  sso: "singleSignOn",
  "two-factor": "twoFactorAuthentication",
} as const;

const heroiconRegex = /^heroicons\//;
const customIconRegex = /^custom\//;

export type Props = {
  style?: any;
  className?: string;
  icon: string;
  title?: string;
};

export default function Icon(props: Props) {
  const style = {
    fill: props.icon.includes("/outline/") ? "none" : "currentColor",
    verticalAlign: "middle",
    ...props.style,
  } as const;

  const icon = ALIASES[props.icon] || props.icon;

  if (LEGACY_ICONS.includes(icon)) {
    return <LegacyIcon {...props} style={style} />;
  }

  if (heroiconRegex.test(icon)) {
    return <Heroicon {...props} icon={icon} style={style} />;
  }

  return <NewIcon {...props} icon={icon} style={style} />;
}

const NewIcon = memo<Props>((props: Props) => {
  const size = customIconRegex.test(props.icon) ? "24px" : "22px";
  // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"raw" | "component" | "heroicon"'.
  const IconComponent = IconLoader.load(IconLoader.types.component, props.icon);

  return (
    <IconComponent
      title={props.title}
      width={size}
      height={size}
      className={props.className}
      style={props.style}
    />
  );
});

const LegacyIcon = memo<Props>((props: Props) => {
  // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"raw" | "component" | "heroicon"'.
  let icon = IconLoader.load(IconLoader.types.raw, props.icon);
  icon = (props.title ? `<title>${escape(props.title)}</title>` : "") + icon;

  return (
    <svg
      viewBox="0 0 22 22"
      width="22px"
      height="22px"
      className={props.className}
      style={props.style}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
});

// Our Heroicon icons will almost always be rendered at their original dimensions i.e. 24px
// square, however, on rare occasions we'll scale these icons (e.g. to 16px square). When we do
// this we don't want to scale the stroke width. All of our heroicons use paths so we can just
// target nested `path` elements for now.
const StyledIconComponent = styled.svg`
  path {
    vector-effect: non-scaling-stroke;
  }
`;

const Heroicon = memo<Props>((props: Props) => {
  const heroicon = props.icon.replace(heroiconRegex, "");
  // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"raw" | "component" | "heroicon"'.
  const IconComponent = IconLoader.load(IconLoader.types.heroicon, heroicon);

  return (
    <StyledIconComponent
      as={IconComponent}
      title={props.title}
      width="24px"
      height="24px"
      className={props.className}
      style={props.style}
    />
  );
});
