import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";

type ActionItem = {
  key: string;
  label: string;
  url: string;
  method: string;
  disabled?: boolean;
};

type ActionCollection = ActionItem[] | ActionItem;

type Props = {
  actions: ActionCollection[];
};

const ActionItem = ({ action }: { action: ActionItem }) => {
  const { url, method, disabled, label } = action;
  return (
    <form
      role="form"
      method="post"
      action={url}
      aria-label={label}
      className="no-title-tooltip"
    >
      <input type="hidden" name="_method" value={method} />
      <input
        type="hidden"
        name={window._csrf.param}
        value={window._csrf.token}
      />
      <input
        type="submit"
        className={`btn w-100 ${
          disabled ? "slate-500" : "hover-purple focus-purple"
        }`}
        value={label}
        disabled={disabled}
      />
    </form>
  );
};

const ActionMenuDropdown = ({ actions }: Props) => {
  return (
    <Dropdown className="flex">
      <button className="btn flex justify-center items-center btn-small btn-default">
        Actions
        <Icon icon="down-triangle" className="ml-2 w-2 h-2" />
      </button>

      <div>
        {actions.map((actionGroup, groupIndex) => (
          <div
            key={`group-${groupIndex}`}
            className="border-b border-slate-400 last-of-type:border-b-0"
          >
            {Array.isArray(actionGroup) ? (
              actionGroup.map((action) => (
                <ActionItem key={action.key} action={action} />
              ))
            ) : (
              <ActionItem action={actionGroup} />
            )}
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default ActionMenuDropdown;
