import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "app/components/shared/Icon";
import Dropdown from "app/components/shared/Dropdown";
import UserSessionStore from "app/stores/UserSessionStore";
import Colors from "app/constants/analytics/Colors";

type Props = {
  branch_name?: string;
  default_branch?: string;
  suite_uuid: string;
};

const USER_SESSION_STORE_DEFAULT_BRANCH_KEY = "test-analytics-branch-filter";

const StyledHeader = styled.button`
  border: 1px ${Colors.GRAY_BORDER} solid;
  border-radius: 3px;
  padding: 4px 8px;
  cursor: pointer;
  background-color: white;
  color: black;
  font-family:
    SF Pro Text,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  line-height: 19.6px;
  font-size: 14px;
  white-space: nowrap;
`;

const StyledLink = styled.a<{ selected: boolean }>`
  font-weight: ${({ selected }) => (selected ? "600" : "normal")};
  display: flex;
  align-items: center;
  flex: none;
  margin-left: ${({ selected }) => (selected ? "0" : "22px")};
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: #177868;
  }
`;

const DropdownHeader = styled.h4`
  line-height: 1.2;
  padding: 0.75em 1em;
  margin: 0 0 5px 0;
  height: auto;
  border-bottom: 1px solid #d8d8d8;
  vertical-align: middle;
`;

const BranchFilter = ({ branch_name, default_branch, suite_uuid }: Props) => {
  const [currentBranch, setCurrentBranch] = useState(
    branch_name ||
      UserSessionStore.get(
        `${USER_SESSION_STORE_DEFAULT_BRANCH_KEY}:${suite_uuid}`,
      ) ||
      default_branch ||
      "all branches",
  );

  useEffect(() => {
    if (currentBranch != null) {
      const url = new URL(window.location.href);
      url.searchParams.set("branch", currentBranch);
      window.history.replaceState(null, "", url);
    }
  });

  return (
    <Dropdown className="flex">
      <StyledHeader className="h2 m0 p0 mr1">
        <Icon
          icon="code"
          style={{
            fill: Colors.BASE_GRAY,
            width: 15,
            height: 15,
            marginRight: "2px",
          }}
        />
        {currentBranch || "all branches"}
        <Icon
          icon="down-triangle"
          style={{ width: 8, height: 8, marginLeft: "5px" }}
        />
      </StyledHeader>
      <DropdownHeader className="flex items-center flex-none">
        Switch branches
      </DropdownHeader>
      {default_branch != null && (
        <DropdownButton
          currentBranch={currentBranch}
          setCurrentBranch={setCurrentBranch}
          suite={suite_uuid}
        >
          {default_branch}
        </DropdownButton>
      )}
      <DropdownButton
        data-testid="allBranches"
        currentBranch={currentBranch}
        setCurrentBranch={setCurrentBranch}
        suite={suite_uuid}
      >
        all branches
      </DropdownButton>
    </Dropdown>
  );
};

type DropdownButtonProps = {
  currentBranch?: string;
  setCurrentBranch: (string) => void;
  children: string;
  suite: string;
};

const DropdownButton = ({
  currentBranch,
  setCurrentBranch,
  children,
  suite,
}: DropdownButtonProps) => {
  const selected =
    currentBranch === children ||
    (currentBranch === null && children === "all branches");

  const handleBranchFilter = useCallback(() => {
    setCurrentBranch(children);
    UserSessionStore.set(
      `${USER_SESSION_STORE_DEFAULT_BRANCH_KEY}:${suite}`,
      children,
    );
  }, []);

  const link = new URL(window.location.href);
  link.searchParams.set("branch", children);

  return (
    <StyledLink
      selected={selected}
      href={link.href}
      onClick={handleBranchFilter}
    >
      {selected && <Icon icon="permission-small-tick" />}
      {children}
    </StyledLink>
  );
};

export default BranchFilter;
