import * as React from "react";
import BuildPageStore, { parseHash } from "app/stores/BuildPageStore";
import classnames from "classnames";
import styled from "styled-components";
import JobComponent from "app/components/job/Job";
import ToggleSwitch from "app/components/shared/ToggleSwitch";
import BuildShowStore, { Build } from "app/stores/BuildShowStore";
import { Job } from "app/components/build/Header/pipeline/types/Job";
import { convertRetriesToGroups, filterJobsToIssues } from "../lib/jobs";
import AnnotationsListRenderer from "../components/AnnotationsListRenderer";
import { EmptyResults } from "../components/EmptyResults";
import RunTime from "../components/RunTime";
import TriggerJobSummary from "../components/TriggerJobSummary";
import ManualJobSummary from "../components/ManualJobSummary";
import WaiterJobSummary from "../components/WaiterJobSummary";
import { useBuild } from "app/components/Playground/BuildContext";
import { useLocation } from "react-router-dom";
import Footer from "app/components/layout/Footer";
type Props = {
  store: BuildShowStore;
};

type State = {
  build: Build;
  jobsWithIssues: Array<Job>;
  isFilteringToIssues: boolean;
  seenIssuesCount: number;
};

// We might want to roll this into the ToggleSwitch component but it's not yet clear what the
// requirements would be so, as the first use case, it can live here for now.
const UnseenIssuesIndicator = styled.em`
  width: 9px;
  height: 9px;
  position: absolute;
  border-radius: 100px;
  overflow: hidden;
  background: var(--red-600);
  border: 1px solid white;
  text-indent: -9000px;
  bottom: 100%;
  left: 100%;
  box-shadow: 0 0 0 3px var(--red-300);
  transform: translate(-50%, 50%);
  animation: scale-in-center 0.4s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.5s
    both;

  @keyframes scale-in-center {
    0% {
      transform: translate(-50%, 50%) scale(0);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, 50%) scale(2);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, 50%) scale(1);
      opacity: 1;
    }
  }
`;

class Jobs extends React.PureComponent<Props, State> {
  constructor(initialProps: Props) {
    super(initialProps);

    const build = this.props.store.getBuild();
    const jobsWithIssues = filterJobsToIssues(build.jobs);

    let isFilteringToIssues =
      build.state === "failing" || build.state === "failed";

    if (isFilteringToIssues) {
      // Grab the job ID, if any, from the hash
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      const jobId = window.location.hash
        .split(/#(?:job-)?/)
        .pop()
        .split("/")
        .shift();

      // If we don't find the job ID in the list of jobs with issues, then we should
      // default to showing all jobs
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      if (jobId.length && !jobsWithIssues.some((job) => job.id === jobId)) {
        isFilteringToIssues = false;
      }
    }

    const initialIssueCount =
      jobsWithIssues.length + build.annotationIssueCount;
    const seenIssuesCount = isFilteringToIssues ? initialIssueCount : 0;

    this.state = {
      build,
      jobsWithIssues,
      isFilteringToIssues,
      seenIssuesCount,
    };

    // EventEmitter-based stores always call a given
    // event handler in their own context 🤦🏻‍♀️
    // so let's override that!
    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.handleBuildPageStoreChange =
      this.handleBuildPageStoreChange.bind(this);
  }

  get issueCount() {
    return (
      this.state.jobsWithIssues.length + this.state.build.annotationIssueCount
    );
  }

  componentDidMount() {
    window.addEventListener("hashchange", this.handleHashChange);
    this.props.store.on("change", this.handleStoreChange);
    BuildPageStore.addChangeListener(null, this.handleBuildPageStoreChange);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.handleHashChange);
    this.props.store.off("change", this.handleStoreChange);
    BuildPageStore.removeChangeListener(null, this.handleBuildPageStoreChange);
  }

  markAllIssuesAsSeen() {
    this.setState({ seenIssuesCount: this.issueCount });
  }

  handleStoreChange = () => {
    const build = this.props.store.getBuild();

    // We want to "stick" jobs to the issues tab if we're already viewing it, so that a
    // manual retry of a failed job doesn't cause that job to suddenly disappear from
    // view, so we'll preserve all jobs that we can already see when re-applying the jobs
    // filter
    const filterOptions = this.state.isFilteringToIssues
      ? { preserveRetriesOfIds: this.state.jobsWithIssues.map((job) => job.id) }
      : null;
    const jobsWithIssues = filterJobsToIssues(build.jobs, filterOptions);

    this.setState({
      build,
      jobsWithIssues,
    });
  };

  handleHashChange = () => {
    if (!window.location.hash) {
      return;
    }

    // Grab the job ID from the hash
    const hash = window.location.hash.split("#").pop();

    // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
    const jobId = hash
      .split(/^(job-)?/)
      .pop()
      .split("/")
      .shift();

    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    this.disableIssueFilterIfTargetJobIsPassed(jobId, () => {
      // If we get a callback here, and the element exists by name,
      // we need to manually scroll it into view, as we're doing this
      // after BuildPageStore or browser would do it themselves.
      // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      const scrollToElement = document.getElementById(hash);
      if (scrollToElement) {
        scrollToElement.scrollIntoView();
      }
    });
  };

  handleBuildPageStoreChange = () => {
    // If we don't have a scrollToJobAfterRender
    // to deal with, we have nothing extra to do
    if (!BuildPageStore.scrollToJobAfterRender) {
      return;
    }

    this.disableIssueFilterIfTargetJobIsPassed(
      BuildPageStore.scrollToJobAfterRender,
    );
  };

  disableIssueFilterIfTargetJobIsPassed = (
    targetJobID: string,
    callback?: any,
  ) => {
    // If we're not filtering, we have nothing extra to do
    if (!this.state.isFilteringToIssues) {
      return;
    }

    const scrollTargetJob = this.state.build.jobs.find(
      ({ id }) => id === targetJobID,
    );

    // Bail if we can't find that job ID
    if (!scrollTargetJob) {
      return;
    }

    // Otherwise, check if the job is failed;
    // if so, we don't need to do anything
    const scrollTargetJobFailed = this.state.jobsWithIssues.some(
      (job) => job.id === targetJobID,
    );
    if (scrollTargetJobFailed) {
      return;
    }

    // Otherwise, we need to switch to the "All Jobs" tab
    // for this job to be visible and therefore scrolled to!
    this.setState({ isFilteringToIssues: false }, callback);
  };

  render() {
    const build = this.state.build;

    return (
      <>
        <div style={{ width: "100%" }}>
          <div className="flex items-center gap3 mb4">
            {this.renderFilters()}
          </div>
        </div>

        <AnnotationsListRenderer
          params={{
            buildSlug: `${build.account.slug}/${build.project.slug}/${build.number}`,
          }}
          organization={build.account.slug}
          pipeline={build.project.slug}
          number={build.number}
          filterIssues={this.state.isFilteringToIssues}
          hasFailedJobs={this.state.jobsWithIssues.length > 0}
        />
        {this.renderJobList()}
      </>
    );
  }

  handleToggleFilter = (toggledOn: boolean) => {
    const url = new URL(window.location.href);
    url.hash = "";
    history.replaceState({}, "", url.toString());

    this.setState({ isFilteringToIssues: toggledOn });

    if (toggledOn) {
      this.markAllIssuesAsSeen();
    }
  };

  renderFilters() {
    // We want to display a "hey look at me" badge on the failures tab when it contains issues, but
    // we want to dismiss that badge when the user has seen all of those issues. Comparing a count
    // is fine for completed builds, but is likely to get weird when looking at running builds with
    // failure retries. To do this properly we'd need to identify exactly which issues have been
    // seen but to avoid re-designing our data flows to support this we can use a count for now. If
    // we find that running builds with job retries are a noticable issue we can revisit this
    // mechanism.
    const hasUnseenIssues = this.state.seenIssuesCount < this.issueCount;

    const renderOnIndicator = () => {
      return (
        <>
          {hasUnseenIssues ? <UnseenIssuesIndicator /> : null}
          <span
            className={classnames({
              "charcoal-300":
                !this.state.isFilteringToIssues && this.issueCount === 0,
            })}
          >
            Failures
          </span>
        </>
      );
    };

    const renderOffIndicator = () => {
      return <span>All</span>;
    };

    return (
      <>
        <label id="job-list-filter" className="hidden">
          Filter jobs to failures
        </label>
        <ToggleSwitch
          value={this.state.isFilteringToIssues}
          onChange={this.handleToggleFilter}
          labelledBy="job-list-filter"
          renderOnIndicator={renderOnIndicator}
          renderOffIndicator={renderOffIndicator}
        />
      </>
    );
  }

  renderJobList() {
    const build = this.state.build;

    let jobs = build.jobs;

    if (this.state.isFilteringToIssues) {
      jobs = this.state.jobsWithIssues;

      if (this.issueCount === 0) {
        return <EmptyResults />;
      }
    }

    const jobsWithRetryGroups = convertRetriesToGroups(jobs);

    // job-list-pipeline is needed by the job components' styles
    return (
      <>
        <div className="job-list-pipeline">
          {jobsWithRetryGroups.map((jobOrGroup) => {
            // Don't even bother showing broken jobs
            if (
              jobOrGroup.type !== "retry_group" &&
              jobOrGroup.state === "broken"
            ) {
              return null;
            }

            return jobOrGroup.type === "retry_group" ? (
              <div className="job-retry-group">
                {jobOrGroup.jobs.map((job, index) =>
                  this.renderJob(job, index > 0),
                )}
              </div>
            ) : (
              this.renderJob(jobOrGroup)
            );
          })}
        </div>
        {build.totalJobDuration !== null ? (
          <div
            className="flex flex-justify py1"
            style={{ marginTop: 50, justifyContent: "center " }}
          >
            <RunTime duration={build.totalJobDuration} />
          </div>
        ) : null}
      </>
    );
  }

  renderJob(job: Job, isRetry = false) {
    if (job.type === "script") {
      return (
        <JobComponent
          key={job.id}
          job={job}
          build={this.state.build}
          buildStore={this.props.store}
        />
      );
    } else if (job.type === "trigger") {
      return <TriggerJobSummary key={job.id} job={job} isRetry={isRetry} />;
    } else if (job.type === "manual") {
      return (
        <ManualJobSummary
          key={job.id}
          job={job}
          buildStore={this.props.store}
        />
      );
    } else if (job.type === "waiter") {
      return <WaiterJobSummary key={job.id} job={job} />;
    }
  }
}

/**
 * Render a list of jobs that expand based on the URL hash.
 */
export default function JobsPage() {
  const { store } = useBuild();
  const { hash } = useLocation();

  if (!store) {
    throw new Error("Missing build context");
  }

  React.useLayoutEffect(() => {
    const { job } = parseHash(hash) || {};
    if (job) {
      BuildPageStore.expandJob({ id: job });
    }
  }, [hash]);

  return (
    <>
      <Jobs store={store} />
      <Footer />
    </>
  );
}
