// These two are NOT actual states from `JobStateEnum` but I think they probably
// really should be at some point to make logic simpler and match Build enum states,
// especially now that steps are a thing. Currently these need to be derived from multiple
// other properties of a Job.
export const VIRTUAL_BUILD_STATES = {
  STARTED: "STARTED",
} as const;

export const BUILD_STATES = {
  SKIPPED: "SKIPPED",
  SCHEDULED: "SCHEDULED",
  RUNNING: "RUNNING",
  PASSED: "PASSED",
  SOFT_FAILED: "SOFT_FAILED",
  FAILING: "FAILING",
  FAILED: "FAILED",
  CANCELING: "CANCELING",
  CANCELED: "CANCELED",
  CREATING: "CREATING",
  BLOCKED: "BLOCKED",
  NOT_RUN: "NOT_RUN",
} as const;

export default BUILD_STATES;

// BuildStates provided by the backend are lower cased so we'll provide a type with the lowercased
// version of the uppercased keys in BUILD_STATES above
export type BuildStates =
  | "started"
  | "skipped"
  | "scheduled"
  | "running"
  | "passed"
  | "soft_failed"
  | "failing"
  | "failed"
  | "canceling"
  | "canceled"
  | "creating"
  | "blocked"
  | "not_run";

const TERMINAL_STATES = new Set<BuildStates>([
  "canceled",
  "failed",
  "not_run",
  "passed",
  "skipped",
]);

export function isTerminalBuildState(state: BuildStates): boolean {
  return TERMINAL_STATES.has(state);
}
