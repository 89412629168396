/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from "react";

import { track } from "app/lib/segmentAnalytics";
import BuildShowStore, { Build } from "app/stores/BuildShowStore";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import Spinner from "app/components/shared/Spinner";

export default function RetryFailedJobsButton({
  build,
  store,
}: {
  build: Build;
  store: BuildShowStore;
}) {
  // We set isLoading when the request starts, but we don't unset it once the
  // request finishes. We want to keep the spinner going until the build starts
  // running again, at which point this will be unrendered.
  const [isLoading, setIsLoading] = React.useState(false);

  function handleBeforeStart() {
    setIsLoading(true);
    track("Retry Failed Jobs Clicked", {
      text: "Retry failed jobs",
      source: "Rebuild Dropdown",
    });
  }

  // When new jobs start, clear the loading state
  useEffect(() => {
    setIsLoading(false);
  }, [build.jobs.length]);

  function handleSuccess(_event: Event, response: Build) {
    if (response) {
      store.loadAndEmit(response);
    }
  }

  function handleError() {
    window.location.assign(build.path);
  }

  return (
    <RemoteButtonComponent
      // @ts-expect-error - TS2339 - Property 'retryFailedJobsPath' does not exist on type 'Build'.
      url={build.retryFailedJobsPath}
      method="post"
      loading={isLoading}
      loadingText="Retrying jobs&hellip;"
      loadingIndicator={<Spinner />}
      className="btn btn-default btn-flex gap1"
      onBeforeStart={handleBeforeStart}
      onSuccess={handleSuccess}
      onError={handleError}
    >
      Retry failed jobs
    </RemoteButtonComponent>
  );
}
