import BuildShowStore, { Build, Job } from "app/stores/BuildShowStore";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { CommandJob } from "../build/Header/pipeline/types/CommandJob";

const BuildContext = createContext<{
  build: Build | null;
  setBuild: (build: Build) => void;
  store: BuildShowStore | null;
}>({
  build: null,
  store: null,
  setBuild: () => {
    return;
  },
});

export const BuildContextProvider = ({ children, store }) => {
  const [build, setBuild] = useState(store?.build);

  useEffect(() => {
    store?.on("change", () => {
      setBuild(store?.build);
    });
    return () => {
      store?.off("change");
    };
  }, []);

  const loadAndEmit = useCallback(
    (build: Build) => {
      store.loadAndEmit(build);
    },
    [store],
  );

  return (
    <BuildContext.Provider value={{ build, setBuild: loadAndEmit, store }}>
      {children}
    </BuildContext.Provider>
  );
};

export const useBuild = () => {
  return useContext(BuildContext);
};

/**
 * Fetch the most recent parallel jobs for a given step.
 */
export const useMostRecentParallelJobsForStep = (
  stepUuid: string,
): CommandJob[] => {
  const jobs = useJobsForStep<CommandJob>(stepUuid);

  return mostRecentParallelJobs(jobs);
};

/**
 * Fetch the most recent matrix jobs for a given step.
 */
export const useMostRecentMatrixJobsForStep = (
  stepUuid: string,
): CommandJob[] => {
  const jobs = useJobsForStep<CommandJob>(stepUuid);

  return mostRecentMatrixJobs(jobs);
};

export function useJobsForStep<T extends Job>(stepUuid: string): T[] {
  const { build } = useBuild();
  if (!build) {
    return [];
  }

  return findJobsForStep<T>(build, stepUuid);
}

export function useMostRecentJob<T extends Job>(stepId: string): T | null {
  const jobs = useJobsForStep<T>(stepId);
  if (!jobs.length) {
    return null;
  }
  return jobs[jobs.length - 1];
}

export function findJobsForStep<T extends Job>(
  build: Build,
  stepUuid: string,
): T[] {
  return build.jobs.filter((job) => job.stepUuid === stepUuid) as T[];
}

export function mostRecentParallelJobs(jobs: CommandJob[]): CommandJob[] {
  return Array.from(
    jobs
      .reduce((acc, job) => {
        acc.set(job.parallelGroupIndex, job);
        return acc;
      }, new Map())
      .values(),
  );
}

export function mostRecentMatrixJobs(jobs: CommandJob[]): CommandJob[] {
  return Array.from(
    jobs
      .reduce((acc, job) => {
        if (!job.matrix) {
          return acc;
        }

        acc.set(JSON.stringify(job.matrix), job);
        return acc;
      }, new Map())
      .values(),
  );
}

export { type Build, type Job };
export default BuildContext;
