import { useState, useCallback } from "react";
import { includes } from "lodash";
import classNames from "classnames";
import PublicOrganizationBadge from "./PublicOrganizationBadge";
import { HighlightableNavigationButton } from "app/components/layout/Navigation";

export type Organization = {
  name: string;
  iconUrl?: string;
  slug: string;
  isPublic: boolean;
};

export type OrganizationMenu = {
  paddingLeft?: number;
  borderLeft?: string;
  className?: string;
};

export const PublicNavigationTopMenu = ({
  organization,
  borderLeft,
}: {
  organization: Organization;
  borderLeft?: string;
}) => (
  <span className="flex xs-hide sm-hide md-hide">
    <PublicNavigationMenu
      organization={organization}
      styles={{ className: "ml1", borderLeft }}
    />
  </span>
);

export const PublicNavigationBottomMenu = ({
  organization,
}: {
  organization: Organization;
}) => (
  <div className="border-top border-gray lg-hide">
    <div
      className="flex flex-stretch nav-container"
      style={{ height: 45, paddingLeft: 5 }}
    >
      <PublicNavigationMenu
        organization={organization}
        styles={{ paddingLeft: 15 }}
      />
    </div>
  </div>
);

const TABS = {
  PIPELINES: "pipelines",
  TEST_SUITES: "testSuites",
};

const PublicNavigationMenu = ({
  organization,
  styles,
}: {
  organization: Organization;
  styles: OrganizationMenu;
}) => {
  if (!organization.isPublic) {
    return null;
  }

  const { slug, name, iconUrl } = organization;
  const { paddingLeft, borderLeft, className } = styles;

  const analyticsPathname = includes(
    window.location.pathname,
    `${slug}/analytics`,
  );

  const [activeNavItem, setActiveNavItem] = useState(
    analyticsPathname ? TABS.TEST_SUITES : TABS.PIPELINES,
  );

  const handlePipelinesClick = useCallback(() => {
    setActiveNavItem(TABS.PIPELINES);
  }, []);

  const handleTestSuitesClick = useCallback(() => {
    setActiveNavItem(TABS.TEST_SUITES);
  }, []);

  return (
    <div className={classNames("flex", className)}>
      <PublicOrganizationBadge
        name={name}
        iconUrl={iconUrl}
        styles={{ paddingLeft: paddingLeft, borderLeft }}
      />
      <HighlightableNavigationButton
        active={activeNavItem === TABS.PIPELINES}
        className="py0"
        onClick={handlePipelinesClick}
        href={`/${slug}`}
      >
        Pipelines
      </HighlightableNavigationButton>
      <HighlightableNavigationButton
        active={activeNavItem === TABS.TEST_SUITES}
        onClick={handleTestSuitesClick}
        className="py0"
        href={`/organizations/${slug}/analytics`}
      >
        Test Suites
      </HighlightableNavigationButton>
    </div>
  );
};
