import Icon from "app/components/shared/Icon";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from "app/components/shared/HoverCard";

export default function Agent({
  agentName,
  agentPath,
  queueName,
  agentsPath,
  onNestedLinkTrigger,
}: {
  agentName: string;
  agentPath: string;
  queueName?: string;
  agentsPath: string;
  onNestedLinkTrigger: (e: React.MouseEvent | React.KeyboardEvent) => void;
}) {
  const agentLink = (
    <a href={agentPath} className="text-inherit hover:text-inherit truncate">
      {agentName}
    </a>
  );

  const showHoverCard =
    !window.Features.BuildsShowAgentNameOnJobRow || queueName;

  return (
    <HoverCard>
      <HoverCardTrigger asChild={true}>
        <a
          href={agentPath}
          className="job-stats flex flex-auto items-center gap-x-1"
          onClick={onNestedLinkTrigger}
          onKeyDown={onNestedLinkTrigger}
        >
          <Icon
            icon="custom/outline/14x/agent"
            className="flex-shrink-0 w-3.5 h-3.5"
          />
          {window.Features.BuildsShowAgentNameOnJobRow ? (
            <span className="truncate max-w-[240px]">{agentName}</span>
          ) : (
            "Agent"
          )}
        </a>
      </HoverCardTrigger>

      {showHoverCard && (
        <HoverCardPortal>
          <HoverCardContent
            size="max-lg"
            onClick={onNestedLinkTrigger}
            onKeyDown={onNestedLinkTrigger}
          >
            {queueName ? (
              <div className="grid gap-x-2.5 gap-y-1.5 grid-cols-[45px_1fr]">
                <span className="font-semibold">Agent</span>

                {agentLink}

                <span className="font-semibold">Queue</span>

                <a
                  href={agentsPath}
                  className="text-inherit hover:text-inherit truncate"
                >
                  {queueName}
                </a>
              </div>
            ) : (
              <div className="flex">{agentLink}</div>
            )}
          </HoverCardContent>
        </HoverCardPortal>
      )}
    </HoverCard>
  );
}
