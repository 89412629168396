export type NoTeamsAvailableProps = {
  isAdmin?: boolean;
  flakyTestAssignmentFeatureAvailable?: boolean;
  organizationSettingsUrl?: string;
};

type Props = NoTeamsAvailableProps & {
  children?: React.ReactNode;
};

const NoTeamsAvailable = ({
  isAdmin,
  flakyTestAssignmentFeatureAvailable,
  organizationSettingsUrl,
  children,
}: Props) => (
  <div className="p4">
    <div className="pb2 center">
      {flakyTestAssignmentFeatureAvailable ? (
        <>
          <h3>No teams found</h3>
          <p>Your organization has no teams to assign to this flaky test.</p>

          {isAdmin && organizationSettingsUrl ? (
            <p>
              <a href={`${organizationSettingsUrl}/teams`} className="lime">
                Add teams to this suite
              </a>{" "}
              from the teams page in your organization settings.
            </p>
          ) : (
            <p>
              Ask your administrator to create teams for your organization to
              start assigning flaky tests.
            </p>
          )}
          <a
            href="https://buildkite.com/docs/team-management/permissions"
            className="btn btn-primary w-full"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about teams
          </a>
        </>
      ) : (
        <div>
          <h3>Flaky assignments</h3>
          <p>
            This feature is available to Pro and Enterprise customers only.{" "}
            <a
              href="https://buildkite.com/docs/test-analytics/flaky-test-assignment"
              target="_blank"
              rel="noreferrer"
              className="lime"
            >
              Learn more about flaky assignments
            </a>
          </p>

          {isAdmin && organizationSettingsUrl && (
            <a
              href={`${organizationSettingsUrl}/billing`}
              className="btn btn-primary w-full"
            >
              Upgrade your plan
            </a>
          )}
        </div>
      )}

      {children}
    </div>
  </div>
);

export default NoTeamsAvailable;
