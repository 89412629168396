import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";
import Emojify from "app/components/shared/Emojify";
import { Outcome, State, Step } from "app/lib/pipeline";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export const StepLabel = ({ step }: { step: Step }) => {
  return (
    <>
      <div
        className={twMerge(
          "flex-auto flex items-center text-charcoal-700 py-1",
          classNames({
            "font-medium":
              step.state === State.Finished &&
              step.outcome === Outcome.HardFailed,
          }),
        )}
      >
        <Emojify
          text={step.label}
          className="whitespace-nowrap overflow-hidden overflow-ellipsis block"
        />
      </div>

      <div className="self-stretch flex items-center justify-center w-7">
        <StateIcon className={twMerge(textColorForStep(step))} {...step} />
      </div>
    </>
  );
};
