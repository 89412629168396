import * as React from "react";

import AnonymousNavigation from "app/components/AnonymousNavigation";
import Badge from "app/components/shared/Badge";
import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";
import OrganizationAvatar from "app/components/shared/OrganizationAvatar";
import SectionLoader from "app/components/shared/SectionLoader";
import permissions from "app/lib/permissions";
import UserSessionStore from "app/stores/UserSessionStore";
import classNames from "classnames";
import styled from "styled-components";
import MyBuilds from "./MyBuilds";
import UserDropown from "./UserDropdown";
import DropdownButton from "./dropdown-button";
import HelpDropdown from "./HelpDropdown";
import NavigationButton from "./navigation-button";

import {
  PublicNavigationBottomMenu,
  PublicNavigationTopMenu,
} from "app/components/shared/PublicNavigationMenu";
import { createRefetchContainer, graphql } from "react-relay";
import logoPride from "./logo-pride.svg";
import logoBuildkite from "./logo.svg";
import navButtonRightArrowImage from "./nav-button-right-arrow.svg";

const ArrowDropdownButton = styled(DropdownButton)`
  background-repeat: no-repeat;
  background-position: center right;

  @media (min-width: 1200px) {
    background-image: url(${navButtonRightArrowImage});
    padding-right: 20px;
  }
`;

export const HighlightableNavigationButton = styled(NavigationButton)<{
  active: boolean;
}>`
  ${({ active }) =>
    active &&
    `
    background-color: var(--purple-100);
    border-radius: 3px;
    color: var(--purple-600);
    margin: 3px 0px;
  `}
`;

type Props = {
  viewer?: any;
  activeNav: string | null | undefined;
  organization?: {
    id: string;
    slug: string;
    name: string;
    iconUrl: string | null | undefined;
    permissions: any;
    public: boolean;
  };
  staff?: boolean;
  organizationShard?: string;
  suggestedDocumentation: Array<SuggestedDoc>;
  relay: any;
};

type State = {
  showingOrgDropdown: boolean;
  warning: boolean;
  lastDefaultTeam: string | null | undefined;
  showingPrideColours: boolean;
};

type SuggestedDoc = {
  title: string;
  url: string;
};

class Navigation extends React.PureComponent<Props, State> {
  componentDidMount() {
    this.props.relay.refetch({ isMounted: true });
    UserSessionStore.on("change", this.handleSessionDataChange);
  }

  UNSAFE_componentWillMount() {
    // When the page loads, we want to pull the last default team out of the UserSessionStore.
    if (this.props.organization && this.props.organization.id) {
      this.setState({
        lastDefaultTeam: UserSessionStore.get(
          `organization-default-team:${this.props.organization.id}`,
        ),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If we're moving between organizations, we need to pull out the new
    // organization's default team setting, if it's available.
    if (
      nextProps.organization &&
      nextProps.organization.id &&
      (!this.props.organization ||
        nextProps.organization.id !== this.props.organization.id)
    ) {
      this.setState({
        lastDefaultTeam: UserSessionStore.get(
          `organization-default-team:${nextProps.organization.id}`,
        ),
      });
    }
  }

  componentWillUnmount() {
    UserSessionStore.off("change", this.handleSessionDataChange);
  }

  state = {
    showingOrgDropdown: false,
    warning: window["_navigation"] && window["_navigation"]["warning"],
    lastDefaultTeam: null,
    // Pride month is June in The United States,
    // and this is generally acknowledged around the world
    // even if jurisdictions have their own dates.
    showingPrideColours: new Date().getMonth() === 5,
  };

  handleSessionDataChange = ({ key, newValue }) => {
    if (!this.props.organization) {
      return;
    }

    // When the UserSessionStore gets a change, if the update was to the
    // currently displayed team, update the state with the new value!
    if (key === `organization-default-team:${this.props.organization.id}`) {
      this.setState({
        lastDefaultTeam: newValue,
      });
    }
  };

  handleAdminContextClick = (evt: any) => {
    evt.preventDefault();
    location.href =
      "/admin/search?context=true&search%5Bquery%5D=" +
      encodeURI(location.href);
  };

  handleOrgDropdownToggle = (visible: any) => {
    this.setState({ showingOrgDropdown: visible });
  };

  renderTopOrganizationMenu() {
    if (this.props.organization) {
      return (
        <span className="flex xs-hide sm-hide md-hide">
          {this.renderOrganizationMenu({ className: "ml1" })}
        </span>
      );
    }
  }

  renderBottomOrganizationMenu() {
    if (this.props.organization) {
      return (
        <div className="border-top border-gray lg-hide">
          <div
            className="flex flex-stretch nav-container"
            style={{ height: 45, paddingLeft: 5 }}
          >
            {this.renderOrganizationMenu({ paddingLeft: 15 })}
          </div>
        </div>
      );
    }
  }

  organizationRequiresSSO({ permissions }) {
    return (
      !permissions.pipelineView.allowed &&
      permissions.pipelineView.code === "sso_authorization_required"
    );
  }

  // We check `organizationMemberView.allowed` here as an aproximation of "is the current user a
  // member of the current orgaization". It's not perfect but it's good enough.
  viewerIsMemberOfOrganization(): boolean {
    return this.props.organization &&
      this.props.organization.permissions.organizationMemberView.allowed
      ? true
      : false;
  }

  renderOrganizationsList() {
    if (!this.props.viewer || !this.props.viewer.organizations) {
      return <SectionLoader />;
    }

    const nodes = this.props.viewer.organizations.edges.map((org) => {
      return (
        <NavigationButton
          key={org.node.slug}
          href={`/${org.node.slug}`}
          className="block py1"
        >
          <OrganizationAvatar
            organization={org.node}
            className="mr1 flex-none"
            style={{ height: 26, width: 26 }}
            suppressAltText={true}
          />
          {org.node.name}
        </NavigationButton>
      );
    });

    nodes.push(
      <NavigationButton
        key="newOrganization"
        href="/organizations/new"
        className="block py1"
      >
        <Icon
          icon="plus-circle"
          className="mr1"
          style={{
            width: 26,
            height: 26,
            padding: 1,
          }}
        />
        Create New Organization
      </NavigationButton>,
    );

    if (nodes.length > 0) {
      return nodes;
    }
  }

  getOrganizationPipelinesUrl(organization: {
    iconUrl: string | null | undefined;
    id: string;
    name: string;
    permissions: any;
    public: boolean;
    slug: string;
  }) {
    let link = `/${organization.slug}`;

    // Make the link default to the user's default team, if that data exists
    if (this.state.lastDefaultTeam) {
      link = `${link}?team=${this.state.lastDefaultTeam}`;
    }

    return link;
  }

  renderOrganizationMenu(
    options: {
      paddingLeft?: number | string;
      className?: string;
    } = {},
  ) {
    const organization = this.props.organization;
    const paddingLeft =
      typeof options.paddingLeft === "number" ? options.paddingLeft : 15;

    if (organization) {
      return (
        <div className={classNames("flex", options.className)}>
          {this.renderOrganizationButtons(paddingLeft)}
        </div>
      );
    }
  }

  isActive(activeForNavName: string) {
    return this.props.activeNav === activeForNavName;
  }

  renderOrganizationButtons(paddingLeft: number) {
    const organization = this.props.organization;

    // This is already checked in `renderOrganizationMenu`, but we check here
    // again to make flow play nice.
    if (!organization) {
      return;
    }

    if (!this.viewerIsMemberOfOrganization()) {
      return;
    }

    return permissions(organization.permissions).collect(
      {
        allowed: "pipelineView",
        render: () => {
          return (
            <HighlightableNavigationButton
              key={10}
              active={this.isActive("pipelines")}
              className="py0"
              style={{ paddingLeft: paddingLeft }}
              href={this.getOrganizationPipelinesUrl(organization)}
            >
              Pipelines
            </HighlightableNavigationButton>
          );
        },
      },
      {
        allowed: "pipelineView",
        and: Features.OrganizationBuildsPage,
        render: () => {
          let url = `/organizations/${organization.slug}/builds`;
          if (Features.OrganizationBuildsPageScopeToRunning) {
            url += "?state=running";
          }

          return (
            <HighlightableNavigationButton
              key={15}
              active={this.isActive("builds")}
              className="py0"
              href={url}
            >
              Builds
            </HighlightableNavigationButton>
          );
        },
      },
      {
        allowed: "agentView",
        render: () => {
          return (
            <HighlightableNavigationButton
              key={20}
              active={this.isActive("clusters")}
              className="py0"
              href={`/organizations/${organization.slug}/clusters`}
            >
              Agents
            </HighlightableNavigationButton>
          );
        },
      },
      // Display the "Teams" item for ordinary users; these permission checks are the _inverse_ of
      // those made for the "Settings" item, ensuring only one or the other is visible.
      //
      // The settings page will redirect to the first section the user has access to. If they _just_
      // have teams view enabled, skip the redirect and go straight to the teams page.
      {
        all: {
          organizationUpdate: false,
          organizationInvitationCreate: false,
          notificationServiceUpdate: false,
          organizationBillingUpdate: false,
          teamView: true,
        },
        render: () => {
          return (
            <HighlightableNavigationButton
              key={30}
              active={this.isActive("settings")}
              className="py0"
              href={`/organizations/${organization.slug}/teams`}
            >
              Teams
            </HighlightableNavigationButton>
          );
        },
      },
      {
        allowed: "suiteView",
        and: !Features.AnalyticsHideNav,
        render: () => {
          return (
            <HighlightableNavigationButton
              key={40}
              active={this.isActive("analytics")}
              className="py0"
              href={`/organizations/${organization.slug}/analytics`}
            >
              Test Suites
            </HighlightableNavigationButton>
          );
        },
      },
      {
        always: true,
        and: Features.Packages,
        render: () => {
          return (
            <HighlightableNavigationButton
              key={50}
              active={this.isActive("packages")}
              className="py0"
              href={`/organizations/${organization.slug}/packages`}
            >
              Packages
            </HighlightableNavigationButton>
          );
        },
      },
      {
        // Display "Settings" item if any of these permissions are true; these are the _inverse_ of
        // permission checks made for the "Teams" item, ensuring only one or the other is visible.
        any: [
          "organizationUpdate",
          "organizationInvitationCreate",
          "notificationServiceUpdate",
          "organizationBillingUpdate",
        ],
        render: () => {
          return [
            <HighlightableNavigationButton
              key={60}
              active={this.isActive("settings")}
              className="py0"
              href={`/organizations/${organization.slug}/settings`}
            >
              Settings
            </HighlightableNavigationButton>,
          ];
        },
      },
    );
  }

  renderIcon() {
    return (
      <img
        src={this.state.showingPrideColours ? logoPride : logoBuildkite}
        className="buildkite-logo"
        alt="Buildkite"
        style={{
          width: 28,
          height: 19,
          marginTop: 4,
        }}
      />
    );
  }

  render() {
    if (!this.props.viewer) {
      const organization = this.props.organization
        ? {
            name: this.props.organization.name,
            slug: this.props.organization.slug,
            iconUrl: this.props.organization.iconUrl,
            isPublic: this.props.organization.public,
          }
        : null;
      // @ts-expect-error - TS2322 - Type '{ name: string; slug: string; iconUrl: string | null | undefined; isPublic: boolean; } | null' is not assignable to type 'Organization | null'.
      return <AnonymousNavigation organization={organization} />;
    }

    return (
      <nav
        className={classNames("border-bottom border-gray bg-silver", {
          "bg-warning-stripes": this.state.warning,
        })}
        style={{ fontSize: 13 }}
        data-tag={true}
      >
        <div className="nav-container">
          <div className="flex justify-between" style={{ height: 45 }}>
            <div className="flex">
              <span className="flex relative border-right border-gray items-center">
                {this.props.staff ? (
                  <Dropdown width={160}>
                    <DropdownButton
                      style={{
                        paddingLeft: 0,
                        // sorry, I need 1 less than px3 to make the logo not flicker
                        // in Chrome 64.0.3282.140
                        paddingRight: 14,
                      }}
                    >
                      {this.renderIcon()}
                    </DropdownButton>

                    <NavigationButton
                      href="/admin"
                      onClick={this.handleAdminContextClick}
                    >
                      Admin Context
                    </NavigationButton>
                    <NavigationButton href="/admin">
                      Staff Dashboard
                    </NavigationButton>
                    <NavigationButton href="/home">
                      Public Site
                    </NavigationButton>
                    <NavigationButton href="/">
                      Application Root
                    </NavigationButton>
                  </Dropdown>
                ) : (
                  <NavigationButton
                    href="/"
                    className="px3"
                    style={{
                      paddingLeft: 0,
                      // sorry, I need 1 less than px3 to make the logo not flicker
                      // in Chrome 64.0.3282.140
                      paddingRight: 14,
                    }}
                  >
                    {this.renderIcon()}
                  </NavigationButton>
                )}
              </span>

              <Dropdown
                width={250}
                className="flex"
                style={{ flex: "0 1 auto", minWidth: 0 }}
                onToggle={this.handleOrgDropdownToggle}
              >
                <ArrowDropdownButton
                  className={classNames("py0 flex-auto", {
                    purple: this.state.showingOrgDropdown,
                  })}
                  style={{
                    flex: "0 1 auto",
                    minWidth: 0,
                  }}
                >
                  {this.props.organization &&
                    this.viewerIsMemberOfOrganization() &&
                    this.props.organization.iconUrl && (
                      <OrganizationAvatar
                        organization={this.props.organization}
                        className="flex-none"
                        style={{ height: 26, width: 26 }}
                        suppressAltText={true}
                      />
                    )}
                  <span
                    className={classNames("truncate", {
                      ml1:
                        this.props.organization &&
                        this.viewerIsMemberOfOrganization() &&
                        this.props.organization.iconUrl,
                    })}
                  >
                    {this.props.organization &&
                    !this.organizationRequiresSSO(this.props.organization) &&
                    this.viewerIsMemberOfOrganization()
                      ? this.props.organization.name
                      : "Organizations"}
                  </span>
                  {this.props.organizationShard && (
                    <Badge
                      outline={true}
                      className={classNames("color-inherit semi-bold")}
                    >
                      {this.props.organizationShard}
                    </Badge>
                  )}
                  <Icon
                    icon="down-triangle"
                    className="flex-none"
                    style={{
                      width: 7,
                      height: 7,
                      marginLeft: ".5em",
                    }}
                  />
                </ArrowDropdownButton>
                {this.renderOrganizationsList()}
              </Dropdown>

              {this.viewerIsMemberOfOrganization()
                ? this.renderTopOrganizationMenu()
                : this.props.organization &&
                  this.props.organization?.public && (
                    <PublicNavigationTopMenu
                      organization={{
                        name: this.props.organization.name,
                        // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
                        iconUrl: this.props.organization.iconUrl,
                        slug: this.props.organization.slug,
                        isPublic: this.props.organization.public,
                      }}
                    />
                  )}
            </div>

            <div className="flex">
              <MyBuilds />

              <HelpDropdown
                suggestedDocumentation={this.props.suggestedDocumentation}
              />

              <UserDropown
                viewer={this.props.viewer}
                warning={this.state.warning}
                organization={this.props.organization}
              />
            </div>
          </div>
        </div>

        {this.viewerIsMemberOfOrganization()
          ? this.renderBottomOrganizationMenu()
          : this.props.organization &&
            this.props.organization?.public && (
              <PublicNavigationBottomMenu
                organization={{
                  name: this.props.organization.name,
                  // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
                  iconUrl: this.props.organization.iconUrl,
                  slug: this.props.organization.slug,
                  isPublic: this.props.organization.public,
                }}
              />
            )}
      </nav>
    );
  }
}

export default createRefetchContainer(
  Navigation,
  {
    organization: graphql`
      fragment Navigation_organization on Organization {
        ...AgentsCount_organization
        name
        id
        slug
        iconUrl
        public
        permissions {
          pipelineView {
            allowed
            code
          }
          suiteView {
            allowed
          }
          agentView {
            allowed
          }
          organizationMemberView {
            allowed
            code
          }
          organizationUpdate {
            allowed
          }
          organizationInvitationCreate {
            allowed
          }
          notificationServiceUpdate {
            allowed
          }
          organizationBillingUpdate {
            allowed
          }
          teamView {
            allowed
          }
        }
      }
    `,
    viewer: graphql`
      fragment Navigation_viewer on Viewer
      @argumentDefinitions(
        isMounted: { type: "Boolean", defaultValue: false }
      ) {
        user {
          name
          avatar {
            url
          }
        }

        organizations(first: 100) @include(if: $isMounted) {
          edges {
            node {
              id
              name
              slug
              iconUrl
              permissions {
                pipelineView {
                  allowed
                  code
                }
                suiteView {
                  allowed
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query NavigationRefetchQuery($isMounted: Boolean!) {
      viewer {
        ...Navigation_viewer @arguments(isMounted: $isMounted)
      }
    }
  `,
);
