/* eslint-disable react/no-unused-prop-types */
import BuildShowStore, { Build, FaviconPaths } from "app/stores/BuildShowStore";
import { BuildContextProvider } from "app/components/Playground/BuildContext";
import {
  RouterProvider,
  Navigate,
  RouteObject,
  useNavigate,
  createBrowserRouter,
  NonIndexRouteObject,
} from "react-router-dom";

import { ErrorView } from "app/components/shared/ErrorView";
import BuildShowLayout from "./layouts/BuildShowLayout";
import StepDrawer from "./components/StepDrawer";
import JobsPage from "./pages";
import CanvasPage from "./pages/canvas";
import SummaryPage from "./pages/summary";
import WaterfallTurboPage from "./pages/waterfall-turbo";
import WaterfallPage, { loader as waterfallLoader } from "./pages/waterfall";
import TestDigestPage from "./pages/test-digest";

export enum BuildView {
  Summary = "SUMMARY",
  JobList = "JOB_LIST",
  Waterfall = "WATERFALL",
  WaterfallTurbo = "WATERFALL_TURBO",
  Canvas = "CANVAS",
  TestDigest = "TEST_DIGEST",
}

const waterfallRoute: NonIndexRouteObject = Features.ReactWaterfall
  ? {
      path: "waterfall",
      id: BuildView.Waterfall,
      element: <WaterfallPage />,
      errorElement: <RouteError />,
      loader: waterfallLoader,
    }
  : {
      path: "waterfall",
      id: BuildView.WaterfallTurbo,
      element: <WaterfallTurboPage />,
      errorElement: <RouteError />,
    };

export const routes: RouteObject[] = Features.BuildSidebar
  ? [
      {
        path: ":org/:pipeline/builds/:number/",
        element: <BuildShowLayout />,
        errorElement: <RouteError />,
        children: [
          {
            index: true,
            element: <Navigate to="./canvas" replace={true} />,
            errorElement: <RouteError />,
          },
          {
            path: "summary",
            id: BuildView.Summary,
            element: <SummaryPage />,
            errorElement: <RouteError />,
            children: [
              {
                path: "test_digest",
                id: BuildView.TestDigest,
                element: <TestDigestPage />,
                errorElement: <RouteError />,
              },
            ],
          },
          {
            path: "canvas",
            id: BuildView.Canvas,
            element: <CanvasPage />,
            errorElement: <RouteError />,
            children: [
              {
                path: ":stepId",
                element: <StepDrawer />,
              },
            ],
          },
          waterfallRoute,
        ],
      },
    ]
  : [
      {
        path: ":org/:pipeline/builds/:number/",
        element: <BuildShowLayout />,
        errorElement: <RouteError />,
        children: [
          {
            index: true,
            id: BuildView.JobList,
            element: <JobsPage />,
            errorElement: <RouteError />,
          },
          {
            path: "canvas",
            id: BuildView.Canvas,
            element: <CanvasPage />,
            errorElement: <RouteError />,
            children: [
              {
                path: ":stepId",
                element: <Navigate to=".." />,
              },
            ],
          },
          waterfallRoute,
          {
            path: "test_digest",
            id: BuildView.TestDigest,
            element: <TestDigestPage />,
            errorElement: <RouteError />,
          },
        ],
      },
    ];

function RouteError() {
  const navigate = useNavigate();

  // eslint-disable-next-line react/jsx-no-bind
  return <ErrorView clearError={() => navigate(0)} />;
}

const router = createBrowserRouter(routes);

interface PageProps {
  build: Build;
  faviconPaths: FaviconPaths;
  waterfallAvailable: boolean;
}

export default (props: PageProps) => {
  const store = new BuildShowStore(props);

  return (
    <BuildContextProvider store={store}>
      <RouterProvider router={router} />
    </BuildContextProvider>
  );
};
