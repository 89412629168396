import { useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";

import { createConsumer } from "app/vendor/actioncable";
import Row from "app/components/analytics/suite/Setup/Row";
import Instruction from "app/components/analytics/suite/Setup/Instruction";
import frameworks from "./frameworks";

type Props = {
  suite: {
    id: string;
    name: string;
    apiKey: string;
  };
  suiteSettingsUrl: string;
  cableUrl: string;
  detectedLanguages: Array<string>;
};

const Separator = styled.hr`
  border: none;
  border-bottom: 1px solid #ddd;
`;

const AnalyticsSuiteSetup = ({
  suite,
  suiteSettingsUrl,
  cableUrl,
  detectedLanguages,
}: Props) => {
  const [selectedFramework, setSelectedFramework] = useState<string>();
  const consumer = useRef(createConsumer(cableUrl));

  const processMessage = (data: { run_path: string }) => {
    setTimeout(() => {
      window.location.href = `${data.run_path}?first_run=true&branch=all%20branches`;
    }, 2000);
  };

  useEffect(() => {
    if (consumer.current) {
      consumer.current.subscriptions.create(
        {
          channel: "Analytics::SuiteChannel",
          uuid: suite.id,
        },
        {
          received: (data) => {
            processMessage(data);
          },
        },
      );
    }

    return () => {
      if (consumer.current) {
        consumer.current.disconnect();
      }
    };
  }, [suite]);

  const filteredFrameworks = useMemo(
    () =>
      frameworks.filter((framework) =>
        detectedLanguages.includes(framework.language),
      ),
    [detectedLanguages],
  );
  const otherFrameworks = useMemo(
    () =>
      frameworks.filter(
        (framework) => !detectedLanguages.includes(framework.language),
      ),
    [detectedLanguages],
  );

  const handleSelectFramework = (framework) => () => {
    setSelectedFramework(framework);
  };

  return (
    <div className="flex">
      <div className="col-4 py4 pr4">
        <h4 className="mt0 line-height-3">
          Set up an integrated test collector:
        </h4>
        {detectedLanguages.length > 0 && (
          <>
            <ul
              className="list-style-none pl0"
              data-testid="filtered-collector-options"
            >
              {filteredFrameworks.map((framework) => (
                <Row
                  key={framework.name}
                  onClick={handleSelectFramework(framework.name)}
                  emoji={framework.emoji}
                  note={framework.language}
                  label={framework.label}
                  selected={selectedFramework === framework.name}
                />
              ))}
            </ul>

            <div className="my4" style={{ padding: "0 3rem" }}>
              <Separator />
            </div>
          </>
        )}

        <ul className="list-style-none pl0" data-testid="collector-options">
          {otherFrameworks.map((framework) => (
            <Row
              key={framework.name}
              onClick={handleSelectFramework(framework.name)}
              note={framework.language}
              emoji={framework.emoji}
              label={framework.label}
              selected={selectedFramework === framework.name}
            />
          ))}
          <Row
            onClick={handleSelectFramework("other")}
            note="All languages"
            emoji=":bash:"
            label="Other"
            selected={selectedFramework === "other"}
          />
        </ul>

        <h4 className="mt4 pt2 line-height-2">Or, upload data via the API:</h4>

        <ul className="list-style-none pl0" data-testid="upload-options">
          <Row
            onClick={handleSelectFramework("json")}
            note="All languages"
            emoji=":json:"
            label="JSON"
            selected={selectedFramework === "json"}
          />
          <Row
            onClick={handleSelectFramework("junit")}
            note="All languages"
            emoji=":junit:"
            label="JUnit XML"
            selected={selectedFramework === "junit"}
          />
        </ul>

        <p className="dark-gray mt4 pt2">
          Changed your mind?{" "}
          <a href={suiteSettingsUrl} className="semi-bold black ">
            Delete this test suite
          </a>
        </p>
      </div>

      <Instruction
        framework={selectedFramework}
        setFramework={setSelectedFramework}
      />
    </div>
  );
};

export default AnalyticsSuiteSetup;
