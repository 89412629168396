import { useBuild } from "app/components/Playground/BuildContext";
import AnnotationsListRenderer from "../components/AnnotationsListRenderer";

export default function SummaryPage() {
  const { build } = useBuild();

  if (!build) {
    throw new Error("Missing build context");
  }

  return (
    <div className="w-full">
      <AnnotationsListRenderer
        params={{
          buildSlug: `${build.account.slug}/${build.project.slug}/${build.number}`,
        }}
        organization={build.account.slug}
        pipeline={build.project.slug}
        number={build.number}
        filterIssues={false}
        hasFailedJobs={false}
      />
    </div>
  );
}
