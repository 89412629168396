import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import Environment from "app/lib/relay/environment";

import Panel from "app/components/shared/Panel";
import Button from "app/components/shared/Button";
import PageHeader from "app/components/shared/PageHeader";
import PageWithContainer from "app/components/shared/PageWithContainer";
import SectionLoader from "app/components/shared/SectionLoader";

import withHistory, { LocationHistoryContext } from "app/lib/withHistory";

import Jobs from "./Jobs";
import SearchInput from "./search-input";

type Props = {
  organizationSlug: string;
  location: {
    query: {
      q?: string;
    };
  };
};

type State = {
  query: string | null | undefined;
  searchInputValue: string | null | undefined;
};

class JobIndex extends React.Component<Props, State> {
  environment = Environment.get();

  static query = graphql`
    query IndexJobIndexQuery($organizationSlug: ID!) {
      organization(slug: $organizationSlug) {
        name
        slug
        ...Jobs_organization
      }
    }
  `;

  static contextType = LocationHistoryContext;

  constructor(initialProps: any) {
    super(initialProps);

    // Figure out if the default query
    const query =
      this.props.location.query.q !== undefined
        ? this.props.location.query.q
        : "state:scheduled";
    this.state = { query: query, searchInputValue: query };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // When the `q` param in the URL changes, do a new search
    const query = nextProps.location.query.q;
    if (query !== undefined && this.state.query !== query) {
      this.setState({ query: query, searchInputValue: query });
    }
  }

  get queryVariables() {
    return {
      organizationSlug: this.props.organizationSlug,
    };
  }

  render() {
    return (
      <QueryRenderer
        fetchPolicy="store-and-network"
        cacheConfig={{ force: true }}
        environment={this.environment}
        query={JobIndex.query}
        variables={this.queryVariables}
        render={this.renderQuery()}
      />
    );
  }

  renderQuery =
    () =>
    ({ error, props }) => {
      if (error) {
        return;
      }

      if (!props || !props.organization) {
        return <SectionLoader />;
      }

      return (
        <PageWithContainer>
          <PageHeader>
            <PageHeader.Title>Jobs</PageHeader.Title>
          </PageHeader>

          <Panel className="mb4">
            <Panel.Section>
              <form
                onSubmit={this.handleFormSubmit}
                className="flex items-stretch"
              >
                <SearchInput
                  value={this.state.searchInputValue}
                  onChange={this.handleSearchInputChange}
                />
                <Button className="ml3">Search</Button>
              </form>

              <div className="dark-gray mt1">
                You can further filter jobs using <code>state:scheduled</code>,{" "}
                <code>concurrency-group:custom-group</code> or{" "}
                <code>passed:true/false</code>
              </div>
            </Panel.Section>
          </Panel>

          <Jobs
            query={this.state.query}
            organization={props.organization}
            onSuggestionClick={this.handleSuggestionClick}
          />
        </PageWithContainer>
      );
    };

  handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchInputValue: event.target.value });
  };

  handleFormSubmit = (event: any) => {
    event.preventDefault();

    this.context.history.push(
      `/organizations/${this.props.organizationSlug}/jobs?q=${
        this.state.searchInputValue || ""
      }`,
    );
  };

  handleSuggestionClick = (suggestion: any) => {
    const query = `${this.state.searchInputValue || ""} ${suggestion}`;

    this.context.history.push(
      `/organizations/${this.props.organizationSlug}/jobs?q=${query}`,
    );
  };
}

export default withHistory(JobIndex);
