/**
 * @generated SignedSource<<c335c6515e267e5947ddb29c03446229>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobStates = "ACCEPTED" | "ASSIGNED" | "BLOCKED" | "BLOCKED_FAILED" | "BROKEN" | "CANCELED" | "CANCELING" | "EXPIRED" | "FINISHED" | "LIMITED" | "LIMITING" | "PENDING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "TIMED_OUT" | "TIMING_OUT" | "UNBLOCKED" | "UNBLOCKED_FAILED" | "WAITING" | "WAITING_FAILED" | "%future added value";
export type JobPrioritySearch = {
  number?: ReadonlyArray<number> | null;
};
export type JobConcurrencySearch = {
  group?: ReadonlyArray<string> | null;
};
export type JobsRefetchQuery$variables = {
  agentQueryRules?: ReadonlyArray<string> | null;
  concurrency?: JobConcurrencySearch | null;
  isMounted: boolean;
  organizationSlug: string;
  pageSize?: number | null;
  passed?: boolean | null;
  priority?: JobPrioritySearch | null;
  states?: ReadonlyArray<JobStates> | null;
};
export type JobsRefetchQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"Jobs_organization">;
  } | null;
};
export type JobsRefetchQuery = {
  response: JobsRefetchQuery$data;
  variables: JobsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agentQueryRules"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "concurrency"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isMounted"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "passed"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priority"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "states"
},
v8 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v9 = {
  "kind": "Variable",
  "name": "agentQueryRules",
  "variableName": "agentQueryRules"
},
v10 = {
  "kind": "Variable",
  "name": "concurrency",
  "variableName": "concurrency"
},
v11 = {
  "kind": "Variable",
  "name": "passed",
  "variableName": "passed"
},
v12 = {
  "kind": "Variable",
  "name": "priority",
  "variableName": "priority"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": [
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "kind": "Variable",
                "name": "isMounted",
                "variableName": "isMounted"
              },
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              },
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "kind": "Variable",
                "name": "states",
                "variableName": "states"
              }
            ],
            "kind": "FragmentSpread",
            "name": "Jobs_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "JobsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "condition": "isMounted",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "pageSize"
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "state",
                    "variableName": "states"
                  },
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "COMMAND"
                  }
                ],
                "concreteType": "JobConnection",
                "kind": "LinkedField",
                "name": "jobs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "state",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JobPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "agentQueryRules",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "passed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JobConcurrency",
                                "kind": "LinkedField",
                                "name": "concurrency",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "group",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "limit",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "label",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "command",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Build",
                                "kind": "LinkedField",
                                "name": "build",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Pipeline",
                                    "kind": "LinkedField",
                                    "name": "pipeline",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v14/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v14/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "type": "JobTypeCommand",
                            "abstractKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isJob"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v14/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15b2492d0ab1ce93b1595136b2a0ecef",
    "id": null,
    "metadata": {},
    "name": "JobsRefetchQuery",
    "operationKind": "query",
    "text": "query JobsRefetchQuery(\n  $organizationSlug: ID!\n  $isMounted: Boolean!\n  $priority: JobPrioritySearch\n  $agentQueryRules: [String!]\n  $concurrency: JobConcurrencySearch\n  $states: [JobStates!]\n  $passed: Boolean\n  $pageSize: Int\n) {\n  organization(slug: $organizationSlug) {\n    ...Jobs_organization_2D55O6\n    id\n  }\n}\n\nfragment JobLink_job on JobTypeCommand {\n  ...JobTitle_job\n  url\n}\n\nfragment JobTitle_job on JobTypeCommand {\n  label\n  command\n  build {\n    number\n    pipeline {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment Jobs_organization_2D55O6 on Organization {\n  name\n  slug\n  jobs(first: $pageSize, type: COMMAND, state: $states, priority: $priority, agentQueryRules: $agentQueryRules, concurrency: $concurrency, passed: $passed) @include(if: $isMounted) {\n    edges {\n      node {\n        __typename\n        ... on JobTypeCommand {\n          id\n        }\n        ...Row_job\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n    pageInfo {\n      hasNextPage\n    }\n  }\n}\n\nfragment Row_job on Job {\n  __isJob: __typename\n  ... on JobTypeCommand {\n    id\n    state\n    priority {\n      number\n    }\n    agentQueryRules\n    passed\n    concurrency {\n      group\n      limit\n    }\n    createdAt\n  }\n  ...JobLink_job\n}\n"
  }
};
})();

(node as any).hash = "4ad7efbaa09880474b7253e4b86676b0";

export default node;
