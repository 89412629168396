import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export enum DockPosition {
  Right = "right",
  Bottom = "bottom",
}

interface BuildPreferences {
  dockPosition: DockPosition;
  setDockPosition: (dockPosition: DockPosition) => void;

  /**
   * Resized drawer height (in percentage)
   */
  resizedDrawerHeight?: number;
  setResizedDrawerHeight: (height: number) => void;

  /**
   * Resized drawer width (in percentage)
   */
  resizedDrawerWidth?: number;
  setResizedDrawerWidth: (width: number) => void;
}

/**
 * A store for build page preferences persisted into local storage to ensure
 * the user's preferences are remembered between sessions.
 */
export const useBuildPreferencesStore = create<BuildPreferences>()(
  persist(
    (set) => ({
      dockPosition: DockPosition.Bottom,
      resizedDrawerHeight: 50,
      resizedDrawerWidth: 50,

      setDockPosition: (dock: DockPosition) =>
        set(() => ({ dockPosition: dock })),

      setResizedDrawerHeight: (height: number) =>
        set(() => ({ resizedDrawerHeight: height })),

      setResizedDrawerWidth: (width: number) =>
        set(() => ({ resizedDrawerWidth: width })),
    }),
    {
      name: "build-preferences",
      storage: createJSONStorage(() => localStorage),
      version: 1,
    },
  ),
);
