import ExecutionResult from "app/components/analytics/shared/ResultIcons/ExecutionResult";
import FailureReason from "app/components/analytics/shared/FailureReason";
import useLoadMore, { LoadMoreProps } from "../../shared/hooks/useLoadMore";
import LoadMoreButton from "../../shared/LoadMoreButton";
import BuildNumber from "../../shared/Metadata/BuildNumber";
import { Execution } from "app/components/analytics/shared/type";
import FriendlyTime from "app/components/shared/FriendlyTime";
import { StyledUnderline } from "../../shared/styles";
import ExecutionDrawerButton from "../../execution/Row/ExecutionDrawerButton";

export type FlakyExecution = Omit<
  Execution,
  "duration" | "humanDuration" | "uuid"
>;

type ExecutionsProps = {
  executions: Array<FlakyExecution>;
  nextPage?: string;
};

type ExecutionsLoadMoreProps = Omit<LoadMoreProps, "collection"> & {
  collection: Array<FlakyExecution>;
};

const ExecutionsList = ({ executions, nextPage }: ExecutionsProps) => {
  const {
    collection,
    handleClickLoadMore,
    hasNextPage,
    loading,
  }: ExecutionsLoadMoreProps = useLoadMore({ data: executions, url: nextPage });

  return (
    <div className="py-5 px-8">
      <ul className="grid gap-2 list-none">
        {collection.map((execution) => (
          <li key={execution.id} className="grid gap-2 charcoal-800">
            <div className="flex gap-2">
              <ExecutionResult.Small result={execution.result.toUpperCase()} />

              <div className="flex gap-2 text-sm">
                <span>
                  {window.Features.AnalyticsExecutionDrawer ? (
                    <ExecutionDrawerButton
                      url={execution.url}
                      classes="color-inherit hover-faded underline"
                    >
                      View execution
                    </ExecutionDrawerButton>
                  ) : (
                    <a
                      className="color-inherit hover-faded"
                      href={execution.url}
                    >
                      View execution
                    </a>
                  )}
                </span>
                <span className="before:content-['·'] before:text-charcoal-300 before:pr-2">
                  <StyledUnderline
                    as={FriendlyTime}
                    value={execution.createdAt}
                    className="hover-faded"
                  />
                </span>
                {(execution.buildNumber || execution.buildUrl) && (
                  <span className="before:content-['·'] before:text-charcoal-300 before:pr-2">
                    <BuildNumber
                      number={execution.buildNumber}
                      url={execution.buildUrl}
                    />
                  </span>
                )}
              </div>
            </div>

            {execution.failureReason != null && (
              <div className="pl-6">
                <FailureReason
                  failureReason={execution.failureReason}
                  endpoint={execution.url}
                  failureExpanded={execution.failureExpanded}
                />
              </div>
            )}
          </li>
        ))}
      </ul>

      {hasNextPage && (
        <LoadMoreButton
          loading={loading}
          collectionName="executions"
          handleLoadMore={handleClickLoadMore}
        />
      )}
    </div>
  );
};

export default ExecutionsList;
