import { useCallback, useState } from "react";

import Icon from "app/components/shared/Icon";
import BuildsStore from "app/stores/BuildsStore";

import Steps from "./Steps";
import { Job } from "./types/Job";

function getHiddenJobsCount(jobs?: Array<Job>) {
  if (!jobs) {
    return 0;
  }

  return jobs.filter(
    (job) =>
      (job.type !== "waiter" && job.state === "broken") ||
      ((job.type === "script" || job.type === "trigger") &&
        job.retriedInJobUuid),
  ).length;
}

export default function BuildHeader({
  build,
  store,
}: {
  build: {
    id: string;
    path: string;
    jobs?: Array<Job>;
    jobsCount: number;
  };
  store: BuildsStore;
}) {
  const hiddenJobsCount = getHiddenJobsCount(build.jobs);

  const [showHiddenJobs, setShowHiddenJobs] = useState(false);

  const toggleHiddenJobs = useCallback(
    (event) => {
      event.preventDefault();

      if (event.target instanceof HTMLElement) {
        event.target.blur();
      }

      setShowHiddenJobs(!showHiddenJobs);
    },
    [showHiddenJobs],
  );

  return (
    <div className="flex text-charcoal-900 builds-usability_block-group-step-styles_enabled">
      <div className="flex flex-wrap flex-auto mb2 mt2 mx4 gap1">
        <Steps build={build} store={store} showHiddenJobs={showHiddenJobs} />
      </div>

      <div className="flex-none" style={{ paddingTop: 18 }}>
        {Boolean(hiddenJobsCount) && (
          <button
            className="btn dark-gray hover-black regular mt0 ml0 pt0 pl0 mr2 right-tooltip"
            onClick={toggleHiddenJobs}
            aria-label={`${
              showHiddenJobs ? "Hide" : "Show"
            } ${hiddenJobsCount} hidden ${
              hiddenJobsCount > 1 ? "jobs" : "job"
            }`}
            style={{ width: 22, height: 22 }}
          >
            <Icon
              icon={showHiddenJobs ? "eye-strikethrough" : "eye"}
              className="relative"
              style={{ top: -3 }}
            />
          </button>
        )}
      </div>
    </div>
  );
}

// We need to be able to conditionally wrap content for styling purposes, in particular
// for hard failed steps which have different layout requirements to 'regular' steps.
// Some day I would love to tease apart the abstractions for step pills into more
// concrete implementations, but this will get us by for now.
export const StepContentWrapper = ({
  children = null,
  shouldWrap = true,
}: {
  children?: React.ReactNode;
  shouldWrap?: boolean;
}) => {
  return shouldWrap ? (
    <div className="build-pipeline-job-content">{children}</div>
  ) : (
    children
  );
};
