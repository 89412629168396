import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import Panel from "app/components/shared/Panel";
import JobLink from "app/components/shared/JobLink";
import FriendlyTime from "app/components/shared/FriendlyTime";
import JobState from "app/components/icons/JobState";

type Props = {
  job: any;
  onConcurrencyGroupClick: (concurrencyGroup: string) => void;
  onPriorityClick: (priority: number) => void;
  onAgentQueryRuleClick: (agentQueryRule: string) => void;
};

class Row extends PureComponent<Props> {
  render() {
    return (
      <Panel.Row>
        <div className="flex items-center">
          <div className="flex-none flex items-center" style={{ width: 120 }}>
            <JobState.Small job={this.props.job} className="flex-none mr2" />
            <code className="code">
              {this.props.job.state && this.props.job.state.toLowerCase()}
            </code>
          </div>
          <div className="flex-auto">
            <JobLink job={this.props.job} />
            <div className="dark-gray mt1 flex">
              {this.renderQueryRules()}
              {this.renderPriority()}
            </div>
          </div>
          {this.renderConcurrency()}
          {this.props.job.createdAt && (
            <div className="flex-none dark-gray">
              Created <FriendlyTime value={this.props.job.createdAt} />
            </div>
          )}
        </div>
      </Panel.Row>
    );
  }

  renderConcurrency() {
    if (this.props.job.concurrency) {
      return (
        <div className="flex-none pr4">
          <code className="dark-gray">
            <span
              className="cursor-pointer hover-underline-dotted"
              onClick={this.handleConcurrencyGroupClick}
            >
              {this.props.job.concurrency.group}
            </span>{" "}
            [{this.props.job.concurrency.limit}]
          </code>
        </div>
      );
    }
  }

  renderPriority() {
    if (this.props.job.priority && this.props.job.priority.number !== 0) {
      return (
        <div className="dark-gray">
          (
          <span
            className="cursor-pointer hover-underline-dotted"
            onClick={this.handlePriorityClick}
          >
            Priority <code>{this.props.job.priority.number}</code>
          </span>
          )
        </div>
      );
    }
  }

  renderQueryRules() {
    const agentQueryRules: ReadonlyArray<string> =
      !this.props.job.agentQueryRules || !this.props.job.agentQueryRules.length
        ? ["queue=default"]
        : this.props.job.agentQueryRules;

    return agentQueryRules.map((agentQueryRule) => {
      return (
        <code
          key={agentQueryRule}
          onClick={(event) =>
            this.handleAgentQueryRuleClick(event, agentQueryRule)
          }
          className="cursor-pointer hover-underline-dotted mr1"
        >
          {agentQueryRule}
        </code>
      );
    });
  }

  handleConcurrencyGroupClick = (event: any) => {
    event.preventDefault();

    if (this.props.job.concurrency) {
      this.props.onConcurrencyGroupClick(this.props.job.concurrency.group);
    }
  };

  handlePriorityClick = (event: any) => {
    event.preventDefault();

    if (
      this.props.job.priority &&
      this.props.job.priority.number !== undefined &&
      this.props.job.priority.number !== null
    ) {
      this.props.onPriorityClick(this.props.job.priority.number);
    }
  };

  handleAgentQueryRuleClick = (event: any, agentQueryRule: string) => {
    event.preventDefault();

    this.props.onAgentQueryRuleClick(agentQueryRule);
  };
}

export default createFragmentContainer(Row, {
  job: graphql`
    fragment Row_job on Job {
      ... on JobTypeCommand {
        id
        state
        priority {
          number
        }
        agentQueryRules
        passed
        concurrency {
          group
          limit
        }
        createdAt
      }
      ...JobLink_job
    }
  `,
});
