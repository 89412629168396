import { WaterfallRowType } from "app/stores/BuildShowStore";
import classNames from "classnames";
import Emojify from "app/components/shared/Emojify";
import MaybeLink from "./MaybeLink";
import Badge from "app/components/shared/Badge";

type TextFormatter = Record<
  WaterfallRowType["label"]["format"],
  (text: string) => React.ReactNode
>;

const textFormatter: TextFormatter = {
  emojify: (text) => <Emojify title={text} text={text} />,
  raw: (text) => <span title={text}>text</span>,
  code: (text) => (
    <code title={text} className="monospace">
      {text}
    </code>
  ),
};

type WaterfallRowLabelProps = {
  data: WaterfallRowType;
  isLastDescendant: boolean;
  depth: number;
};

export default function WaterfallRowLabel(props: WaterfallRowLabelProps) {
  return (
    <MaybeLink
      to={props.data.job_url}
      className={classNames(
        props.depth > 0 && "bg-white border-l",
        props.isLastDescendant ? "" : "border-b",
        "flex-1 line-height-4 h-[52px] px-[15px] truncate border-gray text-decoration-none block",
      )}
    >
      <div className="flex items-center w-full h-full">
        <span
          className={classNames(props.depth > 0 ? "medium" : "semi-bold")}
          data-testid="row-label"
        >
          {props.data.parallel_group_index &&
            props.data.parallel_group_total && (
              <Badge outline={true} className="charcoal-500 mr-1">
                {props.data.parallel_group_index}/
                {props.data.parallel_group_total}
              </Badge>
            )}
          {textFormatter[props.data.label.format](props.data.label.text)}
        </span>
      </div>
    </MaybeLink>
  );
}
