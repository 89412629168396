import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import Duration from "app/components/shared/Duration";
import { jobTime } from "app/lib/jobs";
import WaitTime from "./WaitTime";
import { twMerge } from "tailwind-merge";
import { TriggerJob } from "app/components/build/Header/pipeline/types/TriggerJob";

interface Props {
  job: CommandJob;
  build: {
    account: {
      jobMinuteLimitReached: boolean;
    };
  };
  // It is being used!
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
}

// If a job is assigned to an agent, it must have an agent assigned at timestamp
function isJobAssigned(
  job: CommandJob,
): job is CommandJob & Required<Pick<CommandJob, "agentAssignedAt">> {
  return job.state === "assigned";
}

const WaitDuration = ({ job, build }: Props) => {
  if (job.state === "scheduled") {
    // Only show waiting for scheduled jobs which are runnable
    if (job.runnableAt) {
      return (
        <WaitTime
          label={
            build.account.jobMinuteLimitReached
              ? "Waiting for job minutes"
              : "Waiting for agent"
          }
          from={job.runnableAt}
          to={job.startedAt}
        />
      );
    }
  } else if (job.state === "limited") {
    return (
      <WaitTime
        label="Waiting on concurrency group"
        from={job.runnableAt || job.createdAt}
        to={job.startedAt}
        variant="highlight"
      />
    );
  } else if (isJobAssigned(job)) {
    return <WaitTime label="Waiting to accept" from={job.agentAssignedAt} />;
  } else if (job.state === "skipped") {
    // Return a version that doesn't include the timer if it's been skipped
    return (
      <div>
        <span>Skipped</span>
      </div>
    );
  } else if (job.state === "expired") {
    return (
      <WaitTime
        label="Waited"
        from={job.runnableAt || job.createdAt}
        to={job.expiredAt}
      />
    );
  } else if (
    job.state === "blocked" ||
    job.state === "waiting" ||
    job.state === "waiting_failed"
  ) {
    // Hide on jobs which are blocked or waiting
    return null;
  } else if (job.runnableAt && (job.startedAt || job.canceledAt)) {
    return (
      <WaitTime
        label="Waited"
        from={job.runnableAt}
        to={job.startedAt || job.canceledAt}
      />
    );
  }

  return null;
};

export const RunDuration = ({ job }: { job: CommandJob | TriggerJob }) => {
  const runTense = job.state === "running" ? "Running for" : "Ran in";

  const duration = jobTime({
    state: job.state.toUpperCase(),
    startedAt: job.startedAt,
    finishedAt: job.finishedAt,
    canceledAt: job.canceledAt,
    timedOutAt: job.timedOutAt,
  });

  return (
    <span>
      {runTense} <Duration.Short {...duration} />
    </span>
  );
};

export const JobDuration = (props: Props) => {
  return (
    <div
      className={twMerge("flex nowrap items-center gap-x-1", props.className)}
    >
      <div className="xs-hide">
        <WaitDuration {...props} />
      </div>

      {props.job.startedAt && (
        <>
          <span className="xs-hide">·</span>
          <div>
            <RunDuration {...props} />
          </div>
        </>
      )}
    </div>
  );
};
