import { useBuild } from "app/components/Playground/BuildContext";
import { Step } from "app/lib/pipeline";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

/**
 * A link to a step (with optional job ID).
 */
export const StepLink = ({
  step,
  className,
  jobID,
  children,
  onClick,
}: {
  children: React.ReactNode;
  step: Step;
  jobID?: string;
  className?: string;
  onClick?: () => void;
}) => {
  const { build } = useBuild();

  let path = `${build?.path}/canvas/${step.uuid}`;
  if (jobID) {
    path = `${build?.path}/canvas/${step.uuid}#${jobID}`;
  }

  return (
    <Link
      onClick={onClick}
      key={step.uuid}
      to={path}
      className={twMerge(
        "flex flex-1 items-baseline text-charcoal-700 no-underline hover:no-underline active:no-underline focus:no-underline hover:bg-gray-200 rounded-md",
        className,
      )}
    >
      {children}
    </Link>
  );
};
