import { useCallback, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import useFetch from "use-http";

import InteractiveChart, {
  InteractiveChartProps,
} from "app/components/analytics/shared/InteractiveChart";
import Dialog from "app/components/shared/Dialog";
import Tooltip from "app/components/analytics/shared/Tooltips/BasicTooltip";
import MiniChart from "./MiniChart";

const StyledCard = styled.span`
  flex: none;
  justify-content: space-between;
  min-width: 0;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.09),
    0px 1px 0px rgba(0, 0, 0, 0.09),
    0px 2px 2px rgba(0, 0, 0, 0.04),
    0px 3px 3px rgba(0, 0, 0, 0.02),
    0px 4px 4px rgba(0, 0, 0, 0.01);

  @media (min-width: 769px) {
    display: flex;
    align-items: stretch;
    flex: 1 1 0;
    flex-direction: column;
    max-width: 300px;
  }

  &:hover {
    box-shadow:
      0px 0px 0px 1px rgba(0, 0, 0, 0.09),
      0px 1px 0px rgba(0, 0, 0, 0.09),
      0px 2px 2px rgba(0, 0, 0, 0.04),
      0px 10px 14px rgba(0, 0, 0, 0.02),
      0px 6px 7px -2px rgba(0, 0, 0, 0.08);
  }
`;

const ChartCard = ({
  heading,
  label,
  filter,
  branch,
  metric,
  endpoint,
  description,
  chartType,
}: InteractiveChartProps) => {
  const url = new URL("chart_metric", endpoint);
  url.searchParams.append("from", moment(filter.from).format("YYYY-MM-DD"));
  url.searchParams.append("metric", metric);
  if (branch != null) {
    url.searchParams.append("branch", branch);
  }

  const { loading, error, data } = useFetch(url.href, [filter, branch]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = useCallback(() => setIsModalOpen(false), []);
  const handleCardOnClick = useCallback(() => setIsModalOpen(true), []);

  return (
    <StyledCard
      id={`chart-card-${metric}`}
      className="relative cursor-pointer rounded bg-white text-left"
    >
      {description && (
        <div className="absolute top-4 right-5 z2" style={{ top: "1.3em" }}>
          <Tooltip name={`${label} calculation`}>
            <>
              <p className="font-semibold m-0">{label}</p>
              <p
                className="m-0 mt-1"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </>
          </Tooltip>
        </div>
      )}

      <button
        aria-label="Open chart"
        className="flex flex-col h-full w-full unstyled-button p-5 font-sans font-normal text-left no-title-tooltip"
        onClick={handleCardOnClick}
      >
        <div className="text-sm mb-1 pr-6">{label}</div>
        <div className="flex flex-col justify-between h-full w-full overflow-hidden">
          {error && (
            <div>There was an error loading {label.toLowerCase()} data</div>
          )}
          {!error && (
            <div className="h1 bold mb4">
              {data != null && !loading ? (
                data
              ) : (
                <Skeleton containerTestId="loading-skeleton" />
              )}
            </div>
          )}
          <div>
            <MiniChart
              filter={filter}
              branch={branch}
              metric={metric}
              endpoint={endpoint}
              chartType={chartType}
            />
          </div>
        </div>
      </button>

      <Dialog
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        width="80vw"
      >
        <div className="p4">
          <InteractiveChart
            label={label}
            filter={filter}
            branch={branch}
            metric={metric}
            endpoint={endpoint}
            heading={heading}
            description={description}
            chartType={chartType}
          />
        </div>
      </Dialog>
    </StyledCard>
  );
};

export default ChartCard;
