import reactRenderer from "app/lib/reactRenderer";
import merge from "lodash/merge";
import Chart from "../Chart";
import UsageTooltip from "./UsageTooltip";

const initialTooltipStyle = `
  position: absolute;
  bottom: 0px;
  left: 0px;
  transition: transform 1ms ease-in, opacity 100ms ease-in 100ms;
  z-index: 100;
  pointer-events: none;
`;

const findOrCreateTooltip = (canvas: any) => {
  let tooltipEl = canvas.parentElement.querySelector("#chartjs-tooltip");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.style.cssText = initialTooltipStyle;
    canvas.parentElement.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const renderTooltip = (context: any) => {
  const { tooltip, chart } = context;

  const tooltipEl = findOrCreateTooltip(chart.canvas);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  tooltipEl.style.opacity = "1";
  tooltipEl.style.transform = `translate(${tooltip.caretX}px,0)`;

  reactRenderer(
    <UsageTooltip
      titles={tooltip.title || []}
      bodyLines={tooltip.body.reverse()}
    />,
    tooltipEl,
  );
};

const getUsageChartOptions = ({
  chartData,
  showDateLabels,
  showBottomBorder,
}: {
  chartData: any;
  showDateLabels: boolean;
  showBottomBorder: boolean;
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "hover",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        mode: "index",
        external: renderTooltip,
      },
    },
    scales: {
      // eslint-disable-next-line id-length
      x: {
        stacked: false,
        grid: {
          display: false,
          drawBorder: showBottomBorder,
        },
        ticks: {
          callback: (value, index, ticks) => {
            if (!showDateLabels) {
              return "";
            }
            if (index > 0 && index < ticks.length - 1) {
              return "";
            }

            return chartData.data.labels[index] || value;
          },
          display: true,
          autoSkip: false,
          maxRotation: 0,
          color: "#767676",
          font: {
            weight: 500,
            size: 12,
          },
        },
      },
      // eslint-disable-next-line id-length
      y: {
        display: false,
      },
      y1: {
        display: false,
      },
    },
  } as const;

  return {
    ...chartData,
    options: merge({}, options, chartData?.options),
  };
};

type UsageChartProps = {
  chartData: {
    [key: string]: any;
  };
  height: number;
  canvasId?: string;
  showDateLabels?: boolean;
  showBottomBorder?: boolean;
};

const UsageChart = ({
  showDateLabels = true,
  showBottomBorder = true,
  chartData,
  ...rest
}: UsageChartProps) => {
  const chartOptions = getUsageChartOptions({
    chartData,
    showDateLabels,
    showBottomBorder,
  });
  return <Chart chartOptions={chartOptions} {...rest} />;
};

export default UsageChart;
