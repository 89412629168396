import { useBuild } from "app/components/Playground/BuildContext";
import {
  useParams,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Drawer } from "../Drawer";
import { useCallback, useEffect } from "react";
import { ErrorBoundary } from "app/lib/Bugsnag";
import { ErrorView } from "app/components/shared/ErrorView";
import BuildPageStore, { parseHash } from "app/stores/BuildPageStore";
import { isParallelCommandStep } from "app/lib/pipeline";
import Job from "app/components/job/Job";
import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";

type RouteParams = {
  stepId: string;
};

export const StepDrawer = () => {
  const navigate = useNavigate();
  const { build, store } = useBuild();
  const { stepId = "" } = useParams<RouteParams>();
  const { hash } = useLocation();

  if (!build || !store) {
    throw new Error("Missing build context");
  }

  const handleClose = useCallback(() => {
    navigate(`${build.path}/canvas`);
  }, []);

  // The trigger/root step isn't a real step in the pipeline so for now we
  // just ignore it.
  if (stepId === "root") {
    return null;
  }

  // Redirect for unknown steps.
  const step = build.steps.find((step) => step.uuid === stepId);
  if (!step) {
    return <Navigate to={`${build.path}`} />;
  }

  if (step.type !== "command") {
    return null;
  }

  const { job: jobID } = parseHash(hash) || {};
  let job = jobID ? build.jobs.find((job) => job.id === jobID) : null;

  // If there's no job in the hash, show the last job for the step.
  if (!job) {
    // If the step is a parallel step, we can't determine the last job.
    if (isParallelCommandStep(step)) {
      return null;
    }

    const jobs = build.jobs.filter((job) => job.stepUuid === stepId);
    job = jobs[jobs.length - 1];
  }

  return (
    <Drawer onClose={handleClose}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <CommandJobDetails job={job as CommandJob} />
      </ErrorBoundary>
    </Drawer>
  );
};

const CommandJobDetails = ({ job }: { job: CommandJob }) => {
  const { build, store } = useBuild();
  if (!build || !store) {
    return null;
  }

  useEffect(() => {
    BuildPageStore.expandJob(job);
  }, [job?.id]);

  return (
    <div className="job-list-pipeline">
      <Job
        key={job.id}
        job={job}
        build={build}
        buildStore={store}
        autoFollow={true}
      />
    </div>
  );
};
