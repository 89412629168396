import { useState, useEffect } from "react";
import capitalize from "lodash/capitalize";
import styled from "styled-components";

import AnchoredPopover from "app/components/shared/Popover/anchored";
import FriendlyTime from "app/components/shared/FriendlyTime";
import { getDateString } from "app/lib/date";
import Emojify from "app/components/shared/Emojify";
import ExecutionDrawerButton from "../../execution/Row/ExecutionDrawerButton";

export type ActivityProps = {
  id: string | number;
  name: string;
  duration: number;
  result: string;
  date: string;
  url: string;
  humanDuration: string;
  createdAt: string;
};

type Props = {
  activity: ActivityProps;
  maxDuration: number;
  disableTooltips?: boolean;
  executionTurboFrame?: boolean;
};

const RESULT_COLOUR = {
  passed: "green",
  failed: "red",
};

const StyledAnchoredPopover = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 1px;
  max-width: 12px;

  > a {
    height: 100%;
  }
`;

const StyledBar = ({
  barHeight,
  barColour,
}: {
  barHeight: string;
  barColour: string;
}) => (
  <span
    data-testid="styled-bar"
    className={`w-full transition-height duration-200 ease-in flex-shrink-0 rounded-t-[2px] inline-block ${barColour}`}
    style={{ height: barHeight }}
  />
);

const Bar = ({
  activity,
  maxDuration,
  disableTooltips,
  executionTurboFrame,
}: Props) => {
  const [barHeight, setBarHeight] = useState("1%");

  const colour = RESULT_COLOUR[activity.result];
  const barColour = colour
    ? `ta-bg-${colour} hover-ta-bg-${colour}`
    : "bg-gray";

  const linkLabel = `Go to ${activity.name}. ${capitalize(
    activity.result,
  )} on ${getDateString(activity.createdAt)} — ${activity.humanDuration}`;

  useEffect(() => {
    const height = (activity.duration / maxDuration) * 100;

    const timer = setTimeout(() => {
      setBarHeight(`${Math.min(Math.max(height, 10), 100)}%`);
    }, 0);

    return () => clearTimeout(timer);
  }, [activity.duration, maxDuration]);

  return (
    <StyledAnchoredPopover as={disableTooltips ? "div" : AnchoredPopover}>
      {executionTurboFrame ? (
        <ExecutionDrawerButton
          label={linkLabel}
          url={activity.url}
          classes="flex items-end no-title-tooltip w-full h-full"
        >
          <StyledBar barColour={barColour} barHeight={barHeight} />
        </ExecutionDrawerButton>
      ) : (
        <a
          aria-label={linkLabel}
          href={activity.url}
          className="flex items-end no-title-tooltip"
        >
          <StyledBar barColour={barColour} barHeight={barHeight} />
        </a>
      )}

      {!disableTooltips && (
        <div className="px2 py1">
          <span className="block semi-bold wrap-break-word">
            <Emojify text={activity.name} />
          </span>

          <span className="block small dark-gray">
            {capitalize(activity.result)}{" "}
            <FriendlyTime value={activity.createdAt} clickable={false} /> -{" "}
            {activity.humanDuration}
          </span>
        </div>
      )}
    </StyledAnchoredPopover>
  );
};

export default Bar;
