import { Step } from "app/lib/pipeline";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { StepLink } from "./StepLink";
import { StepLabel } from "./StepLabel";

/**
 * A catch-all step list item.
 */
export function StepListItem({
  step,
  onClick,
  className,
}: {
  step: Step;
  onClick?: () => void;
  className?: string;
}) {
  const { stepId } = useParams();

  return (
    <StepLink
      step={step}
      onClick={onClick}
      className={twMerge(
        "rounded-md hover:bg-gray-200 pl-2",
        classNames({ "bg-gray-200": step.uuid === stepId }),
        className,
      )}
    >
      <StepLabel step={step} />
    </StepLink>
  );
}
