/**
 * @generated SignedSource<<76827b84acb8453ba6aebd4067c951c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AuditSubjectType = "AGENT_TOKEN" | "API_ACCESS_TOKEN" | "AUTHORIZATION" | "CLUSTER" | "CLUSTER_PERMISSION" | "CLUSTER_QUEUE" | "CLUSTER_QUEUE_TOKEN" | "CLUSTER_TOKEN" | "JOB" | "NOTIFICATION_SERVICE" | "ORGANIZATION" | "ORGANIZATION_BANNER" | "ORGANIZATION_IMPERSONATION_REQUEST" | "ORGANIZATION_INVITATION" | "ORGANIZATION_MEMBER" | "PIPELINE" | "PIPELINE_SCHEDULE" | "PIPELINE_TEMPLATE" | "REGISTRY" | "REGISTRY_TOKEN" | "RULE" | "SCM_PIPELINE_SETTINGS" | "SCM_REPOSITORY_HOST" | "SCM_SERVICE" | "SECRET" | "SSO_PROVIDER" | "SUBSCRIPTION" | "SUITE" | "SUITE_MONITOR" | "TEAM" | "TEAM_MEMBER" | "TEAM_PIPELINE" | "TEAM_REGISTRY" | "TEAM_SUITE" | "USER" | "USER_EMAIL" | "USER_TOTP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type subjectSection_auditEvent$data = {
  readonly subject: {
    readonly name: string | null;
    readonly node: {
      readonly name?: string;
    } | null;
    readonly type: AuditSubjectType | null;
    readonly uuid: string;
  } | null;
  readonly " $fragmentType": "subjectSection_auditEvent";
};
export type subjectSection_auditEvent$key = {
  readonly " $data"?: subjectSection_auditEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"subjectSection_auditEvent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "subjectSection_auditEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditSubject",
      "kind": "LinkedField",
      "name": "subject",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "Organization",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "Pipeline",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "Registry",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "Team",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuditEvent",
  "abstractKey": null
};
})();

(node as any).hash = "c6df35ca11d5c2b1b75b15304f04b3b3";

export default node;
