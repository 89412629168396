import EventEmitter from "eventemitter3";

import LegacyDispatcher from "app/lib/legacyDispatcher";

import JobLogGroupStore from "app/stores/JobLogGroupStore";
import JobLogLineStore from "app/stores/JobLogLineStore";

const normalizeUrl = function (url) {
  const withoutAnchor = url.split("#")[0];

  // Normalize the /
  if (withoutAnchor[0] === "/") {
    return withoutAnchor;
  }

  return `/${withoutAnchor}`;
};

const navigateToUrl = function (url) {
  const currentUrl = normalizeUrl(window.location.pathname);
  const newUrl = normalizeUrl(url);

  // If the URL's are the same, just update the anchor, otherwise, navigate to
  // the new page.
  if (currentUrl === newUrl) {
    const newAnchor = url.split("#")[1];
    if (newAnchor != null) {
      return (window.location.hash = newAnchor);
    }
  } else {
    return (window.location = url);
  }
};

const updateAnchorWithoutNavigation = (hash: any) => {
  const url = new URL(window.location.href);
  url.hash = hash;
  history.replaceState(null, "", url.toString());
};

// If we make it blank, the page will jump to the top.
const clearAnchor = () => (window.location.hash = "_");

// This is to hack around a problem with the browser where whem you load the page.
// - Page loads
// - I jump to the element in JS
// - Browser sees anchor, and doesn't find it, so jumps to the top
// This is a ugly hack to make it do the scroll after the initial anchor jump.
const scrollToElement = (el: any, offset: undefined | number) => {
  setTimeout(() => {
    el.scrollIntoView(true);
    if (offset) {
      window.scrollBy(0, offset + el.getBoundingClientRect().top);
    }
  }, 250);
};

/**
 * Parse URL hash for job log attributes.
 */
export const parseHash = (hash: string) => {
  const match = hash.match(/([^/#]+)\/?(\d+)?-?(\d+)?/);
  if (!match) {
    return null;
  }

  const [job, group, line] = match.slice(1);
  return {
    job,
    group,
    line,
  };
};

type Job = {
  id: string;
};

class BuildPageStore {
  eventEmitter: EventEmitter;
  state: {
    expands: {
      [key: string]: boolean;
    };
    line:
      | {
          [key: string]: {
            job: string;
            line: string;
          };
        }
      | null
      | undefined;
  };

  needsScrollingToLine: boolean;
  scrollToJobAfterRender: string | null | undefined;

  expandJob(job: Job) {
    this.state.expands[job.id] = true;

    const element = document.getElementById(`job-${job.id}`);
    if (!element) {
      return;
    }
    const rect = element.getBoundingClientRect();
    if (rect.top < 0 || rect.top >= window.innerHeight) {
      element.scrollIntoView();
    }

    return this.eventEmitter.emit("change");
  }

  constructor() {
    this.eventEmitter = new EventEmitter();
    this.state = {
      expands: {},
      line: {},
    };

    this.needsScrollingToLine = false;
    this.scrollToJobAfterRender = null;

    window.addEventListener("DOMContentLoaded", () => {
      this.scrollToJobAfterRender = null;
      this.needsScrollingToLine = false;

      const anchor = parseHash(window.location.hash);
      if (anchor) {
        const { job, line, group } = anchor;

        this.state.expands[job] = true;
        this.scrollToJobAfterRender = job;

        // Expand the group the line is in, also highlight the group
        if (group) {
          const groupId = `${job}/${group}`;

          JobLogGroupStore.setDefault(groupId, true);
          JobLogLineStore.highlight(groupId);

          // Highlight the line
          if (line) {
            const lineId = `${groupId}-${line}`;
            JobLogLineStore.highlight(lineId);
          }

          // We need to scroll to the line after it's been rendered
          this.needsScrollingToLine = true;
        }

        return this.eventEmitter.emit("change");
      }
    });

    LegacyDispatcher.on("job:toggle", ({ job }) => {
      const isExpanded = this.state.expands[job.id];

      if (isExpanded) {
        this.state.expands[job.id] = false;
        clearAnchor();
      } else {
        this.state.expands[job.id] = true;
      }

      return this.eventEmitter.emit("change");
    });

    LegacyDispatcher.on("job:focus", ({ job }) => {
      this.state.expands[job.id] = true;
      navigateToUrl(job.path);
      this.scrollToJobAfterRender = job.id;
      return this.eventEmitter.emit("change");
    });

    // Watch for when a job is rendered so we can jump to it if we need to
    // (unless we're still waiting for a highlight)
    LegacyDispatcher.on("job:expanded", ({ job, el }) => {
      if (
        this.scrollToJobAfterRender === job.id &&
        !this.needsScrollingToLine
      ) {
        // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
        scrollToElement(el);
        return (this.scrollToJobAfterRender = null);
      }
    });

    // Show the new job when they click on the "Retried In" link
    LegacyDispatcher.on("job:retried-link-click", ({ job }) => {
      this.state.expands[job.id] = false;
      clearAnchor();
      this.state.expands[job.retriedInJobUuid] = true;
      navigateToUrl(window.location.pathname + `#${job.retriedInJobUuid}`);
      return this.eventEmitter.emit("change");
    });

    // Modify the current location anchor when a line is highlighted
    LegacyDispatcher.on("job_log_line:highlight", ({ lineId }) => {
      const [job, line] = lineId.split("/");

      // If we're turning off the current line
      if (
        this.state.line != null &&
        this.state.line.job === job &&
        this.state.line.line === line
      ) {
        this.state.line = null;
        return updateAnchorWithoutNavigation(job);
      }

      this.state.line = { job, line };
      return updateAnchorWithoutNavigation(lineId);
    });

    // Watch for when a line is rendered highlighted so we can scroll to it if we should
    LegacyDispatcher.on("job_log_line:highlighted", ({ el, lineId }) => {
      if (lineId) {
        const [job, line] = lineId.split("/");
        this.state.line = { job, line };
      }

      if (this.needsScrollingToLine) {
        scrollToElement(el, -150);
        this.scrollToJobAfterRender = null;
        return (this.needsScrollingToLine = false);
      }
    });

    // Watch for when a group is rendered highlighted so we can scroll to it if we should
    // Pretty much the same as lines
    LegacyDispatcher.on("job_log_group:highlighted", ({ el }) => {
      if (this.needsScrollingToLine) {
        scrollToElement(el, -150);
        this.scrollToJobAfterRender = null;
        return (this.needsScrollingToLine = false);
      }
    });
  }

  addChangeListener(job: Job | null | undefined, callback: any) {
    return this.eventEmitter.addListener("change", callback);
  }

  removeChangeListener(job: Job | null | undefined, callback: any) {
    return this.eventEmitter.removeListener("change", callback);
  }

  isJobExpanded(job: Job) {
    return this.state.expands[job.id] === true;
  }
}

export default new BuildPageStore();
