import ExecutionResult from "app/components/analytics/shared/ResultIcons/ExecutionResult";
import FailureReason from "app/components/analytics/shared/FailureReason";
import Emojify from "app/components/shared/Emojify";
import { Execution, Run } from "app/components/analytics/shared/type";
import MetadataRow from "app/components/analytics/shared/Metadata/MetadataRow";
import { FailureRow } from "../RunTable/Row";
import ExecutionDrawerButton from "./ExecutionDrawerButton";

export type ExecutionRow = Omit<Execution, "duration" | "id"> & {
  run: Omit<
    Run,
    "createdAt" | "humanDuration" | "duration" | "id" | "result" | "tags"
  >;
};

type Props = {
  execution: ExecutionRow;
};

const Row = ({ execution }: Props) => {
  const failed =
    execution.result === "failed" && execution.failureReason !== null;
  const { name, buildNumber, buildUrl, branch, commitSha } = execution.run;

  return (
    <li className="row-link__container">
      <div className="ta-panel-row grid-cols-[3rem_auto_6rem]">
        <div data-testid="result-icon">
          <ExecutionResult.Regular result={execution.result.toUpperCase()} />
        </div>
        <div className="main-section">
          {window.Features.AnalyticsExecutionDrawer ? (
            <ExecutionDrawerButton
              url={execution.url}
              classes="block text-decoration-none color-inherit row-link"
            >
              <span className="semi-bold block">
                <Emojify text={name} />
              </span>
            </ExecutionDrawerButton>
          ) : (
            <a
              href={execution.url}
              className="block text-decoration-none color-inherit row-link"
            >
              <span className="semi-bold block">
                <Emojify text={name} />
              </span>
            </a>
          )}

          <MetadataRow
            buildNumber={buildNumber}
            buildUrl={buildUrl}
            branch={branch}
            commitSha={commitSha}
            createdAt={execution.createdAt}
          />
        </div>
        <div className="right-align">
          {execution.humanDuration}{" "}
          <span className="hide"> execution duration</span>
        </div>
        {failed && (
          <FailureRow>
            <FailureReason
              failureReason={execution.failureReason}
              endpoint={execution.url}
            />
          </FailureRow>
        )}
      </div>
    </li>
  );
};

export default Row;
