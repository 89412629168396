import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { parse } from "query-string";
import unique from "array-unique";

import Button from "app/components/shared/Button";
import CollapsableArea from "app/components/shared/CollapsableArea";
import Dialog from "app/components/shared/Dialog";
import FormTextField from "app/components/shared/FormTextField";
import FormTextarea from "app/components/shared/FormTextarea";
import FormDataList from "app/components/shared/FormDataList";
import PipelineTemplateFormSelect from "app/components/shared/PipelineTemplateFormSelect";

type PipelineTemplate = {
  uuid?: string;
  name: string;
  description?: string | null;
};

type Props = {
  pipeline: any;
  build: any | null | undefined;
  isOpen: boolean | null | undefined;
  onRequestClose: any;
  pipelineTemplates: Array<PipelineTemplate>;
};

type State = {
  optionsInitiallyOpen: boolean;
  creatingBuild: boolean;
  defaultValues: {
    // putting these here so it's obvious they exist(!)
    message: string | null | undefined;
    commit: string | null | undefined;
    branch: string | null | undefined;
    env: string | null | undefined;
    clean_checkout: boolean | null | undefined;
  };
};

class CreateBuildDialog extends React.PureComponent<Props, State> {
  constructor(props: any) {
    super(props);

    const defaultValues = this.determineDefaultValues();
    this.state = {
      optionsInitiallyOpen: Boolean(
        defaultValues.env || defaultValues.clean_checkout,
      ),
      creatingBuild: false,
      defaultValues,
    };
  }

  form: HTMLFormElement | null | undefined;
  buildMessageTextField: FormTextField;
  buildCommitTextField: FormTextField;
  buildBranchTextField: FormTextField;

  determineDefaultValues() {
    let defaultValues: State["defaultValues"] = {
      message: this.props.build?.message,
      // The current build's commit isn't usually what you want when creating a new build.
      // Rather, if you want to create a new build from the current build's commit, you can
      // just click "Rebuild". Instead, we'll default to the current URL query params commit value.
      commit: undefined,
      branch: this.props.build?.branch,
      env: undefined,
      clean_checkout: undefined,
    };

    // In the case that the URL looks like this:
    // https://buildkite.com/<org-slug>/<pipeline-slug>/builds#new?message=Testing&branch=123&commit=HEAD
    const [hashPath, hashQuery] = window.location.hash.split("?");
    if (hashPath === "#new") {
      defaultValues = {
        ...defaultValues,
        ...parse(hashQuery),
      };
    }

    // In the case that the URL looks like this:
    // https://buildkite.com/<org-slug>/<pipeline-slug>/builds?message=Testing&branch=123&commit=HEAD#new
    if (window.location.search) {
      defaultValues = {
        ...defaultValues,
        ...parse(window.location.search),
      };
    }

    return defaultValues;
  }

  componentDidMount() {
    // Focus the build message input box if the dialog started life opened.
    if (this.props.isOpen && this.buildMessageTextField) {
      this.buildMessageTextField.focus();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      // Focus the build message input box if the dialog was just opened. This
      // is a total hack since when this component transitions from `isOpen
      // (false => true)` the buildMessageTextField doesn't actually exist yet.
      //
      // So we'll do this hacky thing here, let this component render, then
      // focus the text field if we've got it.
      setTimeout(() => {
        if (this.buildMessageTextField) {
          this.buildMessageTextField.focus();
        }
      }, 0);
    }
  }

  render() {
    const branchSuggestions = [
      this.state.defaultValues.branch,
      this.props.pipeline.defaultBranch,
    ].filter(Boolean);
    const commitSuggestions = [this.state.defaultValues.commit, "HEAD"].filter(
      Boolean,
    );
    const messageSuggestions = [this.state.defaultValues.message];

    const noTemplate: PipelineTemplate = { name: "No template" };
    let pipelineTemplates = this.props.pipelineTemplates;
    let selectedTemplate = pipelineTemplates.find(
      (template) =>
        template.uuid === this.props.pipeline.pipelineTemplate?.uuid,
    );

    if (!this.props.pipeline.pipelineTemplate) {
      pipelineTemplates = [noTemplate, ...pipelineTemplates];
      selectedTemplate = selectedTemplate || noTemplate;
    }

    return (
      <Dialog
        // @ts-expect-error - TS2322 - Type 'boolean | null | undefined' is not assignable to type 'boolean | undefined'.
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        width={540}
      >
        <form
          action={`/organizations/${this.props.pipeline.organization.slug}/pipelines/${this.props.pipeline.slug}/builds`}
          acceptCharset=""
          method="POST"
          onKeyDown={this.handleFormKeyDown}
          ref={(form) => (this.form = form)}
        >
          <input type="hidden" name="utf8" value="✓" />
          <input
            type="hidden"
            name={window._csrf.param}
            value={window._csrf.token}
          />

          <div className="p4">
            <h1 className="m0 h2 semi-bold mb3">New Build</h1>

            <FormTextField
              name="build[message]"
              label="Message"
              help="Description of the build. If left blank, the commit message will be used once the build starts."
              defaultValue={messageSuggestions[0]}
              ref={(tf) => (this.buildMessageTextField = tf)}
            />

            <FormDataList
              id="new-build-commit-suggestions"
              values={unique(commitSuggestions)}
            />

            <FormTextField
              name="build[commit]"
              label="Commit"
              list="new-build-commit-suggestions"
              required={true}
              defaultValue={commitSuggestions[0]}
              ref={(tf) => (this.buildCommitTextField = tf)}
            />

            <FormDataList
              id="new-build-branch-suggestions"
              values={unique(branchSuggestions)}
            />

            <FormTextField
              name="build[branch]"
              label="Branch"
              list="new-build-branch-suggestions"
              required={true}
              defaultValue={branchSuggestions[0]}
              ref={(tf) => (this.buildBranchTextField = tf)}
            />

            {this.props.pipelineTemplates.length > 0 && (
              <div className="mb2">
                <label htmlFor="pipeline-template-select-toggle-button">
                  <p className="text-semi-bold mb1">
                    Pipeline Template{" "}
                    <span className="dark-gray h6 semi-bold"> — Required</span>
                  </p>
                </label>
                <PipelineTemplateFormSelect
                  id="pipeline-template-select"
                  name="build[pipeline_template_uuid]"
                  // @ts-expect-error - TS2322 - Type 'PipelineTemplate | undefined' is not assignable to type 'PipelineTemplate | null'.
                  selected={selectedTemplate}
                  // @ts-expect-error - TS2322 - Type 'PipelineTemplate[]' is not assignable to type 'import("/Users/chris/bk/buildkite/frontend/app/components/shared/PipelineTemplateFormSelect/PipelineTemplateFormSelect").PipelineTemplate[]'.
                  items={pipelineTemplates}
                />
              </div>
            )}

            <CollapsableArea
              label="Options"
              initialOpen={this.state.optionsInitiallyOpen}
            >
              <FormTextarea
                name="build[env]"
                label="Environment Variables"
                help={
                  <span>
                    Place each environment variable on a new line, in the format{" "}
                    <code className="code">KEY=value</code>
                  </span>
                }
                rows={3}
                defaultValue={this.state.defaultValues.env}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  resize: "vertical",
                }}
              />
              <div className="relative">
                <input type="hidden" name="build[clean_checkout]" value="0" />
                <label className="bold">
                  <input
                    className="absolute"
                    name="build[clean_checkout]"
                    type="checkbox"
                    value="1"
                    defaultChecked={
                      // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'boolean | null | undefined' and 'string' have no overlap.
                      this.state.defaultValues.clean_checkout === "true"
                    }
                  />{" "}
                  <span className="ml4">Clean checkout</span>
                </label>
                <div className="mb0 p0 dark-gray ml4">
                  Force the agent to remove any existing build directory and
                  perform a fresh checkout
                </div>
              </div>
            </CollapsableArea>
          </div>

          <div className="px4 pb4">
            <Button
              className="col-12"
              onClick={this.handleCreateBuildButtonClick}
              theme="primary"
              // @ts-expect-error - TS2322 - Type 'false | "Creating Build..."' is not assignable to type 'boolean | undefined'.
              loading={this.state.creatingBuild ? "Creating Build..." : false}
            >
              Create Build
            </Button>
          </div>
        </form>
      </Dialog>
    );
  }

  handleFormKeyDown = (event: any) => {
    // Intercept ⌘Enter or ⌃Enter
    if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();

      this.submitForm();
    }
  };

  handleCreateBuildButtonClick = (event: any) => {
    event.preventDefault();

    this.submitForm();
  };

  submitForm() {
    if (this.isValid()) {
      this.setState({ creatingBuild: true });
      this.form && this.form.submit();
    }
  }

  isValid() {
    // Ideally these required fields should prevent themselves from being
    // submitted… but somehow they don't?
    if (!this.buildCommitTextField.value) {
      this.buildCommitTextField.focus();
      return false;
    }
    if (!this.buildBranchTextField.value) {
      this.buildBranchTextField.focus();
      return false;
    }

    return true;
  }
}

export default createFragmentContainer(CreateBuildDialog, {
  build: graphql`
    fragment CreateBuildDialog_build on Build {
      commit
      branch
    }
  `,
  pipeline: graphql`
    fragment CreateBuildDialog_pipeline on Pipeline {
      slug
      defaultBranch
      organization {
        slug
      }
      pipelineTemplate {
        uuid
      }
    }
  `,
});
