import EmptyState from "app/components/shared/EmptyState";
import SupportBanner from "./SupportBanner";
import ContentCypress from "./frameworks/ContentCypress";

type Props = {
  framework?: string;
  setFramework: (framework: string) => void;
};

type ContentJunitProps = {
  onClickJsonLink: () => void;
};

const ContentRspec = () => (
  <>
    <p>
      Before you start, make sure RSpec runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>

    <p>Create a new branch:</p>

    <pre className="code">git checkout -b install-buildkite-test-analytics</pre>

    <p>
      Add{" "}
      <a
        className="purple"
        href="https://rubygems.org/gems/buildkite-test_collector"
      >
        buildkite-test_collector
      </a>{" "}
      to your Gemfile in the <code className="inline-block">test</code> group:
    </p>

    <pre className="code">
      {["group :test do", '  gem "buildkite-test_collector"', "end"].join("\n")}
    </pre>

    <p>
      Run <code className="inline-block">bundler</code> to install the gem and
      update your <code className="inline-block">Gemfile.lock</code>:
    </p>

    <pre className="code">bundle</pre>

    <p>
      Add the Test Analytics code to your application in{" "}
      <code className="inline-block">spec/spec_helper.rb</code>
    </p>

    <pre className="code">
      {[
        'require "buildkite/test_collector"',
        "",
        "Buildkite::TestCollector.configure(hook: :rspec)",
      ].join("\n")}
    </pre>

    <p>
      Add the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      <a className="purple" href="/docs/pipelines/secrets">
        securely
      </a>{" "}
      to your CI environment, push your changes to a branch, and open a pull
      request 🎉
    </p>

    <pre className="code">
      {[
        "git add .",
        'git commit -m "Install and set up Buildkite Test Analytics"',
        "git push",
      ].join("\n")}
    </pre>

    <p>
      {
        "Once you're done, in your Test Analytics dashboard, you'll see analytics of test executions on "
      }
      <strong>all branches that include this code</strong>.
    </p>

    <SupportBanner />
  </>
);

const ContentMinitest = () => (
  <>
    <p>
      Before you start, make sure minitest runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>

    <p>Create a new branch:</p>

    <pre className="code">git checkout -b install-buildkite-test-analytics</pre>

    <p>
      Add{" "}
      <a
        className="purple"
        href="https://rubygems.org/gems/buildkite-test_collector"
      >
        buildkite-test_collector
      </a>{" "}
      to your Gemfile in the <code className="inline-block">test</code> group:
    </p>

    <pre className="code">
      {["group :test do", '  gem "buildkite-test_collector"', "end"].join("\n")}
    </pre>

    <p>
      Run <code className="inline-block">bundler</code> to install the gem and
      update your <code className="inline-block">Gemfile.lock</code>:
    </p>

    <pre className="code">bundle</pre>

    <p>
      Add the Test Analytics code to your application in{" "}
      <code className="inline-block">test/test_helper.rb</code>
    </p>

    <pre className="code">
      {[
        'require "buildkite/test_collector"',
        "",
        "Buildkite::TestCollector.configure(hook: :minitest)",
      ].join("\n")}
    </pre>

    <p>
      Add the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      <a className="purple" href="/docs/pipelines/secrets">
        securely
      </a>{" "}
      to your CI environment, push your changes to a branch, and open a pull
      request 🎉
    </p>

    <pre className="code">
      {[
        "git add .",
        'git commit -m "Install and set up Buildkite Test Analytics"',
        "git push",
      ].join("\n")}
    </pre>

    <p>
      {
        "Once you're done, in your Test Analytics dashboard, you'll see analytics of test executions on "
      }
      <strong>all branches that include this code</strong>.
    </p>

    <SupportBanner />
  </>
);

const ContentJest = () => (
  <>
    <p>
      Before you start, make sure Jest runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>

    <p>Create a new branch:</p>

    <pre className="code">git checkout -b install-buildkite-test-analytics</pre>

    <p>
      Add the{" "}
      <a
        className="purple"
        href="https://www.npmjs.com/package/buildkite-test-collector"
      >
        buildkite-test-collector package
      </a>{" "}
      to your dev dependencies:
    </p>

    <pre className="code">
      {[
        "# If you use npm:",
        "npm install --save-dev buildkite-test-collector",
        "",
        "# If you use Yarn:",
        "yarn add --dev buildkite-test-collector",
      ].join("\n")}
    </pre>

    <p>
      Update your Jest config file (e.g.{" "}
      <code className="inline-block">jest.config.js</code>) to include:
    </p>

    <pre className="code">
      {[
        "// Send results to Test Analytics",
        "reporters: [",
        "  'default',",
        "  'buildkite-test-collector/jest/reporter'",
        "],",
        "",
        "// Enable column + line capture for Test Analytics",
        "testLocationInResults: true",
      ].join("\n")}
    </pre>

    <p>
      Add the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      secret to your CI environment, push your changes to a branch, and open a
      pull request 🎉
    </p>

    <pre className="code">
      {[
        'git commit -am "Install and set up Buildkite Test Analytics"',
        "git push origin install-buildkite-test-analytics",
      ].join("\n")}
    </pre>

    <p>
      {
        "Once you're done, in your Test Analytics dashboard, you'll see analytics of test executions on "
      }
      <strong>all branches that include this code</strong>.
    </p>

    <SupportBanner />
  </>
);

const ContentPytest = () => (
  <>
    <p>
      Before you start, make sure pytest runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>

    <p>Create a new branch:</p>

    <pre className="code">git checkout -b install-buildkite-test-analytics</pre>

    <p>
      Add{" "}
      <a
        className="purple"
        href="https://pypi.org/project/buildkite-test-collector"
      >
        buildkite-test_collector
      </a>{" "}
      to your list of dependencies:
    </p>

    <ul
      className="mt0"
      style={{ listStyle: "disc", paddingInlineStart: "40px" }}
    >
      <li>
        {"If you're using a "}
        <code className="inline-block">requirements.txt</code> file, add{" "}
        <code className="inline-block">buildkite-test-collector</code> on a new
        line.
      </li>
      <li>
        {"If you're using a "}
        <code className="inline-block">setup.py</code> file, add{" "}
        <code className="inline-block">buildkite-test-collector</code> to the{" "}
        <code className="inline-block">extras_require</code> argument, like
        this:
        <pre className="code">
          {'extras_require={"dev": ["pytest", "  buildkite-test-collector"]}'}
        </pre>
      </li>
      <li>
        {"If you're using Pipenv, run "}
        <code className="inline-block">
          pipenv install --dev buildkite-test-collector
        </code>
        .
      </li>
      <li>
        If you&apos;re using another tool, see your dependency management
        system&apos;s documentation for help.
      </li>
    </ul>

    <p>
      Add the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      <a className="purple" href="/docs/pipelines/secrets">
        securely
      </a>{" "}
      to your CI environment, push your changes to a branch, and open a pull
      request 🎉
    </p>

    <pre className="code">
      {[
        "git add .",
        'git commit -m "Install and set up Buildkite Test Analytics"',
        "git push",
      ].join("\n")}
    </pre>

    <p>
      {
        "Once you're done, in your Test Analytics dashboard, you'll see analytics of test executions on "
      }
      <strong>all branches that include this code</strong>.
    </p>

    <SupportBanner />
  </>
);

const ContentSwift = () => (
  <>
    <p>
      Test Analytics ships with Swift collectors listed on this page, and{" "}
      <a
        className="purple"
        href="https://github.com/buildkite/test-collector-swift/labels/test%20framework"
      >
        more coming soon
      </a>
      .
    </p>

    <h3 className="mt4 pt2 line-height-2">XCTest</h3>

    <p>
      Before you start, make sure XCTest runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>

    <p>
      To use the Buildkite Test Collector with a Swift Package Manager project,
      add this repository to the{" "}
      <code className="inline-block">Package.swift</code> manifest and add{" "}
      <code className="inline-block">BuildkiteTestCollector</code> to any test
      target requiring analytics:
    </p>

    <pre className="code">
      {[
        "let package = Package(",
        '  name: "MyProject",',
        "    dependencies: [",
        '    .package(url: "https://github.com/buildkite/test-collector-swift")',
        "  ],",
        "  targets: [",
        '    .target(name: "MyProject"),',
        "    .testTarget(",
        '      name: "MyProjectTests",',
        '      dependencies: ["MyProject", "BuildkiteTestCollector"]',
        "    )",
        "  ]",
        ")",
      ].join("\n")}
    </pre>

    <p>
      Set the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      secret on your CI to your suite API token.
    </p>

    <p>
      Push your changes to a branch, and open a pull request. After a test run
      has been triggered, results will start appearing in your analytics
      dashboard.
    </p>

    <pre className="code">
      {[
        "git checkout -b add-buildkite-test-analytics",
        'git commit -am "Add Buildkite Test Analytics"',
        "git push origin add-buildkite-test-analytics",
      ].join("\n")}
    </pre>

    <p>
      Once you&apos;re done, we&apos;ll start analyzing test executions on{" "}
      <strong>all branches that include this code</strong>.
    </p>

    <SupportBanner />
  </>
);

const ContentRust = () => (
  <>
    <p>
      Before you start, make sure Rust runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>

    <h3 className="mt4 pt2 line-height-2">
      Install the <code className="inline-block">buildkite-test-collector</code>{" "}
      crate
    </h3>

    <pre className="code">
      {[
        "$ cargo install buildkite-test-collector",
        "# or",
        "$ cargo install --git https://github.com/buildkite/test-collector-rust buildkite-test-collector",
      ].join("\n")}
    </pre>

    <h3 className="mt4 pt2 line-height-2">Configure your environment</h3>

    <p>
      Set the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      secret on your CI to your suite API token.
    </p>

    <p>
      {
        'We try and detect several common CI environments based in the environment variables which are present. If this detection fails then the application will crash with an error. To force the use of a "generic CI environment" set the '
      }
      <code className="inline-block">CI</code> environment variable to any
      non-empty value.
    </p>

    <h3 className="mt4 pt2 line-height-2">
      Change your test output to JSON format
    </h3>

    <p>
      {" "}
      In your CI environment you will need to change your output format to{" "}
      <code className="inline-block">JSON</code> and add{" "}
      <code className="inline-block">--report-time</code> to include execution
      times in the output. Unfortunately, these are currently unstable options
      for Rust, so some extra command line options are needed. Once you have the
      JSON output you can pipe it through the{" "}
      <code className="inline-block">buildkite-test-collector</code> binary -
      the input JSON is echoed back to STDOUT so that you can still operate upon
      it if needed.
    </p>

    <pre className="code">
      $ cargo test -- -Z unstable-options --format json --report-time |
      buildkite-test-collector
    </pre>

    <h3 className="mt4 pt2 line-height-2">Confirm correct operation</h3>

    <p>Verify that the run is visible in the Buildkite analytics dashboard.</p>

    <p>
      Once you&apos;re done, we&apos;ll start analyzing test executions on{" "}
      <strong>all branches that include this code</strong>.
    </p>

    <SupportBanner />
  </>
);

const ContentElixir = () => (
  <>
    <p>
      Test Analytics ships with a <strong>ExUnit</strong> test collector.
    </p>

    <p>
      Before you start, make sure ExUnit runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>

    <h3 className="mt4 pt2 line-height-2">
      Add <code className="inline-block">buildkite_test_collector</code> to your
      list of dependencies in <code className="inline-block">mix.exs</code>
    </h3>

    <pre className="code">
      {[
        "def deps do",
        "  [",
        '    {:buildkite_test_collector, "~> 0.1.0", only: [:test]}',
        "  ]",
        "end",
      ].join("\n")}
    </pre>

    <h3 className="mt4 pt2 line-height-2">Set up your API token</h3>

    <p>
      In your <code className="inline-block">config/test.exs</code> (or other
      environment configuration as appropriate) add your suite API token.
    </p>

    <pre className="code">
      {[
        "import Config",
        "",
        "config :buildkite_test_collector,",
        '  api_key: System.get_env("BUILDKITE_ANALYTICS_TOKEN")',
      ].join("\n")}
    </pre>

    <h3 className="mt4 pt2 line-height-2">
      Add <code className="inline-block">BuildkiteTestCollectorFormatter</code>{" "}
      to your ExUnit configuration in{" "}
      <code className="inline-block">test/test_helper.exs</code>
    </h3>

    <pre className="code">
      {[
        "ExUnit.configure formatters: [BuildkiteTestCollector.Formatter, ExUnit.CLIFormatter]",
        "ExUnit.start",
      ].join("\n")}
    </pre>

    <h3 className="mt4 pt2 line-height-2">Run your tests like normal</h3>

    <p>
      Note that we attempt to detect the presence of several common CI
      environments. However if this fails you can set the{" "}
      <code className="inline-block">CI</code> environment variable to any value
      and it will work.
    </p>

    <pre className="code">
      {[
        "$ mix test",
        "",
        "...",
        "",
        "Finished in 0.01 seconds (0.003s on load, 0.004s on tests)",
        "3 tests, 0 failures",
        "",
        "Randomized with seed 12345",
      ].join("\n")}
    </pre>

    <h3 className="mt4 pt2 line-height-2">Confirm correct operation</h3>

    <p>Verify that the run is visible in the Buildkite analytics dashboard.</p>

    <p>
      Once you&apos;re done, we&apos;ll start analyzing test executions on{" "}
      <strong>all branches that include this code</strong>.
    </p>

    <SupportBanner />
  </>
);

const ContentJunit = ({ onClickJsonLink }: ContentJunitProps) => (
  <>
    <p>
      To import{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/importing-junit-xml"
      >
        JUnit XML files
      </a>
      , a common format supported by most test runners, you can use the{" "}
      <a href="Test Collector plugin" className="purple">
        Test Collector plugin
      </a>{" "}
      or the Test Analytics Uploads API
    </p>

    <h3 className="mt4 pt2 line-height-2">Using a plugin</h3>

    <p>
      Import XML-formatted JUnit test results to Test Analytics using the Test
      Collector plugin from a build step:
    </p>
    <pre className="code">
      {[
        "steps",
        '  - label: "🔨 Test"',
        '  command: "make test"',
        "  plugins:",
        "    - test-collector#v1.0.0:",
        '    files: "test/junit-*.xml"',
        '    format: "junit"',
      ].join("\n")}
    </pre>

    <p>
      See more configuration information in the{" "}
      <a
        href="https://github.com/buildkite-plugins/test-collector-buildkite-plugin"
        className="purple"
      >
        Test Collector plugin README.
      </a>
    </p>

    <p>
      Using the plugin is the recommended way as it allows for a better
      debugging process in case of an issue.
    </p>

    <h3 className="mt4 pt2 line-height-2">
      Using the Test Analytics Uploads API
    </h3>

    <p>
      The following <code className="code inline-block">curl</code> command
      uploads a <code className="code inline-block">junit.xml</code> file and
      sets meta-data using standard Buildkite build environment variables:
    </p>

    <pre className="code">
      {[
        "curl -X POST \\",
        '  -H "Authorization: Token token=$BUILDKITE_ANALYTICS_TOKEN" \\',
        '  -F "format=junit" \\',
        '  -F "data=@junit.xml" \\',
        '  -F "run_env[CI]=buildkite" \\',
        '  -F "run_env[key]=$BUILDKITE_BUILD_ID" \\',
        '  -F "run_env[number]=$BUILDKITE_BUILD_NUMBER" \\',
        '  -F "run_env[job_id]=$BUILDKITE_JOB_ID" \\',
        '  -F "run_env[branch]=$BUILDKITE_BRANCH" \\',
        '  -F "run_env[commit_sha]=$BUILDKITE_COMMIT" \\',
        '  -F "run_env[message]=$BUILDKITE_MESSAGE" \\',
        '  -F "run_env[url]=$BUILDKITE_BUILD_URL" \\',
        "  https://analytics-api.buildkite.com/v1/uploads",
      ].join("\n")}
    </pre>

    <p>
      Once you&apos;ve added this to your build scripts and securely set up your{" "}
      <code className="code inline-block">BUILDKITE_ANALYTICS_TOKEN</code>, your
      tests will start reporting data to Test Analytics.
    </p>

    <p>
      For details on uploading from other CI tools or environments, see{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/importing-junit-xml"
      >
        Importing JUnit XML
      </a>
      .
    </p>

    <section
      className="border border-gray rounded mb3 very-dark-gray"
      style={{ borderLeft: "3px solid orange" }}
    >
      <div className="m3">
        <p>
          JUnit XML does not provide detailed span information so features in
          Test Analytics that depend on span information aren&apos;t available
          when using JUnit XML. If you need span information, consider using the{" "}
          <a className="purple" href="#" onClick={onClickJsonLink}>
            JSON import API
          </a>{" "}
          instead.
        </p>
      </div>
    </section>

    <section
      className="border border-gray rounded mb3 very-dark-gray"
      style={{ borderLeft: "3px solid #14CC80" }}
    >
      <div className="m3">
        <p>
          If you&apos;re using Docker, don&apos;t forget to pass through the
          environment variables to the container.
        </p>
      </div>
    </section>
    <SupportBanner />
  </>
);

const ContentJson = () => (
  <>
    <p>
      To import{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/importing-json"
      >
        Test Analytics JSON
      </a>{" "}
      you can use the Test Analytics Uploads API.
    </p>

    <p>
      For example, the following <code className="code inline-block">curl</code>{" "}
      command uploads a{" "}
      <code className="code inline-block">test-results.json</code> file and sets
      meta-data using standard Buildkite build environment variables:
    </p>

    <pre className="code">
      {[
        "curl -X POST \\",
        '  -H "Authorization: Token token=$BUILDKITE_ANALYTICS_TOKEN" \\',
        '  -F "format=json" \\',
        '  -F "data=@test-results.json" \\',
        '  -F "run_env[CI]=buildkite" \\',
        '  -F "run_env[key]=$BUILDKITE_BUILD_ID" \\',
        '  -F "run_env[number]=$BUILDKITE_BUILD_NUMBER" \\',
        '  -F "run_env[job_id]=$BUILDKITE_JOB_ID" \\',
        '  -F "run_env[branch]=$BUILDKITE_BRANCH" \\',
        '  -F "run_env[commit_sha]=$BUILDKITE_COMMIT" \\',
        '  -F "run_env[message]=$BUILDKITE_MESSAGE" \\',
        '  -F "run_env[url]=$BUILDKITE_BUILD_URL" \\',
        "  https://analytics-api.buildkite.com/v1/uploads",
      ].join("\n")}
    </pre>

    <p>
      For the details on the JSON format, and how to upload from other CI tools
      or environments, see the{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/importing-json"
      >
        Test Analytics JSON documentation
      </a>
      .
    </p>

    <section
      className="border border-gray rounded mb3 very-dark-gray"
      style={{ borderLeft: "3px solid #14CC80" }}
    >
      <div className="m3">
        <p>
          If you&apos;re using Docker, don&apos;t forget to pass through the
          environment variables to the container.
        </p>
      </div>
    </section>
    <SupportBanner path="/importing-json" />
  </>
);

const ContentOther = () => (
  <>
    <p>
      You can integrate any language and framework by uploading{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/importing-json"
      >
        Test Analytics JSON
      </a>{" "}
      or{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/importing-junit-xml"
      >
        JUnit XML
      </a>{" "}
      after your tests run. You can also{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/your-own-collectors"
      >
        build your own collector
      </a>
      .
    </p>

    <p className="mb1">The following test collectors are coming soon:</p>
    <ul className="mt0 list-disc list-inside pl-5 mb-4">
      <li>Android</li>
      <li>.NET</li>
    </ul>

    <p className="mb1">Questions? Feedback?</p>
    <ul
      className="mt0"
      style={{ listStyle: "disc", paddingInlineStart: "40px" }}
    >
      <li>
        See our{" "}
        <a className="purple" href="https://buildkite.com/docs/test-analytics">
          documentation
        </a>
      </li>
      <li>
        Join our #test-analytics channel on the{" "}
        <a className="purple" href="https://chat.buildkite.community">
          community Slack
        </a>
      </li>
      <li>
        <a className="purple" href="mailto:support+analytics@buildkite.com">
          Email support
        </a>
      </li>
    </ul>
  </>
);

const ContentMocha = () => (
  <>
    <p>
      Before you start, make sure Mocha runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>
    <p>Create a new branch:</p>

    <pre className="code">git checkout -b install-buildkite-test-analytics</pre>

    <p>
      Add the{" "}
      <a
        className="purple"
        href="https://www.npmjs.com/package/buildkite-test-collector"
      >
        buildkite-test-collector package
      </a>{" "}
      and the{" "}
      <a
        className="purple"
        href="https://github.com/stanleyhlng/mocha-multi-reporters"
      >
        mocha-multi-reporters
      </a>{" "}
      to your dev dependencies:
    </p>

    <pre className="code">
      {[
        "# If you use npm:",
        "npm install --save-dev buildkite-test-collector mocha-multi-reporters",
        "",
        "# If you use Yarn:",
        "yarn add --dev buildkite-test-collector mocha-multi-reporters",
      ].join("\n")}
    </pre>

    <p>Configure it to run your desired reporter and the Buildkite reporter:</p>
    <pre className="code">
      {[
        "# config.json",
        "{",
        '"reporterEnabled": "spec, buildkite-test-collector/mocha/reporter"',
        "}",
      ].join("\n")}
    </pre>

    <p>
      Update your test script to use the Buildkite reporter via
      mocha-multi-reporters:
    </p>
    <pre className="code">
      {[
        "# package.json",
        '"scripts": {',
        '"test": "mocha --reporter mocha-multi-reporters --reporter-options configFile=config.json"',
        "}",
      ].join("\n")}
    </pre>

    <p>
      <em>(Optional)</em> To pass in the API token using a custom environment
      variable, use the report options. Since the reporter options are passed in
      as a JSON file, we recommend you put the environment variable name as a
      string value in the config.json, which is retrieved using{" "}
      <a className="purple" href="https://github.com/motdotla/dotenv">
        dotenv
      </a>{" "}
      in the mocha reporter.
    </p>
    <pre className="code">
      {[
        "# config.json",
        "{",
        '"reporterEnabled": "spec, buildkite-test-collector/mocha/reporter",',
        '"buildkiteTestCollectorMochaReporterReporterOptions": {',
        ' "token_name": "CUSTOM_ENV_VAR_NAME"',
        "}",
        "}",
      ].join("\n")}
    </pre>

    <p>
      Add the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      secret to your CI environment, push your changes to a branch, and open a
      pull request 🎉
    </p>
    <pre className="code">
      {[
        "git add .",
        'git commit -m "Install and set up Buildkite Test Analytics"',
        "git push",
      ].join("\n")}
    </pre>

    <p>
      After completing these steps, you&apos;ll see the analytics of test
      executions on all branches that include this code in the Test Analytics
      dashboard.
    </p>
    <SupportBanner />
  </>
);

const ContentJasmine = () => (
  <>
    <p>
      Before you start, make sure Jasmine runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>
    <p>
      Follow the{" "}
      <a
        className="purple"
        href="https://jasmine.github.io/setup/nodejs.html#reporters"
      >
        Jasmine docs
      </a>{" "}
      to add the Buildkite reporter. For example:
    </p>
    <pre className="code">
      {[
        "// SpecHelper.js",
        'var BuildkiteReporter = require("buildkite-test-collector/jasmine/reporter");',
        "var buildkiteReporter = new BuildkiteReporter();",
        "jasmine.getEnv().addReporter(buildkiteReporter);",
      ].join("\n")}
    </pre>
    <p>
      <em>(Optional)</em> To pass in the API token using a custom environment
      variable, use the following report options:
    </p>
    <pre className="code">
      {[
        "// SpecHelper.js",
        "var buildkiteReporter = new BuildkiteReporter(undefined, {",
        "token: process.env.CUSTOM_ENV_VAR,",
        "});",
      ].join("\n")}
    </pre>

    <p>
      Add the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      secret to your CI environment, push your changes to a branch, and open a
      pull request 🎉
    </p>
    <pre className="code">
      {[
        "git add .",
        'git commit -m "Install and set up Buildkite Test Analytics"',
        "git push",
      ].join("\n")}
    </pre>

    <p>
      After completing these steps, you&apos;ll see the analytics of test
      executions on all branches that include this code in the Test Analytics
      dashboard.
    </p>
    <SupportBanner />
  </>
);

const ContentPlaywright = () => (
  <>
    <p>
      Before you start, make sure Playwright runs with access to{" "}
      <a
        className="purple"
        href="https://buildkite.com/docs/test-analytics/ci-environments"
      >
        CI environment variables
      </a>
      .
    </p>

    <p>Create a new branch:</p>

    <pre className="code">git checkout -b install-buildkite-test-analytics</pre>

    <p>
      Add the{" "}
      <a
        className="purple"
        href="https://www.npmjs.com/package/buildkite-test-collector"
      >
        buildkite-test-collector package
      </a>{" "}
      to your dev dependencies:
    </p>

    <pre className="code">
      {[
        "# If you use npm:",
        "npm install --save-dev buildkite-test-collector",
        "",
        "# If you use Yarn:",
        "yarn add --dev buildkite-test-collector",
      ].join("\n")}
    </pre>

    <p>
      Update your Playwright{" "}
      <a
        href="https://playwright.dev/docs/test-reporters#multiple-reporters"
        target="_blank"
        rel="noreferrer"
      >
        config
      </a>{" "}
      file (e.g. <code className="inline-block">playwright.config.js</code>) to
      include:
    </p>
    <pre className="code">
      {[
        "// Send results to Test Analytics",
        "reporters: [",
        "  ['line'],",
        "  ['buildkite-test-collector/playwright/reporter']",
        "],",
      ].join("\n")}
    </pre>

    <p>
      Add the <code className="inline-block">BUILDKITE_ANALYTICS_TOKEN</code>{" "}
      secret to your CI environment, push your changes to a branch, and open a
      pull request 🎉
    </p>

    <pre className="code">
      {[
        'git commit -am "Install and set up Buildkite Test Analytics"',
        "git push origin install-buildkite-test-analytics",
      ].join("\n")}
    </pre>

    <p>
      {
        "Once you're done, in your Test Analytics dashboard, you'll see analytics of test executions on "
      }
      <strong>all branches that include this code</strong>.
    </p>

    <SupportBanner />
  </>
);

const Instruction = ({ framework, setFramework }: Props) => {
  const contents = {
    rspec: {
      title: "Installing the RSpec test collector",
      body: <ContentRspec />,
    },
    minitest: {
      title: "Installing the Minitest test collector",
      body: <ContentMinitest />,
    },
    jest: {
      title: "Installing the Jest test collector",
      body: <ContentJest />,
    },
    pytest: {
      title: "Installing the pytest test collector",
      body: <ContentPytest />,
    },
    swift: {
      title: "Installing the Swift test collector",
      body: <ContentSwift />,
    },
    rust: {
      title: "Installing the Rust test collector",
      body: <ContentRust />,
    },
    elixir: {
      title: "Installing the Elixir test collector",
      body: <ContentElixir />,
    },
    junit: {
      title: "Uploading JUnit XML",
      body: <ContentJunit onClickJsonLink={() => setFramework("json")} />,
    },
    junitJava: {
      title: "Uploading JUnit XML",
      body: <ContentJunit onClickJsonLink={() => setFramework("json")} />,
    },
    json: {
      title: "Uploading Test Analytics JSON",
      body: <ContentJson />,
    },
    other: {
      title: "Collecting data from other test frameworks",
      body: <ContentOther />,
    },
    mocha: {
      title: "Installing the Mocha test collector",
      body: <ContentMocha />,
    },
    jasmine: {
      title: "Installing the Jasmine test collector",
      body: <ContentJasmine />,
    },
    playwright: {
      title: "Installing the Playwright test collector",
      body: <ContentPlaywright />,
    },
    cypress: {
      title: "Installing the Cypress test collector",
      body: <ContentCypress />,
    },
  };

  const content = framework === undefined ? null : contents[framework];

  return (
    <div className="col-8 py4 pl4">
      {content ? (
        <div style={{ maxWidth: "600px", margin: "0 auto" }}>
          <h2 className="mt1">{content.title}</h2>
          {content.body}
        </div>
      ) : (
        <EmptyState
          fullscreen={true}
          emoji="🔌"
          heading="Waiting for test data"
          subheading="Choose a test collection method to complete setup and start analyzing your runs."
        />
      )}
    </div>
  );
};

export default Instruction;
