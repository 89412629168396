import { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { shortCommit } from "app/lib/commits";
import BuildDuration from "app/components/build/Duration";
import BuildMessage from "app/components/build/Message";
import BuildStatusDescription from "app/components/shared/BuildStatusDescription";
import Button from "app/components/shared/Button";
import Dialog from "app/components/shared/Dialog";
import Dropdown from "app/components/shared/Dropdown";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";
import PipelineStateIcon, {
  VARIANTS,
} from "app/components/shared/PipelineStateIcon";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import UserAvatar from "app/components/shared/UserAvatar";

import Pipeline from "./pipeline";
import RetryFailedJobsButton from "./RetryFailedJobsButton";
import BuildTriggerLabel from "app/components/shared/BuildTriggerLabel";
import BuildBranchNavigation from "./BuildBranchNavigation";

class BuildHeaderComponent extends PureComponent {
  static defaultProps = {
    showJobs: true,
  };

  static propTypes = {
    currentView: PropTypes.string,
    store: PropTypes.object.isRequired,
    build: PropTypes.shape({
      number: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      blockedState: PropTypes.string,
      finishedAt: PropTypes.string,
      source: PropTypes.string.isRequired,
      message: PropTypes.string,
      commitId: PropTypes.string,
      commitShortLength: PropTypes.number,
      commitUrl: PropTypes.string,
      branchName: PropTypes.string,
      branchPath: PropTypes.string,
      authorName: PropTypes.string,
      authorAvatar: PropTypes.string,
      creatorVerified: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired,
      project: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        public: PropTypes.bool.isRequired,
        archived: PropTypes.bool.isRequired,
        provider: PropTypes.shape({
          id: PropTypes.string,
          frontendIcon: PropTypes.string,
        }).isRequired,
        allowRebuilds: PropTypes.bool.isRequired,
      }).isRequired,
      pullRequest: PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      account: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired,
      rebuildPath: PropTypes.string,
      rebuildBranchPath: PropTypes.string,
      rebuiltFrom: PropTypes.shape({
        url: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
      }),
      cancelPath: PropTypes.string,
      triggeredFrom: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        project: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        build: PropTypes.shape({
          number: PropTypes.number.isRequired,
        }).isRequired,
      }),
      pipelineSchedule: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
      permissions: PropTypes.shape({
        rebuild: PropTypes.shape({
          allowed: PropTypes.bool,
          reason: PropTypes.string,
          message: PropTypes.string,
        }).isRequired,
        cancel: PropTypes.shape({
          allowed: PropTypes.bool,
          reason: PropTypes.string,
          message: PropTypes.string,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    showJobs: PropTypes.bool,
    showRebuild: PropTypes.bool,
    showProject: PropTypes.bool,
    showOrganization: PropTypes.bool,
  };

  state = {
    showRebuildDialog: false,
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this).addEventListener(
      "ajax:error",
      this._onAjaxError,
    ); // eslint-disable-line react/no-find-dom-node
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this).removeEventListener(
      "ajax:error",
      this._onAjaxError,
    ); // eslint-disable-line react/no-find-dom-node
  }

  _onAjaxError = (event) => {
    event.preventDefault();

    const [response, _status, _xhr] = event.detail;

    if (response && response.message) {
      alert(response.message);
    }
  };

  buildIsInNeutralState = () => {
    return ["canceling", "canceled", "scheduled"].includes(
      this.props.build.state,
    );
  };

  bootstrapState = () => {
    switch (this.props.build.state) {
      case "failing":
      case "failed":
      case "danger":
        return "danger";
      case "passed":
      case "blocked":
        switch (this.props.build.blockedState) {
          case "failed":
            return "danger";
          case "running":
            return "warning";
          case "passed":
          default:
            return "success";
        }
      case "started":
        return "warning";
      default:
        return "default";
    }
  };

  commitInfoNode = () => {
    let commitNode, pullRequestNode;
    const { build } = this.props;

    if (build.commitId) {
      if (build.commitUrl) {
        const providerIconClass = `build-header__provider-icon fa fa-${build.project.provider.frontendIcon}`;
        commitNode = (
          <code className="monospace inline-flex self-center">
            <span title={build.commitId} className="flex items-center">
              <i className={providerIconClass} />
              <a
                href={build.commitUrl}
                className="color-inherit hover-color-inherit"
              >
                {shortCommit(build.commitId, build.commitShortLength)}
              </a>
            </span>
          </code>
        );
      } else {
        commitNode = (
          <code className="monospace">
            <span title={build.commitId}>
              {shortCommit(build.commitId, build.commitShortLength)}
            </span>
          </code>
        );
      }
    }

    if (build.pullRequest) {
      pullRequestNode = (
        <a
          className="color-inherit hover-color-inherit"
          href={build.pullRequest.url}
        >
          Pull Request #{build.pullRequest.id}
        </a>
      );
    }

    if (commitNode && pullRequestNode) {
      return (
        <span>
          {commitNode} ({pullRequestNode})
        </span>
      );
    } else if (commitNode) {
      return commitNode;
    }

    return null;
  };

  timeAgoNode = () => {
    return (
      <small className="dark-gray build-secondary-time">
        <BuildStatusDescription build={this.props.build} />
      </small>
    );
  };

  metaInformation = () => {
    let rebuiltFromNode;
    let triggeredFromNode;
    let pipelineScheduleNode;

    const sourceNode = (
      <div className="small dark-gray">
        <BuildTriggerLabel source={this.props.build.source} />
      </div>
    );

    let innerClassName =
      "border-left border-mid-gray flex-auto flex flex-column px2";

    if (this.props.build.rebuiltFrom) {
      rebuiltFromNode = (
        <div className="small dark-gray">
          {"Rebuilt from "}
          <a
            href={this.props.build.rebuiltFrom.url}
            className="semi-bold color-inherit hover-color-inherit"
          >
            {`#${this.props.build.rebuiltFrom.number}`}
          </a>
        </div>
      );
    }

    if (this.props.build.pipelineSchedule) {
      pipelineScheduleNode = (
        <div className="small dark-gray flex">
          <a
            href={this.props.build.pipelineSchedule.url}
            className="semi-bold color-inherit hover-color-inherit flex items-center"
          >
            <Icon
              icon="schedules"
              style={{ width: 15, height: 15, marginRight: 3 }}
            />
            <Emojify text={this.props.build.pipelineSchedule.label} />
          </a>
        </div>
      );
    }

    if (this.props.build.triggeredFrom && this.props.build.triggeredFrom.url) {
      const jobName =
        this.props.build.triggeredFrom.name ||
        `Job #${this.props.build.triggeredFrom.uuid}`;

      innerClassName += " truncate";

      triggeredFromNode = (
        <div className="small dark-gray">
          <a
            href={this.props.build.triggeredFrom.url}
            className="semi-bold color-inherit hover-color-inherit"
          >
            <span>
              {this.props.build.triggeredFrom.project.name}
              {" - Build #"}
              {this.props.build.triggeredFrom.build.number}
              {" / "}
              <Emojify text={jobName} />
            </span>
          </a>
        </div>
      );
    }

    // This nested structure allows us to have two disconnected borders;
    // one which is always along the top of the flex element, for use in
    // the flex-wrapped layout, and one which is on the left, but only
    // along the height of the actual content. These are overflow-hidden
    // with the negative 1px margins, and the result is a dynamic border
    // dependent upon whether the flex element is wrapped or not!
    return (
      <div
        className="border-top border-gray flex-auto flex items-center py1"
        style={{
          marginTop: -1,
          marginLeft: -1,
        }}
      >
        <div className={innerClassName}>
          {sourceNode}
          {rebuiltFromNode}
          {triggeredFromNode}
          {pipelineScheduleNode}
        </div>
      </div>
    );
  };

  handleCancelJobDialogClose = () => {
    this.setState({ cancelJobDialogOpen: false });
  };

  handleCancelJobDialogOpen = () => {
    this.setState({ cancelJobDialogOpen: true });
  };

  render() {
    const { build, showJobs } = this.props;
    const bootstrapPanelState = this.bootstrapState();

    const panelClassName = classNames(
      `panel panel-${bootstrapPanelState} build-panel build-state-${build.state}`,
      {
        mb4: this.props.showProject,
        // This is a tad hacky but we need a variant of bootstraps `panel-default` styles
        // for "neutral" build states
        "panel-neutral": this.buildIsInNeutralState(),
      },
    );

    let rebuildNode;

    if (
      !this.props.build.project.archived &&
      this.props.showRebuild &&
      (build.state === "canceling" || build.finishedAt)
    ) {
      if (build.permissions.rebuild.allowed && build.project.allowRebuilds) {
        rebuildNode = (
          <div className="mx1">
            <a
              className="btn btn-default build-rebuild-button btn-grouped btn-flex btn-grouped--first center"
              data-method="post"
              href={build.rebuildPath}
              rel="nofollow"
            >
              <Icon
                icon="custom/outline/16x/arrows-refresh"
                style={{ width: 16, height: 16 }}
                className="mr1 charcoal-700"
              />

              <span>Rebuild</span>
            </a>

            <Dropdown width={200} className="inline-block">
              <Button
                className="btn-grouped btn-flex btn-grouped--last"
                title="Rebuild options"
              >
                <Icon icon="down-triangle" style={{ width: 7, height: 7 }} />
              </Button>

              <a
                data-method="post"
                href={build.rebuildPath}
                rel="nofollow"
                className="btn block hover-lime"
              >
                Rebuild this commit
              </a>
              <a
                data-method="post"
                href={build.rebuildBranchPath}
                rel="nofollow"
                className="btn block hover-lime"
              >
                Rebuild this branch
              </a>
              <a href="#new" className="btn block hover-lime">
                Custom rebuild&hellip;
              </a>
            </Dropdown>
          </div>
        );
      } else if (build.permissions.rebuild.reason !== "anonymous") {
        rebuildNode = (
          <span
            className="btn btn-default build-rebuild-button center mx1 btn--disabled"
            disabled={true}
            aria-label={build.permissions.rebuild.message}
          >
            <i className="fa fa-refresh mr1" />
            <span>Rebuild</span>
          </span>
        );
      }
    }

    let cancelNode;

    if (
      build.state === "creating" ||
      build.state === "scheduled" ||
      build.state === "started" ||
      build.state === "blocked" ||
      build.state === "failing"
    ) {
      if (build.permissions.cancel.allowed) {
        // Success returns a fresh copy of the build with the new state.
        //
        // Error usually returns a message which is handled by the onAjaxError
        // handler above, but we might also have an odd build state or
        // something so also tell the store to reload itself.
        //
        // The redirect to the build page feels odd, but I'm preserving the
        // current behaviour for the moment. It could use a `store.reload()`
        // here instead to refresh the current state, in the case that a build
        // is now "Canceling" or something.
        cancelNode = (
          <>
            <Button
              className="btn btn-danger build-cancel-button center mx1"
              onClick={this.handleCancelJobDialogOpen}
            >
              <span>Cancel</span>
            </Button>

            <Dialog
              isOpen={this.state.cancelJobDialogOpen}
              onRequestClose={this.handleCancelJobDialogClose}
              width={400}
            >
              <div className="px4 pb4">
                <h2 className="h2">Are you sure?</h2>
                <p className="mb3">
                  Canceling the build will stop all running and pending jobs
                  from running. Continue?
                </p>
                <RemoteButtonComponent
                  url={build.cancelPath}
                  method="post"
                  loadingText="Canceling…"
                  className="btn btn-danger block center"
                  onSuccess={(_event, response) =>
                    response && this.props.store.loadAndEmit(response)
                  }
                  onError={(_event, _response) =>
                    window.location.assign(this.props.build.path)
                  }
                >
                  <span>Yes, Cancel Build</span>
                </RemoteButtonComponent>
              </div>
            </Dialog>
          </>
        );
      } else if (build.permissions.cancel.reason !== "anonymous") {
        cancelNode = (
          <a
            href="#"
            disabled={true}
            aria-label={build.permissions.cancel.message}
            className="btn btn-danger build-cancel-button center mx1"
            rel="nofollow"
          >
            <span>Cancel</span>
          </a>
        );
      }
    }

    let retryNode;
    if (build.permissions.retry.allowed && build.canRetryFailedJobs) {
      retryNode = (
        <RetryFailedJobsButton build={build} store={this.props.store} />
      );
    }

    let avatarNode = this._avatarNode();
    if (avatarNode) {
      avatarNode = (
        <div className="build-author-avatar" style={{ width: 32 + 8 }}>
          {avatarNode}
        </div>
      );
    }

    let authorAndTimeNode;
    if (build.authorName) {
      authorAndTimeNode = (
        <div className="build-author">
          <div>
            {build.authorName}
            {build.creatorVerified && (
              <div className="build-verified">Verified</div>
            )}
          </div>

          {this.timeAgoNode()}
        </div>
      );
    } else {
      authorAndTimeNode = (
        <div className="build-author flex items-center">
          {this.timeAgoNode()}
        </div>
      );
    }

    let buildMessage = <BuildMessage message={build.message} />;
    if (build.path !== window.location.pathname) {
      buildMessage = (
        <a href={build.path} className="color-inherit hover-color-inherit">
          {buildMessage}
        </a>
      );
    }

    const isRunning =
      build.state === "failing" ||
      build.state === "started" ||
      build.state === "canceling" ||
      (build.state === "blocked" && build.blockedState === "running");
    const showTopBar =
      bootstrapPanelState !== "default" || this.buildIsInNeutralState();

    return (
      <div className="build-header build-header-tremor">
        {this._projectNode()}
        <div className={panelClassName}>
          {showTopBar ? (
            <div
              className="panel-bar build-speed-hacks"
              style={
                isRunning
                  ? {
                      backgroundImage:
                        "linear-gradient(-45deg, rgba(255,255,255,.5) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.5) 50%, rgba(255,255,255,.5) 75%, transparent 75%, transparent)",
                      backgroundSize: "12px 12px",
                    }
                  : undefined
              }
            />
          ) : null}
          <div className="panel-heading clearfix">
            <div className="flex items-center">
              <div className="flex-auto flex items-center flex-wrap">
                <div
                  className="flex-auto mr2 build-commit"
                  style={{
                    flexBasis: 300,
                  }}
                >
                  <h3 className="panel-title">{buildMessage}</h3>
                  <div className="flex mt1 gap-3">
                    <BuildBranchNavigation
                      {...build}
                      currentView={this.props.currentView}
                    />

                    {build.branchName && (
                      <div className="before:content-['/'] before:text-gray-600 before:mr-2">
                        <a
                          className="color-inherit hover-color-inherit"
                          href={build.branchPath}
                        >
                          <Icon
                            icon="custom/outline/14x/branch"
                            style={{ height: 14, width: 14 }}
                          />
                          {build.branchName}
                        </a>
                      </div>
                    )}

                    {build.commitId && (
                      <div className="before:content-['/'] before:text-gray-600 before:mr-2">
                        {this.commitInfoNode()}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-none build-duration mr3">
                  <BuildDuration build={build} />
                </div>
              </div>
              <div className="flex-none build-status-icon flex items-center justify-center build-speed-hacks-child">
                <PipelineStateIcon
                  build={build}
                  style={{ width: "44px", height: "44px" }}
                  variant={VARIANTS.large}
                />
              </div>
            </div>
          </div>
          {showJobs && <Pipeline build={build} store={this.props.store} />}
          <div className="panel-footer flex flex-wrap items-center px2 py0">
            <div
              className="flex flex-wrap items-stretch overflow-hidden"
              style={{ flexGrow: 100 }}
            >
              <div className="flex items-center p2 mln1">
                {avatarNode}
                {authorAndTimeNode}
              </div>
              {this.metaInformation()}
            </div>
            {(retryNode || rebuildNode || cancelNode) && (
              <div className="flex p2 mxn1">
                {retryNode}
                {rebuildNode}
                {cancelNode}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  _projectNode = () => {
    if (this.props.showProject) {
      return (
        <div style={{ marginBottom: 10 }}>
          <a href={this.props.build.project.url} className="dark-gray">
            {this.props.showOrganization &&
              `${this.props.build.account.name} / `}
            {this.props.build.project.name}
          </a>
        </div>
      );
    }
  };

  _avatarNode = () => {
    if (!this.props.build.authorName) {
      return null;
    }

    const user = {
      name: this.props.build.authorName,
      avatar: { url: this.props.build.authorAvatar },
    };

    return <UserAvatar user={user} style={{ width: 32, height: 32 }} />;
  };
}

export default BuildHeaderComponent;
