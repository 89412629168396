/**
 * @generated SignedSource<<5589e6a48c065c69db881930351b8edf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateBuildDialog_build$data = {
  readonly branch: string;
  readonly commit: string;
  readonly " $fragmentType": "CreateBuildDialog_build";
};
export type CreateBuildDialog_build$key = {
  readonly " $data"?: CreateBuildDialog_build$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateBuildDialog_build">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateBuildDialog_build",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branch",
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "413f380712aca2a33bad31b9923e7b38";

export default node;
