/* eslint-disable react/jsx-no-bind */
import { useBuild } from "app/components/Playground/BuildContext";
import { useCallback, useEffect } from "react";
import ToggleSwitch from "app/components/shared/ToggleSwitch/ToggleSwitch";
import { StepList } from "./StepList";
import {
  DockPosition,
  useBuildPreferencesStore,
} from "../../lib/useBuildPreferencesStore";
import { useParams } from "react-router-dom";
import { useFilterStore } from "./useFilterStore";

export default function Sidebar() {
  const { build } = useBuild();
  const setFilters = useFilterStore((state) => state.setFilters);
  const filters = useFilterStore((state) => state.filters);

  if (!build) {
    throw new Error("Missing build context");
  }

  const { stepId } = useParams();
  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);
  const drawerHeight =
    useBuildPreferencesStore((state) => state.resizedDrawerHeight) || 0;

  useEffect(() => {
    // If the build is failed, filter the steps to only show the failed ones
    if (build.state === "failed") {
      setFilters("issues");
    }
  }, [build.state]);

  const toggleFilters = useCallback(() => {
    setFilters(filters === "issues" ? "all" : "issues");
  }, [setFilters, filters]);

  return (
    <div className="flex flex-col" data-testid="sidebar">
      <div className="flex flex-col space-y-2">
        <div className="flex align-baseline">
          <div data-testid="step-filters">
            <ToggleSwitch
              value={filters === "issues"}
              onChange={toggleFilters}
              labelledBy="Filter steps"
              renderOnIndicator={() => <>Failed</>}
              renderOffIndicator={() => <>All</>}
            />
          </div>
        </div>

        <div
          style={
            // If the drawer is docked to the bottom, add padding to ensure the content
            // is always scrollable.
            stepId && dockPosition === DockPosition.Bottom
              ? {
                  paddingBottom: `${window.innerHeight * (drawerHeight / 100)}px`,
                }
              : {}
          }
        >
          <StepList />
        </div>
      </div>
    </div>
  );
}
