import { useRef, useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import Icon from "app/components/shared/Icon";
import Dropdown from "app/components/shared/Dropdown";
import FormInputLabel from "app/components/shared/FormInputLabel";
import FormInputHelp from "app/components/shared/FormInputHelp";
import { FlakyTest, Team } from "../../shared/type";
import NoTeamsAvailableDropdown, {
  NoTeamsAvailableProps,
} from "./NoTeamsAvailable";
import ResolveButton from "./ResolveButton";

type Props = NoTeamsAvailableProps & {
  assignment?: FlakyTest["assigned_team"];
  teams: Team[];
  onCreateAssignment: (data: FormFields) => Promise<boolean>;
  onRemoveAssignment: (
    assignment: FlakyTest["assigned_team"],
  ) => Promise<boolean>;
  onResolveTest: () => Promise<boolean>;
  resolved: boolean;
};

export type FormFields = {
  teamId: string;
};

const ManageFlakyDropdown = ({
  assignment,
  teams,
  onCreateAssignment,
  onRemoveAssignment,
  onResolveTest,
  resolved,
  flakyTestAssignmentFeatureAvailable,
  organizationSettingsUrl,
  isAdmin,
}: Props) => {
  if (
    isAdmin === undefined &&
    flakyTestAssignmentFeatureAvailable === undefined
  ) {
    return null;
  }

  const assignmentButton = useRef<HTMLButtonElement>(null);
  const removeButton = useRef<HTMLButtonElement>(null);
  const resolveButton = useRef<HTMLButtonElement>(null);
  const assignmentDropdown = useRef<Dropdown>(null);
  const [error, setError] = useState(false);
  const [showHelpText, setShowHelpText] = useState(false);
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const handleDisableButtons = (disabled: boolean) => {
    if (assignmentButton.current) {
      assignmentButton.current.disabled = disabled;
    }

    if (removeButton.current) {
      removeButton.current.disabled = disabled;
    }

    if (resolveButton.current) {
      resolveButton.current.disabled = disabled;
    }
  };

  const handleCloseDropdown = () => {
    if (assignmentDropdown.current) {
      assignmentDropdown.current.setShowing(false);
    }
  };

  const handleTeamChange = useCallback((event) => {
    setValue("teamId", event.target.value, { shouldValidate: true });
  }, []);

  const handleRemoveTeam = useCallback(async () => {
    handleDisableButtons(true);

    const removeAssignment = await onRemoveAssignment(assignment);

    if (removeAssignment) {
      setValue("teamId", "", { shouldValidate: true });
      handleCloseDropdown();
    } else {
      setError(true);
      handleDisableButtons(false);
    }
  }, [assignment]);

  const handleResolveTest = useCallback(async () => {
    handleDisableButtons(true);

    const resolveTest = await onResolveTest();

    if (resolveTest) {
      setValue("teamId", "", { shouldValidate: true });
      handleCloseDropdown();
    } else {
      setError(true);
      handleDisableButtons(false);
    }
  }, []);

  const onSubmit = useCallback(async (data) => {
    handleDisableButtons(true);

    const createAssignment = await onCreateAssignment(data);

    if (createAssignment) {
      handleCloseDropdown();
    } else {
      setError(true);
      handleDisableButtons(false);
    }
  }, []);

  const handleShowHelpText = useCallback(
    (event) => {
      event.preventDefault();
      setShowHelpText(!showHelpText);
    },
    [showHelpText],
  );

  // Hide if test is resolved
  const showResolveButton = !resolved;

  useEffect(() => {
    if (assignment?.id) {
      setValue("teamId", assignment?.id);
    }
  }, [assignment?.id]);

  return (
    <Dropdown ref={assignmentDropdown}>
      <button
        aria-label="Manage flaky test"
        className="unstyled-button relative z1 py2 px3"
      >
        {/* @ts-expect-error - TS2322 - Type '{ icon: string; size: number; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
        <Icon icon="heroicons/solid/ellipsis-vertical" size={24} />
      </button>

      {teams.length > 0 && flakyTestAssignmentFeatureAvailable ? (
        <div className="p4">
          <div className="pb2">
            <form onSubmit={handleSubmit(onSubmit)} className="relative">
              <button
                onClick={handleShowHelpText}
                className="unstyled-button absolute top-0 right-0 cursor-pointer z1 charcoal-300 hover-charcoal-800"
              >
                <span className="hide">Help</span>
                <Icon
                  icon="heroicons/outline/information-circle"
                  style={{ width: "20px", height: "20px" }}
                />
              </button>

              <FormInputLabel label="Assign to team">
                {showHelpText && (
                  <FormInputHelp className="small pt1 pb1">
                    Can&apos;t see your team? Make sure your team has the
                    correct permissions to view this suite.{" "}
                    <a
                      href="https://buildkite.com/docs/test-analytics/flaky-test-assignment"
                      target="_blank"
                      rel="noreferrer"
                      className="lime"
                    >
                      Learn more about flaky assignments.
                    </a>
                  </FormInputHelp>
                )}
                <select
                  className="select mt1"
                  {...register("teamId", { required: true })}
                  onChange={handleTeamChange}
                  defaultValue=""
                >
                  <option value="" disabled={true} />
                  {teams.map((team) => (
                    <option key={team.id} value={team.id}>
                      {team.name}
                    </option>
                  ))}
                </select>
              </FormInputLabel>
              {error && (
                <p className="red-700">
                  Something went wrong! Refresh your page and try again.
                </p>
              )}
              <button
                ref={assignmentButton}
                type="submit"
                className="btn btn-primary w-full mt-2"
                disabled={!formState.isValid}
              >
                {assignment?.id ? "Reassign" : "Assign"}
              </button>

              {showResolveButton && (
                <ResolveButton
                  buttonRef={resolveButton}
                  onResolveTest={handleResolveTest}
                />
              )}
            </form>
          </div>

          {assignment?.id && (
            <div className="border-top border-gray">
              <button
                ref={removeButton}
                className="hover-underline red btn col-12"
                onClick={handleRemoveTeam}
              >
                Remove assignment
              </button>
            </div>
          )}
        </div>
      ) : (
        <NoTeamsAvailableDropdown
          isAdmin={isAdmin}
          flakyTestAssignmentFeatureAvailable={
            flakyTestAssignmentFeatureAvailable
          }
          organizationSettingsUrl={organizationSettingsUrl}
        >
          {showResolveButton && (
            <ResolveButton
              buttonRef={resolveButton}
              onResolveTest={handleResolveTest}
            />
          )}
        </NoTeamsAvailableDropdown>
      )}
    </Dropdown>
  );
};

export default ManageFlakyDropdown;
