import Icon from "app/components/shared/Icon";
import { BuildView } from "../Show";

interface Props {
  number: number;
  path: string;
  branchName: string;
  currentView?: BuildView;

  nextBranchBuild?: {
    url: string;
    number: number;
  };
  prevBranchBuild?: {
    url: string;
    number: number;
  };
}

function urlForView(buildUrl: string, view?: BuildView) {
  switch (view) {
    case BuildView.Canvas:
      return `${buildUrl}/canvas`;
    case BuildView.Waterfall:
      return `${buildUrl}/waterfall`;
    case BuildView.TestDigest:
      return `${buildUrl}/test_digest`;
    default:
      return `${buildUrl}`;
  }
}

export default function BuildBranchNavigation({
  currentView,
  number,
  path,
  branchName,
  prevBranchBuild,
  nextBranchBuild,
}: Props) {
  return (
    <div className="flex gap-2 items-center">
      {prevBranchBuild && (
        <a
          href={urlForView(prevBranchBuild.url, currentView)}
          title={`Previous build (#${prevBranchBuild.number}) on ${branchName}`}
          className="color-inherit hover-color-inherit flex items-center"
        >
          <Icon
            icon="heroicons/outline/chevron-left"
            style={{ height: 14, width: 14 }}
          />
        </a>
      )}

      {path === window.location.pathname ? (
        <span className="flex items-center">#{number}</span>
      ) : (
        <a
          className="color-inherit hover-color-inherit flex items-center"
          href={path}
        >
          #{number}
        </a>
      )}

      {nextBranchBuild && (
        <a
          href={urlForView(nextBranchBuild.url, currentView)}
          title={`Next build (#${nextBranchBuild.number}) on ${branchName}`}
          className="color-inherit hover-color-inherit flex items-center"
        >
          <Icon
            icon="heroicons/outline/chevron-right"
            className="h-[14px] w-[14px]"
          />
        </a>
      )}
    </div>
  );
}
