import EmptyState from "app/components/shared/EmptyState";

const FlakyEmptyState = ({
  query,
  suiteHasRecentRuns,
  suiteHasPreviousInstances,
}) => {
  if (query) {
    return (
      <EmptyState emoji="🕳️" heading={`No tests match the query "${query}"`} />
    );
  }
  if (suiteHasRecentRuns) {
    return (
      <EmptyState
        emoji="🪴"
        heading={
          suiteHasPreviousInstances
            ? "No flaky instances found in the past 7 days"
            : "No flaky instances found"
        }
        subheading={
          suiteHasPreviousInstances ? undefined : "It must be your day."
        }
      />
    );
  }

  return (
    <EmptyState
      emoji="🤖"
      heading="No tests have been run in the past 7 days"
      subheading="We haven’t received any test executions. Create a run and check back soon."
    />
  );
};

export default FlakyEmptyState;
