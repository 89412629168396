import { useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import useCollapse from "react-collapsed";

import { getMicrosecondDurationFormattedString } from "app/lib/date";
import Icon from "app/components/shared/Icon";
import { Span } from "../../shared/type";

const StyledTableRow = styled.tr`
  &:focus,
  &:hover {
    background: #f9f9f9;
  }

  .span-content {
    margin-top: 4px;
    font-family:
      SFMono-Regular,
      SF Mono,
      Monaco,
      Menlo,
      Consolas,
      Liberation Mono,
      Courier,
      monospace;
    color: var(--charcoal-700);
    font-size: 12px;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    display: block;
    overflow: hidden;
  }

  .duration {
    font-family:
      SF Pro Text,
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Helvetica Neue,
      Helvetica,
      sans-serif;
    color: var(--charcoal-700);
    margin-left: auto;
    font-size: 14px;
  }
`;

const EllipsisButton = styled.button`
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  height: 16px;
  width: 28px;
  border-radius: 3px;
  border: 1px solid transparent;
  background: var(--gray-100);
  vertical-align: middle;
  -webkit-appearance: none;
  color: inherit;

  &:focus,
  &:hover {
    background: var(--gray-400);
  }
`;

const Row = ({ detail, duration, section }: Span) => {
  const formatDetail = () => {
    switch (section) {
      case "sql":
        return detail.query;
      case "http":
        return `${detail.method} ${detail.url} ${detail.lib}`;
      default:
        return JSON.stringify(detail);
    }
  };

  const LINE_HEIGHT = 25;
  const calcExpandable = (element: HTMLElement | null) => {
    return element ? element.offsetHeight > LINE_HEIGHT : false;
  };
  const [isExpandable, setExpandable] = useState(false);
  const spanContentRef = useRef<HTMLPreElement | null>(null);
  const { getCollapseProps, getToggleProps } = useCollapse({
    collapsedHeight: LINE_HEIGHT,
    hasDisabledAnimation: true,
  });
  useLayoutEffect(() => {
    setExpandable(calcExpandable(spanContentRef.current));
  }, []);

  return (
    <StyledTableRow>
      <td className="width-10/12">
        <div {...getCollapseProps()} className="pb1">
          <pre className="span-content" ref={spanContentRef}>
            {formatDetail()}
          </pre>
        </div>
        {isExpandable && (
          <EllipsisButton {...getToggleProps()} aria-label="Expand">
            <Icon icon="ellipsis" style={{ width: 15, height: 12 }} />
          </EllipsisButton>
        )}
      </td>
      <td className="width-2/12">
        <div className="flex">
          <div className="flex-none duration">
            {getMicrosecondDurationFormattedString(duration * 1_000_000)}
          </div>
        </div>
      </td>
    </StyledTableRow>
  );
};

export default Row;
