/**
 * @generated SignedSource<<d7b2c9e64224d294f430a550356d24ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Jobs_organization$data = {
  readonly jobs?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id?: string;
        readonly " $fragmentSpreads": FragmentRefs<"Row_job">;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    } | null;
  } | null;
  readonly name: string;
  readonly slug: string;
  readonly " $fragmentType": "Jobs_organization";
};
export type Jobs_organization$key = {
  readonly " $data"?: Jobs_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Jobs_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "agentQueryRules"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "concurrency"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isMounted"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "passed"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priority"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "states"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Jobs_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "condition": "isMounted",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "agentQueryRules",
              "variableName": "agentQueryRules"
            },
            {
              "kind": "Variable",
              "name": "concurrency",
              "variableName": "concurrency"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "pageSize"
            },
            {
              "kind": "Variable",
              "name": "passed",
              "variableName": "passed"
            },
            {
              "kind": "Variable",
              "name": "priority",
              "variableName": "priority"
            },
            {
              "kind": "Variable",
              "name": "state",
              "variableName": "states"
            },
            {
              "kind": "Literal",
              "name": "type",
              "value": "COMMAND"
            }
          ],
          "concreteType": "JobConnection",
          "kind": "LinkedField",
          "name": "jobs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "JobEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        }
                      ],
                      "type": "JobTypeCommand",
                      "abstractKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Row_job"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6a8dce972d078c1fbc9ad2eac6029c8c";

export default node;
