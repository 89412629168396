import { useCallback, useState } from "react";
import FlakyInstances from "../FlakyInstances";
import TestTable from "../../execution/TestTable";
import TabControl, { TabButton } from "app/components/analytics/shared/Tabs";

type TabsProps = {
  flaky_instances_endpoint: string;
  flaky_instances_executions_endpoint?: string;
  failed_executions_endpoint: string;
  tooltip: string;
};

const TABS = {
  FLAKY: "flaky",
  FAILED: "failed",
};

const Tabs = ({
  flaky_instances_endpoint,
  flaky_instances_executions_endpoint,
  failed_executions_endpoint,
  tooltip,
}: TabsProps) => {
  let anchor = window.location.hash.replace("#", "");

  if (anchor !== TABS.FLAKY && anchor !== TABS.FAILED) {
    anchor = TABS.FLAKY;
  }
  const [activeTab, setActiveTab] = useState(anchor);
  const handleFlakyTabClick = useCallback(() => setActiveTab(TABS.FLAKY), []);
  const handleFailedTestExecutionsTabClick = useCallback(() => {
    setActiveTab(TABS.FAILED);
  }, []);

  return (
    <div style={{ marginTop: "60px" }}>
      <TabControl className="justify-center">
        <TabButton
          active={activeTab === TABS.FLAKY}
          href={`#${TABS.FLAKY}`}
          onClick={handleFlakyTabClick}
          icon="fire"
        >
          Flaky instances
        </TabButton>
        <TabButton
          active={activeTab === TABS.FAILED}
          href={`#${TABS.FAILED}`}
          onClick={handleFailedTestExecutionsTabClick}
          icon="cross-clipboard"
        >
          Failed test executions
        </TabButton>
      </TabControl>
      {activeTab === TABS.FLAKY && (
        <FlakyInstances
          endpoint={flaky_instances_endpoint}
          executionsEndpoint={flaky_instances_executions_endpoint}
        />
      )}
      {activeTab === TABS.FAILED && (
        <TestTable endpoint={failed_executions_endpoint} tooltip={tooltip} />
      )}
    </div>
  );
};

export default Tabs;
