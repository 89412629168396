/* eslint-disable react/jsx-no-bind */
import classNames from "classnames";
import Badge from "app/components/shared/Badge";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useBuild } from "app/components/Playground/BuildContext";
import { BuildView } from "..";

const BuildNavLink = (props: NavLinkProps) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        classNames(
          "px-2 py-1 text-sm",
          "flex items-center justify-center rounded-lg",
          "text-navy-600 hover:text-current focus:text-current no-underline hover:no-underline focus:no-underline",
          "hover:bg-white hover:bg-opacity-50",
          {
            "bg-white shadow-depth-100 text-navy-700": isActive,
          },
        )
      }
    />
  );
};

function BuildContentNavigation({ currentView }: { currentView: BuildView }) {
  const { store, build } = useBuild();
  if (!store || !build) {
    throw new Error("Missing build context");
  }

  // Assemble the URLs for the different views for this build;
  const listViewHref = build.path;
  const waterfallHref = `${build.path}/waterfall`;
  const canvasHref = `${build.path}/canvas`;
  const summaryHref = `${build.path}/summary`;
  const testDigestHref = `${build.path}/test_digest`;

  const renderUpgradeBadge = () => {
    return store.waterfallAvailable ? null : (
      <Badge outline={false} className="white bg-purple right">
        Upgrade
      </Badge>
    );
  };

  if (Features.BuildSidebar) {
    return (
      <nav
        data-testid="BuildContentNavigation"
        className="flex bg-navy-100 rounded-lg p-1 gap-0.5"
      >
        <BuildNavLink to={summaryHref}>Summary</BuildNavLink>
        <BuildNavLink to={canvasHref}>Canvas</BuildNavLink>
        <BuildNavLink to={waterfallHref}>
          <span>Waterfall</span>
          {renderUpgradeBadge()}
        </BuildNavLink>
      </nav>
    );
  }

  return (
    <nav
      className="Tablist flex mb4 border-bottom border-gray"
      data-testid="buildViewTabsNav"
    >
      <NavLink
        className={classNames("btn btn-tab text-current focus:text-current", {
          "btn-tab--active": currentView === BuildView.JobList,
        })}
        to={listViewHref}
      >
        Jobs
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          classNames("btn btn-tab text-current focus:text-current", {
            "btn-tab--active": isActive,
          })
        }
        to={canvasHref}
      >
        Canvas <Badge outline={true}>New</Badge>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          classNames("btn btn-tab text-current focus:text-current", {
            "btn-tab--active": isActive,
          })
        }
        to={waterfallHref}
      >
        <span>Waterfall</span>
        {renderUpgradeBadge()}
      </NavLink>

      {window.Features.AnalyticsPipelinesBuildHeaderTab && (
        <NavLink
          className={({ isActive }) =>
            classNames("btn btn-tab text-current focus:text-current", {
              "btn-tab--active": isActive,
            })
          }
          to={testDigestHref}
        >
          <span>Test digest</span>
        </NavLink>
      )}
    </nav>
  );
}

export default BuildContentNavigation;
