import * as React from "react";
import { DrawerOutlet } from "../components/DrawerOutlet";
import { useBuild } from "app/components/Playground/BuildContext";
import classNames from "classnames";

const Canvas = React.lazy(
  () =>
    import(
      /* webpackChunkName: "canvas" */ "app/components/Playground/BuildCanvas"
    ),
);

export default function CanvasPage() {
  const { build, store } = useBuild();

  if (!build || !store) {
    throw new Error("Missing build context");
  }

  return (
    <div
      className={classNames(
        Features.BuildSidebar ? "" : "border border-gray-300",
        "flex-1 relative rounded-md data-[fullscreen=true]:fixed data-[fullscreen=true]:w-screen data-[fullscreen=true]:h-screen data-[fullscreen=true]:border-none data-[fullscreen=true]:rounded-none data-[fullscreen=true]:left-0 data-[fullscreen=true]:top-0 bg-white data-[fullscreen=true]:m-0 overflow-hidden",
      )}
      id="canvas"
    >
      <div className="absolute w-full h-full">
        <React.Suspense fallback={null}>
          <Canvas />
        </React.Suspense>
      </div>
      <DrawerOutlet />
    </div>
  );
}
