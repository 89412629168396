import { Outlet, useMatches } from "react-router-dom";
import PausedClusterQueuesNotice from "app/components/shared/PausedClusterQueuesNotice";
import BuildContentNavigation from "../components/BuildContentNavigation";
import { BuildView } from "..";
import { useBuild } from "app/components/Playground/BuildContext";
import Header from "app/components/build/Header";
import Sidebar from "../components/Sidebar";

/**
 * Build page layout.
 */
export default function BuildShowLayout() {
  const matches = useMatches();
  const { build, store } = useBuild();

  if (!build || !store) {
    throw new Error("Missing build context");
  }

  const view = [...matches].pop()?.id as BuildView;

  return (
    <>
      <PausedClusterQueuesNotice
        pausedQueues={build.dispatchPausedClusterQueues}
      />

      <Header
        build={build}
        showRebuild={true}
        store={store}
        currentView={view}
        showJobs={!Features.BuildSidebar && view === BuildView.JobList}
      />

      {Features.BuildSidebar ? (
        <div className="flex gap-2 grow">
          <div className="w-[250px] flex flex-col relative">
            <div className="absolute -left-2 -right-2 px-2 h-full overflow-y-auto no-scrollbar">
              <Sidebar />
            </div>
          </div>

          <div className="flex flex-col flex-1 p-4 border border-gray-400 rounded-lg">
            <div className="w-full mb-4">
              <BuildContentNavigation currentView={view} />
            </div>

            <div className="flex-1 relative">
              <div className="flex w-full h-full absolute inset-0 overflow-y-auto border border-gray-400 rounded-lg">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <BuildContentNavigation currentView={view} />
          <Outlet />
        </>
      )}
    </>
  );
}
