import { useState } from "react";
import styled from "styled-components";

import DateFilters, {
  DateFilter,
  LAST_28_DAYS,
} from "app/components/analytics/shared/DateFilters";
import {
  EnvironmentVariables,
  Metrics,
} from "app/components/analytics/shared/type";
import ChartCard from "./ChartCard";

const CardContainer = styled.div`
  gap: 15px;
  padding-bottom: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    flex: 1 1 auto;
    flex-direction: column;
  }

  > div {
    @media (max-width: 1320px) {
      flex: 1 0 25%;
    }
  }
`;

type Props = {
  heading: string;
  endpoint: string;
  branch: EnvironmentVariables["branch"];
  modal_heading: string;
  metrics: Array<Metrics>;
};

const MetricChartCards = ({
  heading,
  metrics,
  branch,
  endpoint,
  modal_heading,
}: Props) => {
  const [filter, setFilter] = useState<DateFilter>(LAST_28_DAYS);

  return (
    <div className="flex flex-column flex-auto">
      <DateFilters heading={heading} filter={filter} setFilter={setFilter} />
      <CardContainer className="flex mt3">
        {metrics.map(({ label, metric, description, chartType }) => (
          <ChartCard
            key={`${metric}-${label}`}
            metric={metric}
            label={label}
            filter={filter}
            branch={branch}
            endpoint={endpoint}
            heading={modal_heading}
            description={description}
            chartType={chartType}
          />
        ))}
      </CardContainer>
    </div>
  );
};

export default MetricChartCards;
