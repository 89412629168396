import { useState, useCallback } from "react";
import useFetch from "use-http";
import _get from "lodash/get";
import styled from "styled-components";
import useCollapse from "react-collapsed";
import Skeleton from "react-loading-skeleton";

import Icon from "app/components/shared/Icon";
import Colors from "app/constants/analytics/Colors";
import { Execution } from "app/components/analytics/shared/type";

type Props = {
  endpoint: string;
  failureReason: Execution["failureReason"];
  failureExpanded?: Execution["failureExpanded"];
};

type ExpandedFailureReason =
  | {
      expanded: string[];
      backtrace: string[];
    }[]
  | null;

const StyledFailureReason = styled.div`
  background-color: ${Colors.DARK_GRAY};
  border-radius: 3px;
  position: relative;

  pre {
    color: ${Colors.GRAY_BORDER};
    font-family:
      SFMono-Regular,
      SF Mono,
      Monaco,
      Menlo,
      Consolas,
      Liberation Mono,
      Courier,
      monospace;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;
    display: block;
    word-break: break-word;

    .failure-accordion-button__container {
      position: relative;
      padding: 10px 15px 10px 50px;
    }
  }
`;

const FailureAccordionButton = styled.button`
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  transition: filter 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: left;
  padding-left: 2em;

  &:hover {
    filter: brightness(1.5);
  }

  svg {
    width: 10px;
    height: 10px;
  }
`;

const ExpandedFailureReason = styled.div`
  margin-top: 10px;
  padding: 10px 15px 10px 50px;
`;

const FailureReason = ({ endpoint, failureReason, failureExpanded }: Props) => {
  if (!failureReason) {
    return null;
  }

  const { get, response, loading, error } = useFetch(endpoint);
  const [expandedFailureReason, setExpandedFailureReason] =
    useState<Execution["failureExpanded"]>(failureExpanded);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const fetchExpandedFailureReason = useCallback(async () => {
    if (expandedFailureReason) {
      return;
    }

    const failureExpanded = await get("/failure_expanded");
    if (response.ok) {
      setExpandedFailureReason(failureExpanded);
    }
  }, [expandedFailureReason]);

  return (
    <StyledFailureReason>
      <pre>
        <div
          className={`${
            !isExpanded ? "truncate" : ""
          } failure-accordion-button__container`}
        >
          <FailureAccordionButton
            className="no-title-tooltip"
            aria-label={
              isExpanded ? "Hide failure reason" : "Show failure reason"
            }
            {...getToggleProps({ onClick: fetchExpandedFailureReason })}
          >
            <Icon
              icon={isExpanded ? "thick-minus" : "thick-plus"}
              className="relative -top-0.5"
            />
          </FailureAccordionButton>

          {failureReason}
        </div>

        <div data-testid="expanded-failure-reason" {...getCollapseProps()}>
          <ExpandedFailureReason>
            {expandedFailureReason &&
              expandedFailureReason
                .map((failure) => {
                  const expandedString = _get(failure, "expanded", []).join(
                    "\n",
                  );
                  const backtraceString = _get(failure, "backtrace", []).join(
                    "\n",
                  );
                  return expandedString.concat("\n", backtraceString);
                })
                .join("\n\n")}
            {loading && (
              <Skeleton
                containerTestId="loading-skeleton"
                width="80%"
                baseColor="#444444"
                highlightColor="#5D5D5D"
                count={6}
              />
            )}
            {error && "Error: test failure reasons could not be loaded"}
          </ExpandedFailureReason>
        </div>
      </pre>
    </StyledFailureReason>
  );
};

export default FailureReason;
