import Popover from "app/components/shared/Popover";
import styled from "styled-components";

const StyledUsageTooltip = styled.div`
  padding: 20px 40px 30px 40px;
  .header {
    display: flex;
    justify-content: center;
    color: var(--charcoal-900);
  }

  .usage-tooltip-body {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    gap: 30px;
    .usage-tooltip-cell {
      border-left: 3px solid #00be13;
      padding-right: 10px;
      padding-left: 8px;
      text-align: left;
      &:nth-child(2) {
        border-left: 3px solid #202632;
      }

      .title {
        color: var(--charcoal-300);
      }
      .count {
        color: var(--charcoal-900);
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
`;

const Title = ({ titles }: { titles: Array<string> }) => (
  <div className="header">
    {titles.map((title) => (
      <h5 className="m0" key={title}>
        {title}
      </h5>
    ))}
  </div>
);

const Cell = ({ info, color }: { info: any; color?: string }) => {
  const [title, count] = info.lines[0].split(":");
  return (
    <div
      className="usage-tooltip-cell"
      style={color ? { borderLeftColor: color } : {}}
    >
      <p className="title m0">{title}</p>
      <p className="count m0">{count}</p>
    </div>
  );
};

const Body = ({
  body,
  backgroundColor,
}: {
  body: Array<any>;
  backgroundColor?: string;
}) => (
  <div className="usage-tooltip-body">
    {body.map((cell) => (
      <Cell key={cell.lines[0]} info={cell} color={backgroundColor} />
    ))}
  </div>
);

type UsageTooltipProps = {
  titles: Array<string>;
  bodyLines: Array<any>;
  backgroundColor?: string;
};
const UsageTooltip = ({
  titles,
  bodyLines,
  backgroundColor,
}: UsageTooltipProps) => (
  <Popover
    offsetX={0}
    offsetY={0}
    nibOffsetX={0}
    width={300}
    style={{ backgroundColor: "white" }}
  >
    <StyledUsageTooltip>
      <Title titles={titles} />
      <Body body={bodyLines} backgroundColor={backgroundColor} />
    </StyledUsageTooltip>
  </Popover>
);

export default UsageTooltip;
