import { Step } from "app/lib/pipeline";
import { GroupListItem } from "./GroupStepListItem";
import { CommandStepListItem } from "./CommandStepListItem";
import { StepListItem } from "./StepListItem";

export function createStepListItem({
  step,
}: {
  step: Step;
  onClick?: () => void;
}) {
  if (step.type === "group") {
    return <GroupListItem step={step} key={step.uuid} />;
  }

  if (step.type === "command") {
    return <CommandStepListItem step={step} key={step.uuid} />;
  }

  return <StepListItem step={step} key={step.uuid} />;
}
