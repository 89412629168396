import { create } from "zustand";

interface FilterState {
  filters: "all" | "issues";
  setFilters: (filters: "all" | "issues") => void;
}

export const useFilterStore = create<FilterState>((set) => ({
  filters: "all",
  setFilters: (filters) => set({ filters }),
}));
