import classNames from "classnames";

import BuildsStore from "app/stores/BuildsStore";
import Emojify from "app/components/shared/Emojify";
import jobCommandOneliner from "app/lib/jobCommandOneliner";
import PipelineStateIcon from "app/components/shared/PipelineStateIcon";

import { isJobTerminated } from "./utils/job";
import { Build } from "./Steps";
import { CommandJob } from "./types/CommandJob";
import getStepClassName from "./utils/getStepClassName";
import JobGroup from "./utils/jobGroup";
import ManualStep from "./ManualStep";
import ScriptStep from "./ScriptStep";
import StepGroup from "./StepGroup";
import TriggerStep from "./TriggerStep";
import WaiterStep from "./WaiterStep";
import { Job } from "app/components/build/Header/pipeline/types/Job";

export type StepProps<T = Job | JobGroup> = {
  build: Build;
  job: T;
  buildStore: BuildsStore;
  group?: JobGroup;
  stepClassName?: string;
};

/**
 * <Step /> is a wrapper component that renders the correct component for a given
 * job type.
 */
export function Step(
  props: Omit<StepProps, "stepClassName"> & { className?: string },
) {
  // Combine the className prop with the classNames for the job type
  const stepClassName = classNames(
    props.className,
    getStepClassName(props.job),
  );

  const stepProps = {
    ...props,
    stepClassName,
  };

  switch (props.job.type) {
    case "job_group":
      return <StepGroup {...stepProps} job={props.job} />;
    case "script":
      return <ScriptStep {...stepProps} job={props.job} />;
    case "waiter":
      return <WaiterStep {...stepProps} job={props.job} />;
    case "manual":
      return <ManualStep {...stepProps} job={props.job} />;
    case "trigger":
      return <TriggerStep {...stepProps} job={props.job} />;
    default:
      return (
        // @ts-expect-error - TS2339 - Property 'id' does not exist on type 'never'.
        <div key={props.job.id} className={stepClassName}>
          {/* @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'. */}
          {props.job.type}
        </div>
      );
  }
}

export function StepIcon({ job }: { job: Job | JobGroup }) {
  return (
    <span className="build-pipeline-job__icon">
      <PipelineStateIcon job={job} />
    </span>
  );
}

// We need to be able to conditionally wrap content for styling purposes, in particular
// for hard failed steps which have different layout requirements to 'regular' steps.
// Some day I would love to tease apart the abstractions for step pills into more
// concrete implementations, but this will get us by for now.
export function StepContentWrapper({
  children,
  shouldWrap = true,
}: {
  children: React.ReactNode;
  shouldWrap?: boolean;
}) {
  if (shouldWrap) {
    return <div className="build-pipeline-job-content">{children}</div>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function StepName({
  job,
  title,
  useLabelForText,
}: {
  job: CommandJob | JobGroup;
  title?: string;
  useLabelForText?: boolean;
}) {
  // @ts-expect-error - TS2339 - Property 'nameIsCommand' does not exist on type 'ScriptStepType | JobGroup'.
  if (job.nameIsCommand) {
    return (
      <span
        className="monospace truncate"
        style={{ fontSize: "0.9em", maxWidth: "12em" }}
      >
        {jobCommandOneliner(job.name)}
      </span>
    );
  }

  const text = useLabelForText && job.step?.label ? job.step?.label : job.name;

  return (
    <Emojify
      // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
      text={text}
      title={title}
      className="truncate"
      style={{ maxWidth: "12em" }}
    />
  );
}

export function ParallelGroupHeader({ job }: { job: CommandJob }) {
  // I'm really stretching what's sensible here (both in this function and the
  // `renderParallelGroupFooter` function).
  //
  // The previous styling resulted in double ups of top and bottom borders when stacking
  // parallel groups. To resolve this I've added some dubious CSS styles using adjacent
  // sibling selectors to render a single border between parallel groups, but what we
  // should really do is make every 'group' in the dropdown a block element, and use
  // child selectors to add borders to divide groups.
  return (
    <div
      className="flex items-center flex-auto line-height-1 group-dropdown-parallel-group-header"
      style={{
        width: "100%",
        borderRadius: "none",
      }}
    >
      <StepContentWrapper
        shouldWrap={isJobTerminated(job) && !job.passed && !job.softFailed}
      >
        <StepName job={job} useLabelForText={true} />
      </StepContentWrapper>
    </div>
  );
}

export function ParallelGroupFooter() {
  return (
    <div
      className="flex-auto group-dropdown-parallel-group-footer"
      style={{
        width: "100%",
        borderRadius: "none",
      }}
    />
  );
}
