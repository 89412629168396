import Chart from "app/components/shared/Chart";
import UsageTooltip from "app/components/shared/UsageChart/UsageTooltip";

import reactRenderer from "app/lib/reactRenderer";

const initialTooltipStyle = `
  position: absolute;
  left: 0px;
  transition: transform 1ms ease-in, opacity 100ms ease-in 100ms;
  z-index: 100;
  pointer-events: none;
`;

const findOrCreateTooltip = (canvas: HTMLCanvasElement) => {
  let tooltipEl: HTMLDivElement | null =
    canvas.parentElement!.querySelector("#chartjs-tooltip");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.style.cssText = initialTooltipStyle;
    canvas.parentElement!.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const renderTooltip = (context: any) => {
  const { tooltip, chart } = context;

  const tooltipEl = findOrCreateTooltip(chart.canvas);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  tooltipEl.style.opacity = "1";
  tooltipEl.style.transform = `translate(${tooltip.caretX}px,0)`;
  reactRenderer(
    <UsageTooltip
      titles={tooltip.title || []}
      bodyLines={tooltip.body.reverse()}
      backgroundColor={tooltip.labelColors[0]?.backgroundColor}
    />,
    tooltipEl,
  );
};
const generateOptions = ({
  period,
  interval,
}: {
  period: ResourceBreakdownProps["data"]["period"];
  interval: ResourceBreakdownProps["data"]["interval"];
}) => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "hover",
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      mode: "point",
      axis: "x",
      external: renderTooltip,
    },
  },
  scales: {
    // eslint-disable-next-line id-length
    x: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: true,
      },
      type: "time",
      min: period.from,
      max: period.to,
      time: {
        unit: interval,
        round: interval,
        tickFormat: interval === "month" ? "MMM 'YY" : "DD MMM 'YY",
        tooltipFormat: interval === "month" ? "MMM 'YY" : "DD MMM 'YY",
        displayFormats: {
          millisecond: "DD MMM 'YY",
          second: "DD MMM 'YY",
          minute: "DD MMM 'YY",
          hour: "DD MMM 'YY",
          day: "DD MMM 'YY",
          month: "MMM 'YY",
        },
      },
      ticks: {
        callback: function (_value, index, ticks) {
          if (index > 0 && index < ticks.length - 1) {
            return "";
          }
          return _value;
        },
        display: true,
        maxRotation: 0,
        color: "#767676",
        font: {
          weight: 500,
          size: 12,
        },
      },
    },
    // eslint-disable-next-line id-length
    y: {
      display: false,
      stacked: true,
    },
  },
  parsing: {
    xAxisKey: "aggregated_on",
    yAxisKey: "total_usage",
  },
});

interface ResourceBreakdownProps {
  data: {
    period: {
      from: string;
      to: string;
    };
    interval: "day" | "month";
    data: {
      datasets: any[];
    };
  };
}

const ResourceBreakdown = ({
  data: { period, interval, ...rest },
}: ResourceBreakdownProps) => {
  const options = generateOptions({ period, interval });
  return (
    <Chart
      chartOptions={{ options: options, type: "bar", ...rest }}
      height={200}
    />
  );
};

export default ResourceBreakdown;
