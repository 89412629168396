import { PureComponent } from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { getCssValue } from "app/lib/cssValues";
import BuildStates from "app/constants/BuildStates";

const SIZE_DEFINITIONS = {
  Regular: {
    strokeWidth: 1.5,
    size: 24,
  },
  Small: {
    strokeWidth: 2,
    size: 20,
  },
  XSmall: {
    strokeWidth: 2.5,
    size: 13,
  },
};

const GREEN = getCssValue("--green-500");
const RED = getCssValue("--red-500");
const ORANGE = getCssValue("--orange-500");
const SLATE = getCssValue("--slate-500");

const STATE_COLORS = {
  [null]: SLATE,
  [BuildStates.SCHEDULED]: SLATE,
  [BuildStates.RUNNING]: ORANGE,
  [BuildStates.PASSED]: GREEN,
  [BuildStates.FAILING]: RED,
  [BuildStates.FAILED]: RED,
  [BuildStates.CANCELED]: SLATE,
  [BuildStates.CANCELING]: SLATE,
  [BuildStates.CREATING]: ORANGE,
  [BuildStates.SKIPPED]: SLATE,
  [BuildStates.NOT_RUN]: SLATE,
  [BuildStates.BLOCKED]: {
    [BuildStates.PASSED]: GREEN,
    [BuildStates.RUNNING]: ORANGE,
    [BuildStates.FAILED]: RED,
    [null]: GREEN,
  },
};

class BuildState extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.number.isRequired,
    state: PropTypes.oneOf(Object.keys(STATE_COLORS)),
    blockedState: PropTypes.oneOf(
      Object.keys(STATE_COLORS[BuildStates.BLOCKED]),
    ),
    strokeWidth: PropTypes.number.isRequired,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      uuid: uuid(),
    };
  }

  render() {
    const { className, size, strokeWidth, style } = this.props;

    const outerCircleId = `BuildState_${this.state.uuid}_circle`;
    const strokeClipPathId = `BuildState_${this.state.uuid}_strokeClipPath`;

    const { defs, content } = this.renderPaths();

    return (
      <svg
        className={className}
        style={style}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        aria-label={"Build state: " + (this.props.state || "Undetermined")}
      >
        <defs>
          <circle
            id={outerCircleId}
            fill="#fff"
            cx="12"
            cy="12"
            r="11.25"
            stroke={this.strokeColor()}
            strokeWidth={strokeWidth * 2}
          />
          <clipPath id={strokeClipPathId}>
            <use xlinkHref={`#${outerCircleId}`} />
          </clipPath>
          {defs}
        </defs>
        <use
          xlinkHref={`#${outerCircleId}`}
          clipPath={`url(#${strokeClipPathId})`}
        />
        {content}
      </svg>
    );
  }

  strokeColor() {
    if (this.props.state === BuildStates.BLOCKED) {
      return STATE_COLORS[this.props.state][this.props.blockedState];
    }

    return STATE_COLORS[this.props.state];
  }

  renderPaths() {
    const applyStroke = {
      fill: "none",
      stroke: this.strokeColor(),
      strokeWidth: this.props.strokeWidth,
    };

    let defs;
    let content;

    switch (this.props.state) {
      case BuildStates.PASSED:
        content = (
          <path
            d="M8.66268 12.75L10.9393 15.0267L15.7123 10.2537"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...applyStroke}
          />
        );
        break;

      case BuildStates.FAILED:
        content = (
          <g {...applyStroke}>
            <path d="M9.34836 9.375L14.6517 14.6783" strokeLinecap="round" />
            <path d="M14.6516 9.375L9.34834 14.6783" strokeLinecap="round" />
          </g>
        );
        break;

      case BuildStates.CREATING:
      case BuildStates.RUNNING:
      case BuildStates.CANCELING:
        content = (
          <g
            {...applyStroke}
            className="animation-spin-slow"
            style={{ transformOrigin: "center" }}
          >
            <path
              d="M12 16.5C13.1935 16.5 14.3381 16.0259 15.182 15.182C16.0259 14.3381 16.5 13.1935 16.5 12"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M11.5 7C10.3065 7 9.16193 7.47411 8.31802 8.31802C7.47411 9.16193 7 10.3065 7 11.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </g>
        );
        break;

      case BuildStates.FAILING:
        content = (
          <g
            {...applyStroke}
            className="animation-spin-slow"
            style={{ transformOrigin: "center" }}
          >
            <path
              d="M12 16.5C13.1935 16.5 14.3381 16.0259 15.182 15.182C16.0259 14.3381 16.5 13.1935 16.5 12"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M12 7.5C10.8065 7.5 9.66193 7.97411 8.81802 8.81802C7.97411 9.66193 7.5 10.8065 7.5 12"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </g>
        );
        break;

      case BuildStates.CANCELED:
        content = (
          <g {...applyStroke}>
            <path
              d="M12 16.5C13.1935 16.5 14.3381 16.0259 15.182 15.182C16.0259 14.3381 16.5 13.1935 16.5 12"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M12 7.5C10.8065 7.5 9.66193 7.97411 8.81802 8.81802C7.97411 9.66193 7.5 10.8065 7.5 12"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path d="M15.7123 8.28772L8.28766 15.7123" strokeLinecap="round" />
          </g>
        );
        break;

      case BuildStates.SCHEDULED:
        content = (
          <path
            d="M12 7.5V12.75L14.625 15.375"
            strokeLinecap="round"
            {...applyStroke}
          />
        );
        break;

      case null:
      case BuildStates.SKIPPED:
      case BuildStates.NOT_RUN:
        content = <path d="M9 12H15" strokeLinecap="round" {...applyStroke} />;
        break;

      case BuildStates.BLOCKED:
        content = (
          <g {...applyStroke}>
            <path d="M10.125 15L10.125 9" strokeLinecap="round" />
            <path d="M13.875 15L13.875 9" strokeLinecap="round" />
          </g>
        );
        break;
    }

    return { defs, content };
  }
}

const exported = {};

Object.keys(SIZE_DEFINITIONS).forEach((size) => {
  const component = (props) => {
    const StateComponent = BuildState;
    return <StateComponent {...props} {...SIZE_DEFINITIONS[size]} />;
  };
  component.displayName = `BuildState.${size}`;

  exported[size] = component;
});

export default exported;
