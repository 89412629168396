import Bar, { ActivityProps } from "./Bar";

type Props = {
  activities: Array<ActivityProps>;
  disableTooltips?: boolean;
  wrapperHeight?: string;
  executionTurboFrame?: boolean;
};

const Activity = ({
  activities,
  disableTooltips,
  wrapperHeight = "75px",
  executionTurboFrame,
}: Props) => {
  if (activities.length === 0) {
    return null;
  }

  const calcMaxDuration = () => {
    if (activities.length === 0) {
      return 0;
    }

    const max = activities.reduce((prev, current) => {
      return prev.duration > current.duration ? prev : current;
    });

    return max.duration;
  };

  const renderBars = () => {
    const maxDuration = calcMaxDuration();

    return activities.map((activity) => {
      return (
        <Bar
          key={activity.id}
          activity={activity}
          maxDuration={maxDuration}
          disableTooltips={disableTooltips}
          executionTurboFrame={executionTurboFrame}
        />
      );
    });
  };

  return (
    <div data-testid="chart">
      <div
        className="flex items-end justify-start"
        style={{ height: wrapperHeight }}
      >
        {renderBars()}
      </div>
    </div>
  );
};

export default Activity;
