const DEFAULT_GAP = 2;
const DEFAULT_SIZE = 48;
const DEFAULT_STROKE_WIDTH = 5;

const COLOR_MAP = {
  success: "var(--green-500)",
  fail: "var(--red-500)",
};

type Props = {
  gap?: number;
  progress: number;
  size?: number;
  strokeWidth?: number;
  color: "success" | "fail";
};

const CircularProgress = (props: Props) => {
  const {
    size = DEFAULT_SIZE,
    strokeWidth = DEFAULT_STROKE_WIDTH,
    gap = DEFAULT_GAP,
    progress,
    color,
  } = props;
  const radius = (size - strokeWidth) / 2;
  const circumference = Math.floor(radius * 2 * Math.PI);
  const progressLength = Math.floor(circumference * progress);
  const trailLength =
    circumference - progressLength - 2 * strokeWidth - 2 * gap;

  return (
    <div className="relative flex">
      <svg
        style={{
          height: size,
          width: size,
          transform: "rotate(-90deg)",
        }}
        role="meter"
        aria-valuenow={progress * 100}
      >
        <circle
          stroke="var(--gray-600)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${trailLength} ${circumference}`}
          strokeDashoffset={0 - progressLength - strokeWidth - gap}
          fill="transparent"
          r={radius}
          cx="50%"
          cy="50%"
        />
        <circle
          stroke={COLOR_MAP[color]}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${progressLength} ${circumference}`}
          fill="transparent"
          r={radius}
          cx="50%"
          cy="50%"
        />
      </svg>
      <span className="ta-circular-progress__progress">
        {Math.round(progress * 100)}%
      </span>
    </div>
  );
};

export default CircularProgress;
