import styled from "styled-components";
import Metadata, {
  MetadataType,
} from "app/components/analytics/shared/Metadata";
import MetadataCard, { MetadataCardType } from "./MetadataCard";
import {
  CardContainer,
  Metrics,
} from "app/components/analytics/shared/MetricCards";
import FailureReason from "app/components/analytics/shared/FailureReason";
import useFetch from "use-http";
import Card from "../../shared/MetricCards/Card";
import { Execution } from "../../shared/type";

export const SPAN_LABELS = [
  "Test execution duration",
  "Total SQL duration",
  "Total HTTP duration",
];

type Props = {
  metadata: MetadataType;
  metricsEndpoint: string;
  hasSpans: boolean;
  failedExecutionEndpoint: string;
  metadataCard: MetadataCardType;
  breakdown: Pick<Execution, "duration" | "failureReason">;
};

const StyledExecutionHeader = styled.div<{ failure: boolean }>`
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-wrap: wrap;
  justify-items: start;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }

  .failure {
    padding-bottom: 15px;
  }
`;

const MetadataCardContainer = styled.div`
  flex: 3 1 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 500px;
`;

const Header = ({
  metadata,
  metricsEndpoint,
  failedExecutionEndpoint,
  metadataCard,
  breakdown,
  hasSpans,
}: Props) => {
  const metricLabels = hasSpans ? SPAN_LABELS : [SPAN_LABELS[0]];
  const { loading, error, data = [] } = useFetch<Metrics>(metricsEndpoint, []);

  const getCardProps = (label: string) => {
    if (error) {
      return { label, error: true, value: "-" };
    }

    const matchingMetric = data.find((item) => item.label === label);
    const value = matchingMetric?.value || "-";

    return { label, loading, value };
  };

  return (
    <StyledExecutionHeader failure={breakdown.failureReason != null}>
      <Metadata {...metadata} />
      {breakdown.failureReason != null && (
        <div className="failure">
          <FailureReason
            failureReason={breakdown.failureReason}
            endpoint={failedExecutionEndpoint}
          />
        </div>
      )}
      <div className="flex flex-column lg-flex-row flex-wrap">
        <div className="flex flex-column flex-auto">
          <CardContainer className="flex">
            {metricLabels.map((label) => {
              const cardProps = getCardProps(label);
              return <Card key={label} {...cardProps} />;
            })}
          </CardContainer>
        </div>
        <MetadataCardContainer>
          <MetadataCard {...metadataCard} />
        </MetadataCardContainer>
      </div>
    </StyledExecutionHeader>
  );
};

export default Header;
